import { Injectable, OnInit } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { HttpClient,  HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { Authorization } from '../authorization'
import { LoginService } from '../services/login.service'
import { graphQlFormat }  from '../graphQlFormatting'
import { environment } from '../../environments/environment'
import { ShowingOrderUpdate } from '../showing'
import { dateFromEpochTime, epochTimeFromDate, formatDate } from '../utils'
import { ServiceErrors } from './service-errors'

@Injectable({
  providedIn: 'root'
})
export class ToursService {

	private graphApi:string = environment.graphApi;

	authorization: Authorization

	constructor(
		private cookieService: CookieService, 
		private loginService: LoginService, 
		private http: HttpClient) { 
		 this.checkAuth()
	}

	checkAuth () {
		this.authorization = this.loginService.getAuthorization()
		if ( !this.authorization?.token ) { console.error("No auth token present") }
	}

	/**
 	 Just the count of tours this agent has created
	*/
	fetchToursCount() {

		this.checkAuth()

		let query = `query { toursCount } `
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	/**
 	 Fetch the list of tours for the logged in agent
	*/
	fetchTours() {

		this.checkAuth()

		let listAgent = "listAgent { firstName lastName email phone }"
		let actionFields = 'requestActions { name code }'
		let confirmationRequest = `showingConfirmationRequest { updatedAt confirmed confirmationToken counteredTime responded instructions viewed ${actionFields} ${listAgent} }`
		let showingFields = `id showingTime showingOrder ${confirmationRequest} `
		let clientFields = "clients { id firstName lastName email phone avatar { url }}"
		let propertyFields = "property { listingKey propertyPhotos { url displayOrder } address { fullAddress streetNumber streetName city state zip latitude longitude } }"
    	let fields = ["id", "tourNumber", "tourDate", "name", "description", "createdAt","tourCompleted", `${clientFields} showings { ${showingFields} ${propertyFields} }`  ]
  

		let query = `query { tours { ${fields.join(' ')} } }`
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	/**
 	 Fetch one tour for summary display
	*/
	fetchTourSummary(tourId) {

		let agentAvatar = "avatar { url }"
		let license = "license { licenseNumber }"
		let agentFields = `agent { firstName lastName fullName email phone ${agentAvatar} ${license} }`
		let showingFields = `id showingTime showingOrder`

		let client = 'clients { id firstName lastName }'

		let clientRatingFields = ` propertyRatings { starRating } `
		let clientPhotos = ` clientPhotos { url type } `
		let clientFavorites = `clientFavorites { favoriteStatus }`
		let clientComments = `clientComments { id comments createdAt }`
		let clientFields = `${clientFavorites} ${clientComments} ${clientRatingFields} ${clientPhotos}`

    let propertyRatings =	"propertyRatings { starRating }"
		let propertyFields = `property { id listingKey propertyPhotos { url displayOrder type } address { fullAddress streetNumber streetName city state zip latitude longitude } propertyFeatures }`

	

  	let fields = ["id", "tourNumber", "tourDate", "name", "description", "createdAt", "tourCompleted", ` ${client} showings { ${clientFields} ${showingFields} ${propertyFields} ${agentFields} }`  ]
  

		let query = `query { tours(tourId: ${tourId} ) { ${fields.join(' ')} } }`
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	/**
	 * sendCustomerUrlEmail
	 */
	sendCustomerUrlEmail(email:string,url:string,agentName:string,tourName:string,phoneNumber){
		email = email.toLowerCase();
		this.checkAuth()
		let query = `mutation{ sendCustomerUrlEmail(email:"${email}",url:"${url}",agentName:"${agentName}",tourName:"${tourName}",phone:"${phoneNumber}"){  email	}  }`
		let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }

	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	webSendRemainderToursEmails(tourId){
		this.checkAuth()
        let query = `mutation{ scheduler{ webSendRemainderToursEmails(tourId:${tourId}){ tourDate }}}`
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }

	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}
	/**
 	 Get the statuses of showings associate with this tour. Used for realtime updates.
	*/
	pollShowingStatus(showingId: number) {

		this.checkAuth()

		let actionFields = 'requestActions { name code }'
		let showingFields = `showings { id showingTime }`
		let confirmationRequest = `updatedAt confirmed confirmationToken counteredTime responded instructions viewed ${actionFields} ${showingFields}`
  

		let query = `query { pollShowingStatus (showingId: [ ${showingId} ]) { ${confirmationRequest} } }`
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}


	/**
 	 Get the current dates of tours and its showings' request status'. Slimmed down select queries as this is used for realtime updates.
	*/
	pollToursStatus() {

		this.checkAuth()

		let actionFields = 'requestActions { name code }'
		let confirmationRequest = `showingConfirmationRequest { updatedAt confirmed confirmationToken counteredTime responded instructions viewed ${actionFields} }`
		let showingFields = `showings { id showingTime ${confirmationRequest} }`
		let clientFields = "clients { id firstName lastName}"

		let query = `query { pollToursStatus { id tourDate ${clientFields} ${showingFields} } }`
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}


	/**
 	 Create a new tour for use
	*/
	addTour(tour, properties, clients) {

		tour.tourDate = formatDate(tour.tourDate);

		this.checkAuth()

		let propertiesToAdd = ''
		if ( properties != null ) {
			if (Array.isArray(properties)) {
				propertiesToAdd = ` properties: [${properties.join(', ')}] `;
			} else {
				propertiesToAdd = ` properties: [${properties}] `;
			}
		}
		let clientsToAdd = ''
		if ( clients != null ) {
			clientsToAdd = ` clients: [${clients.join(',') }] `
		}

		let query = `mutation { createTour (name: "${tour.name}" description: "${tour.description}" date: "${tour.tourDate}" ${propertiesToAdd} ${clientsToAdd} ) { id tourNumber tourDate name description } }`

		console.log("query is",query);

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	/**
 	 Generate a confirmation request that a listing agent will respond to for a single showing appointment.
	*/
	requestShowingAppointment(showingId, actionCode) {
		
		this.checkAuth()

		let listAgentFields = "listAgent { id firstName lastName email phone }"
		let actionFields = 'requestActions { name code }'
		let confirmationRequest = ` updatedAt confirmed viewed responded counteredTime instructions ${actionFields} ${listAgentFields} `

		let query = `mutation { scheduler { requestShowingConfirmation(showingId: ${showingId} actionCode: ${actionCode} ) { ${confirmationRequest} } } }`


	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	/**
 	 Generate a counter confirmation request, based on a previous one.
 	 This is an open endpoint, secured only by the secret token. 
 	 TODO: Revisit security on this.
	*/
	requestCounterAppointment(confirmationToken, actionCode) {
		
		let listAgentFields = "listAgent { id firstName lastName email phone }"
		let actionFields = 'requestActions { name code }'
		let previousRequests = `showingConfirmationRequest { counteredTime ${actionFields} }`
		let propertyFields = "property { listingKey originatingMls propertyFeatures propertyPhotos { url displayOrder } address { fullAddress streetNumber streetName city state zip latitude longitude } }"
		let agentFields = "agent { fullName }"
		let showingFields = `showings { id showingTime showingOrder ${propertyFields} ${agentFields} ${previousRequests} }`
		let confirmationRequest = ` updatedAt confirmed viewed responded counteredTime instructions ${actionFields} ${listAgentFields} ${showingFields}`

		let query = `mutation { scheduler { requestCounterConfirmation(confirmationToken: "${confirmationToken}" actionCode: ${actionCode} ) { ${confirmationRequest} } } }`

	  	let body = { "query" : query } 
	  	return this.http.post(this.graphApi, body )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}


	/**
 	 Fetch a confirmation request by token. This is meant for the listing agent to use when
 	 responding.

 	 WARN: Unauthenticated point
	*/
	fetchShowingConfirmationRequest(confirmationToken) {
		
		let listAgentFields = "listAgent { id firstName lastName fullName email phone }"
		let propertyFields = "property { listingKey originatingMls  propertyFeatures propertyPhotos { url displayOrder } address { fullAddress streetNumber streetName city state zip latitude longitude } }"
		let agentFields = "agent { fullName }"
		let actionFields = 'requestActions { name code }'
		let previousRequests = `showingConfirmationRequest { counteredTime ${actionFields} }`
		let showingFields = `showings { id showingTime showingOrder ${propertyFields} ${agentFields} ${previousRequests} }`
		let confirmationRequest = `updatedAt confirmed responded instructions viewed counteredTime confirmationToken commission ${actionFields} ${showingFields} ${listAgentFields}`

		let query = `mutation { scheduler { confirmationRequest(confirmationToken: "${confirmationToken}" ) { ${confirmationRequest} } } }`

	  	let body = { "query" : query } 
	  	return this.http.post(this.graphApi, body )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	fetchShowingConfimationStatus(confirmationIds) {

	}

	/**
 	 Fetch all confirmation requests for a particular showing. Intended for use by the agent.
	*/
	fetchShowing(showingId) {

		this.checkAuth()

		let listAgentFields = "listAgent { id firstName lastName email phone }"
		let actionFields = 'requestActions { name code }'
		let confirmationRequest = `showingConfirmationRequest { updatedAt confirmed counteredTime viewed confirmationToken responded instructions ${actionFields} ${listAgentFields} }`
		let propertyFields = "property { listingKey originatingMls propertyPhotos { url displayOrder } address { fullAddress streetNumber streetName city state zip latitude longitude } }"
		let showingFields = `id showingTime showingOrder ${confirmationRequest} ${propertyFields}`

		let query = `query { showing(showingId: ${showingId} ) { ${showingFields} } }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	/**
 	 Fetch the list of request actions
	*/
	fetchRequestActions() {

		this.checkAuth()

		let query = `query { requestActions { name code } }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}


	/**
 	 Update the value of the date when the tour is going to occur.
	*/
	updateTourDate(tourId, date) {

		this.checkAuth()

		let actionFields = 'requestActions { name code }'
		let confirmationRequest = `showingConfirmationRequest { updatedAt confirmed confirmationToken counteredTime responded instructions viewed ${actionFields} }`

		let showingFields = `id showingTime showingOrder ${confirmationRequest} `
		let clientFields = "clients { id firstName lastName email phone avatar { url }}"
		let propertyFields = "property { listingKey originatingMls propertyPhotos { url displayOrder } address { fullAddress streetNumber streetName city state zip latitude longitude } }"
    	let fields = ["id", "tourNumber", "tourDate", "name", "description", "createdAt", "tourCompleted", `${clientFields} showings { ${showingFields} ${propertyFields} }`  ]
  

		let query = `mutation { updateTourDate ( tour: ${tourId} date: "${date}" ) { ${fields.join(' ')} } }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	/**
 	 Updates the time this listing will be shown on this tour
	*/
	updateShowingTime(showingId, date) {

		this.checkAuth()

		// let query = `mutation { updateShowingTime ( showing: ${showingId} date: "${Number(date)}" ) }`
		let query = `mutation { updateShowingTime ( showing: ${showingId} date: "${formatDate(date)}" ) }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	fetchTimeZone(latitude, longitude) {

		this.checkAuth()

		// let query = `mutation { updateShowingTime ( showing: ${showingId} date: "${Number(date)}" ) }`
		let query = `mutation { timeZone ( latitude: "${latitude}" longitude: "${longitude}" ) }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	/**
 	 Mark whether the listing agent has confirmed this time
	*/
	updateConfirmedState(showingId, confirmedState) {

		this.checkAuth()

		let query = `mutation { updateConfirmedState ( showing: ${showingId} confirmedState: ${confirmedState} ) }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	/**
 	 Update the various fields associated with an update request
	*/
	updateConfirmationRequest(confirmationRequest, actionCode, agentCommission) {

		let req = confirmationRequest

		let listAgentFields = "listAgent { id firstName lastName fullName email phone }"
		let propertyFields = "property { listingKey originatingMls propertyFeatures propertyPhotos { url displayOrder } address { fullAddress streetNumber streetName city state zip latitude longitude } }"
		let agentFields = "agent { fullName }"
		let actionFields = 'requestActions { name code }'
		let previousRequests = `showingConfirmationRequest { counteredTime ${actionFields} }`

		let showingFields = `showings { id showingTime showingOrder ${propertyFields} ${agentFields} ${previousRequests} }`
		let confirmationFields = `updatedAt confirmed responded instructions viewed counteredTime confirmationToken ${actionFields} ${showingFields} ${listAgentFields}`
		let instructions = req.instructions == null ? null : `"${req.instructions}"`
		let counteredTime = req.counteredTime == null ? null : `"${req.counteredTime}"`
		let confirmationUpdate = `{ 
			viewed: ${Number(req.viewed)} 
			confirmed: ${Number(req.confirmed)} 
			responded: ${Number(req.responded)} 
			counteredTime: ${counteredTime}
			instructions: ${instructions} 
			actionCode: ${actionCode}
			confirmationToken: "${req.confirmationToken}" 
			commission: "${agentCommission}"}`
		let query = `mutation { scheduler { updateConfirmationRequest( confirmationUpdate: ${confirmationUpdate} ) { ${confirmationFields} } } }`
	  	let body = { "query" : query } 
	  	return this.http.post(this.graphApi, body )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	/**
 	 Mark the request as confirmed, update and notify the requester
	*/
	confirmRequestedTime(confirmationRequest, agentCommission) {

		let req = confirmationRequest

		let listAgentFields = "listAgent { id firstName lastName fullName email phone }"
		let propertyFields = "property { listingKey originatingMls propertyFeatures propertyPhotos { url displayOrder } address { fullAddress streetNumber streetName city state zip latitude longitude } }"
		let agentFields = "agent { fullName }"
		let actionFields = 'requestActions { name code }'
		let previousRequests = `showingConfirmationRequest { counteredTime ${actionFields} }`
		let showingFields = `showings { id showingTime showingOrder ${propertyFields} ${agentFields} ${previousRequests} }`
		let confirmationFields = `updatedAt confirmed responded instructions viewed confirmationToken ${actionFields} ${showingFields} ${listAgentFields}`
		let instructions = req.instructions == null ? null : `"${req.instructions}"`
		let confirmedTime = req.confirmedTime == null ? null : `"${req.confirmedTime}"`

		let confirmationUpdate = `{ 
			viewed: ${Number(req.viewed)} 
			confirmed: ${Number(req.confirmed)} 
			responded: ${Number(req.responded)} 
			instructions: ${instructions} 
			actionCode: ${req.requestActions.code}
			confirmationToken: "${req.confirmationToken}" 
			confirmedTime: ${confirmedTime} 
			commission: "${agentCommission}"
			}`
		let query = `mutation { scheduler { confirmRequestedTime( confirmationUpdate: ${confirmationUpdate} ) { ${confirmationFields} } } }`
		console.log('query', query)
	  	let body = { "query" : query } 
	  	return this.http.post(this.graphApi, body )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}


	addPropertiesToTour(properties, tour) {

		this.checkAuth()

		let query = `mutation { addPropertiesToTour (properties: [${properties.join(',')}] tour: ${tour} ) { id  } }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	/**
 	 Update the orders of two showings
	*/
	changeShowingOrder(showings:ShowingOrderUpdate[]) {

		this.checkAuth()

		let first = graphQlFormat(showings[0]).join(', ')
		let second = graphQlFormat(showings[1]).join(', ')

		let query = `mutation { updateShowingOrder ( showings: [ { ${first} } , { ${second} } ])  }`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	/**
 	 Remove tour by id
	*/
	deleteTours( tourList ) {

		this.checkAuth()

		let query = `mutation { deleteTours ( tourList: [${tourList.join(", ")} ] )}`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	/**
 	 Remove showing by id
	*/
	deleteShowing( showingId, tourId ) {

		this.checkAuth()

		let query = `mutation { deleteShowing ( showing: ${showingId} tour: ${tourId} )}`

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	updateShowingOrder(showings) {

		this.checkAuth()

		let query = `mutation { updateTourShowingOrder ( id:[${showings}] ) {message}  }`

	  	let body = { "query" : query } 

		console.log("query is",query)
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	sendTourSummaryEmail(email:string,tourId){
		this.checkAuth()
        let query = `mutation {
			scheduler{
				tourSummaryEmailToClient(tourId:${tourId},emails:"${email}"){
		
						id
		
					}
				}
		}`
	  	let body = { "query" : query } 
		let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }

	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}
	addToWishList(property,userId:number):Observable<any>{
		this.checkAuth()
		let query = `mutation{addPropertyToWishList(userId:${userId},propertyId:${property.id},listing_id:"${property.listingId}",listing_key:"${property.listingKey}"){ message } }`
	  	let body = { "query" : query } 
		let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}
	getUserWishList(userId:number):Observable<any>{
		this.checkAuth()
		let query =  `query{getPropertyWishList(userId:${userId}){ id, userId, listing_id, listing_key, propertyId,  property{	id,	UnparsedAddress,ListPrice,LivingArea, LotSizeAcres,BedroomsTotal,
			BathroomsTotalInteger, propertyPhotos{  url }  } } }`;
	  	let body = { "query" : query } 
		let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}
	removeWishList(wishlistId:string):Observable<any>{
		this.checkAuth()
		let query = `mutation{deletePropertyWish(id:"${wishlistId}"){  message	}  }`;
	  	let body = { "query" : query } 
		let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	checkDocSentToClient(clients) {

		this.checkAuth()

		let query = `query { checkDocSentToClient ( id:[${clients}] ) {message}  }`

	  	let body = { "query" : query } 

		console.log("query is",query)
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	checkDocApprovedByClients(clients) {

		let query = `query { checkDocApprovedByClients ( id:[${clients}] ) {message}  }`

	  	let body = { "query" : query } 

		  console.log("query is",query)
	  	return this.http.post(this.graphApi, body )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	tourClone(tour, id) {

		tour.tourDate = formatDate(tour.tourDate);

		this.checkAuth()

		let query = `mutation { tourClone (name: "${tour.name}" description: "${tour.description}" id: "${id}" date: "${tour.tourDate}" ) { message } }`

		console.log("query is",query);

	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	getDocumentLogs(clientId) {

		this.checkAuth()

		let query = `query { getClientDocLogsdetails ( clientId:${clientId} ) {id, document_name, orgId, status, file_path }  }`

	  	let body = { "query" : query } 

		console.log("query is",query)
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	getTourSummaryList(clientId) {

		this.checkAuth()

		let query = `query { getClientsToursdetails ( clientId:${clientId} ) { id, tourId, tours {id, name, tourDate, tourNumber, tourCompleted },clients { id }}  }`

	  	let body = { "query" : query } 

		console.log("query is",query)
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	orgList(): Observable<any> {
		this.checkAuth();
	
		let query = `query{ getOrgsList{
		  id,  name, address,description,status, createdAt,
		  orguserdetails{roleId}
		  orgmls{
			contractedMls{
			  id,
			  mlsName
			}
			
		  }
		  } }`;
		let body = { query: query };
		let options = {
		  headers: { Authorization: `Bearer ${this.authorization?.token}` },
		};
		return this.http
		  .post(this.graphApi, body, options)
		  .pipe(catchError(ServiceErrors.handleError));
	}

}
