import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { ToursService } from '../services/tours.service'
import { Showing, RequestActions } from '../showing'
import { Tour } from '../tour'
import { Property } from '../propertySearch'
import { Client, ClientFavorites, ClientComments } from '../client'
import { checkForAuthError, firstOf, dateFromEpochTime, appointmentDateWithYear,validateInput,formatDate, formatTime } from '../utils'
import { TourSummaryCardComponent } from '../tour-summary-card/tour-summary-card.component'
import { TourSummaryDetailsCardComponent } from '../tour-summary-details-card/tour-summary-details-card.component'
import { MatSnackBar } from '@angular/material/snack-bar';


TourSummaryDetailsCardComponent

@Component({
  selector: 'app-tour-summary',
  templateUrl: './tour-summary.component.html',
  styleUrls: ['./tour-summary.component.css']
})
export class TourSummaryComponent implements OnInit {

  tour: Tour

  tourId: string

  error: string = null

  showTourSummaryModal: boolean = false;
  showSpinner:boolean = false
  submitBtn:boolean=false;

  errorInvalidEmail: boolean = false
  sortCriteria: string = ''; // Default sort criteria
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toursService: ToursService,
    private snackBar:MatSnackBar,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {

    // Get the tourId passed in the url and fetch the tour
    this.route
      .params
      .subscribe( (params) => {
        this.tourId = params['tourId']

        this.fetchTourSummary(this.tourId)

      })

  }

  formatTime(dateTimeString?){
    if(dateTimeString){
      let [date, time] = dateTimeString.split(" ");
      console.log(time);
      const [hourString, minute] = time.split(":");
      const hour = +hourString % 24;
      let time12 = (hour % 12 || 12) + ":" + minute +" " +(hour < 12 ? "AM" : "PM");
      let formatDate = date +" "+ time12;
      return formatDate;
    }
  }

  getClient() {
    return firstOf(this.tour.clients)
  }

  /**
   Fetch this tour with client & showing information
  */  
  fetchTourSummary(tourId) {

    this.error = null
    this.showSpinner=true;
    this.toursService
      .fetchTourSummary(tourId)
      .subscribe(tours => { 

    
          // Assume for alpha stage that an error means logged out
          if ( tours["errors"] != null ) {
            this.error = checkForAuthError( tours["errors"], this.router )
            this.showSpinner=false;
            return
          }  

          // We should only have been given one tour
          this.tour = tours["data"]["tours"].shift()
          this.sortShowings(); 
          this.showSpinner=false;

    })
  }

  sortShowings() {
    if (!this.tour?.showings) return;

    switch (this.sortCriteria) {
      case 'price':
        this.tour.showings.sort((a, b) => a.property.propertyFeatures.featureData.ListPrice - b.property.propertyFeatures.featureData.ListPrice);
        break;
      case 'ratings':
        this.tour.showings.sort((a, b) => {
          const ratingA = a.propertyRatings.length > 0 ? a.propertyRatings[0].starRating : 0;
          const ratingB = b.propertyRatings.length > 0 ? b.propertyRatings[0].starRating : 0;
          return ratingB - ratingA; // Sort in descending order
        });
        break;
      case 'favorites':
        this.tour.showings.sort((a, b) => {
          const favA = a.clientFavorites.length > 0 ? a.clientFavorites[0].favoriteStatus : 0;
          const favB = b.clientFavorites.length > 0 ? b.clientFavorites[0].favoriteStatus : 0;
          return favB - favA; // Sort in descending order
        });
        break;
      default:
        break;
    }
  }

  sectionInfo() {
    let client = firstOf(this.tour?.clients)
    let showing = firstOf(this.tour?.showings)
    let clientName = client ? `${client.firstName} ${client.lastName}` : showing?.agent?.fullName ?? "";

    if(validateInput(this.tour?.tourDate)=="datetime"){
      this.tour.tourDate = formatTime(this.tour.tourDate);
    }
    else if(validateInput(this.tour?.tourDate)=="numeric"){
      this.tour.tourDate = new Date(Number(this.tour?.tourDate))
      this.tour.tourDate = formatDate((this.tour.tourDate))
    }

    return [
      clientName,
      // `${this.tour?.tourDate}`,
      // `${showing?.agent?.fullName ?? ""}`
    ]
  }

  scrollToAnchor(showingOrderAnchor) {
    let el = this.document.getElementById(`showing-${showingOrderAnchor}`);
    //el.scrollIntoView( {behavior: "smooth"});
  }


  favoriteStatus(showing: any): string {
    if (showing?.clientFavorites[0]?.favoriteStatus == 1) {
      return "./assets/ph_heart-fill.png";
    }
    
    return "./assets/solar_heart-outline.png";
  }

  RatingStatus(showing: any): string {
    const rating = showing?.propertyRatings?.[0]?.starRating;
  
    if (rating && rating >= 1 && rating <= 5) {
      const filledStars = rating;
      const emptyStars = 5 - rating;
      
      const filledStarHtml = '<img src="./assets/ph_star-fill-rating.png" alt="Filled Star" width="25">'.repeat(filledStars);
      const emptyStarHtml = '<img src="./assets/ph_star-non-rating.png" alt="Empty Star" width="25">'.repeat(emptyStars);
      
      return filledStarHtml + emptyStarHtml;
    }
    
    return '';
  }

  onOpenModal(){
		console.log("tourId is",this.tourId);
    this.showTourSummaryModal=true;
	}

  onSubmitGenerateLink(email) {
    this.showSpinner = true;
    this.submitBtn=true;
    if(!email){
      this.errorInvalidEmail = true;
      this.showSpinner = false;
      this.submitBtn=false;
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		let emails =[];
		 // Validate each email
		 let inValidEmails = [];
		 if(email){
			emails=  email.split(',');
		 emails.forEach(email => {
			console.log("object",inValidEmails,emailPattern.test(email.trim()));
			 if (!emailPattern.test(email.trim())) {
				inValidEmails.push(email.trim());
				
			 }
		 });
		}
    if (inValidEmails.length > 0) {
      this.errorInvalidEmail = true;
      this.showSpinner = false;
      this.submitBtn=false;
      return;
    }
    console.log("email is",email);
    console.log("tour id is",this.tourId);
		
      this.toursService.sendTourSummaryEmail(email,this.tourId).subscribe({next:(response)=>{
        this.showSpinner = false;
        console.log("reponse i am getting is",response);
			  this.snackBar.open("Tour summary emailed successfully!", 'Close', {
			  duration: 4000, // notification will automatically close after 5 seconds
			  horizontalPosition: 'center', // position the notification at the right corner of the screen
			  verticalPosition: 'top' // position the notification at the top of the screen
			  });
			  this.onCloseModal();
		  },error:(err:any)=>{console.log("error",err);}})
	}


  onCloseModal(){
    this.showTourSummaryModal=false;
  }
  
  

}
