import { Injectable } from '@angular/core';
// import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class LocalService {
 //service to save and retrieve data from local.
  constructor() { }

  public saveData(key: string, value: any) {
    localStorage.setItem(key, value);
    // localStorage.setItem(key, this.encrypt(value));
  }

  public getData(key: string) {
    return localStorage.getItem(key)
    // let data = localStorage.getItem(key)|| "";
    // return this.decrypt(data);
  }
  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }
  
  // private encrypt(txt: string): string {
  //   return CryptoJS.AES.encrypt(txt, this.key).toString();
  // }

  // private decrypt(txtToDecrypt: string) {
  //   return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
  // }
}
