import { Injectable } from '@angular/core';
import { Observable, Subject, of, throwError,BehaviorSubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


import { HttpClient,  HttpErrorResponse } from '@angular/common/http';
import { Authorization } from '../authorization'
import { LoginService } from '../services/login.service'
import { environment } from '../../environments/environment'
import { Agent } from '../agent';
import { AuthResponse } from '../authResponse';
import { ServiceErrors } from './service-errors';
import { orgUserdetails } from '@app/user';
import { Organization } from '@app/interface/organization';
import { capitalizeFirstLetter, capitalizeEachWord } from '@app/utils';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  // private userSelected = new EventEmitter()
  private graphApi: string = environment.graphApi;

  authorization: Authorization;

  private currentUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentUrl$: Observable<string> = this.currentUrlSubject.asObservable();

  private poiListUpdated = new BehaviorSubject<void>(null);
  poiListUpdated$ = this.poiListUpdated.asObservable();

  constructor(private loginService: LoginService, private http: HttpClient,private router: Router) {
    this.checkAuth();
  }

  getCurrentUrl(): Observable<string> {
    return this.currentUrl$;
  }

  notifyPoiListUpdate() {
    this.poiListUpdated.next();
  }

  updateCurrentUrl(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrlSubject.next(event.url);
      }
    });
    this.currentUrlSubject.next(this.router.url);
  }

  getAuthorization(){
    return this.loginService.getAuthorization();
	}
  getUserData()  {
		return this.loginService.getUser();
	}
  private notifyUserRequest = new BehaviorSubject<number>(0);
  currentNotifyUserRequest = this.notifyUserRequest.asObservable();
  // Observable string sources
  // private userAnnouncedSource = new Subject<string>();
  // private userConfirmedSource = new Subject<string>();

  // // Observable string streams
  // userAnnounced$ = this.userAnnouncedSource.asObservable();
  // userConfirmed$ = this.userConfirmedSource.asObservable();

  // // Service message commands
  // announceUser(user: string) {
  //   this.userAnnouncedSource.next(user);
  // }
  // // Service message commands
  // confirmUser(user: string) {
  //   this.userConfirmedSource.next(user);
  // }
  // showSpinner: boolean = false;
  // private isLoadingSubject = new BehaviorSubject<boolean>(false);
  // public isLoading$ = this.isLoadingSubject.asObservable();
  // show(): void {
  //   this.isLoadingSubject.next(true);
  // }

  // hide(): void {
  //   this.isLoadingSubject.next(false);
  // }
  updateNotifyUserRequest(message: number) {
    this.notifyUserRequest.next(message);
  }
  getUserRole() {
    return Number(this.loginService.isUserRole);
  }
  getUserMls() {
    return this.loginService?.user?.usermls;
  }
  /** @notify for once the user added then refresh the user list */
  private notify = new Subject<any>();
  /**
   * Observable string streams
   */
  notifyObservable$ = this.notify.asObservable();
  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }
  checkAuth() {
    this.authorization = this.loginService.getAuthorization();
    if (!this.authorization?.token) {
      console.error('No auth token present');
    }
  }
  // callUserList() {
  //   return;
  // }
  /** get all user list */
  getUserList(superiorId: number, roleId, mlsValue) {
    this.checkAuth();
    let orgId = this.loginService.user.orguserdetails[0].orgId;
    // let query = `query { getUsers (superiorId:${superiorId} roleId:${roleId}){ id status name email superiorId agent{ id link firstName lastName fullName phone avatar { url } mls { contractedMls { id mlsName } } } userroles {id roleName } } }`;
    let query;
    if (roleId == 2) {
      query = `query { getUsers (superiorId:${superiorId} roleId:${roleId} orgId:${orgId}){ id status name email superiorId agent{ id link firstName lastName fullName phone avatar { url } } usermls{ contractedMls {id mlsName} } orguserdetails {roleId, orgId } } }`;
    } else {
      query = `query { getUsers (superiorId:${superiorId} roleId:${roleId}){ id status name email superiorId agent{ id link firstName lastName fullName phone avatar { url } } usermls{ contractedMls {id mlsName} } orguserdetails {roleId, orgId }} }`;
    }

    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** deactivate the user */
  deactivateUser(id: any, newActivationValue: any) {
    this.checkAuth();
    const query = `mutation {
			deactivateUser(id: "${id}", deactivated: ${newActivationValue}) {
				email
			}
		  }`;

    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** delete user for both multiple and single record */
  deleteUser(id: any) {
    this.checkAuth();

    /* OrgId and RoleId of the logged user we are passing here */
    let orgId = this.loginService.user.orguserdetails[0].orgId;
    let roleId = this.loginService.user.orguserdetails[0].roleId;

    this.checkAuth();
    let query
    if(roleId == 1){
      query = `mutation{
        deleteUser(userList:[${id}] orgId:1 roleId:${roleId})
      }`;
    }
    else{
      query = `mutation{
        deleteUser(userList:[${id}] orgId:${orgId} roleId:${roleId})
      }`;
    }
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  deleteOrgAdmin(id: any) {
    this.checkAuth();
    const query = `mutation{
      deleteOrgAdmin(userId:${id}){message}
    }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  /** user reset password  */
  resetPasswordUser(id: number, email) {
    this.checkAuth();
console.log("email1",email)
    let query;
    
    if(email){
      query = `mutation{
        resetPassword(id:[${id}],additionalEmail:"${email}"){
          message
        }
      }`;
    }else{
      query = `mutation{
        resetPassword(id:[${id}]){
          message
        }
      }`;
    }
    console.log("query",query);
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  /** user verification  */
  sendUserMailVerification(id: number) {
    this.checkAuth();
    const query = `mutation{
      sendUserMailVerification(id:[${id}]){
        message
      }
    }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** get all user roles */
  getUserRoles(id) {
    this.checkAuth();
    const query = `query{
      getRoles(id:${id}){
        id,
        roleName
      }
    }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** get agent */
  getUser(userId: number) {
    this.checkAuth();

    let query = `query{ getUser(id:${userId}){  id name email status usermls{ contractedMls {id mlsName} } orguserdetails {roleId, orgId, organizations{name}}agent{  id firstName lastName phone}}}`;    
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /**	 Create a User ( not an agent )	*/
  createUser(agent: Agent, user: any, next: any, error: any) { 
    this.checkAuth();   
    let superiorId = this.loginService.user.id;
    let roleId = this.loginService.user.orguserdetails[0].roleId;
    let orgId;

    if(roleId == 2){
      orgId = this.loginService.user.orguserdetails[0].orgId;
    }
    else {
      orgId = user.organization;
    }
    let agentCreate = '';
    if (agent != null) {
      let agent_email = agent.email.toLowerCase();
      agentCreate = `agent:{firstName:"${agent.firstName}" lastName:"${agent.lastName}" email:"${agent_email}" phone:"${agent.phone}"}`;
    }
    let user_email = user.email.toLowerCase();
    /** create user query */
    let query =`mutation {addUser(userDetails:{name:"${user.name}" superiorId:${superiorId} email:"${user_email}" roleId:${Number(user.userRoleId)}, usermls:{mls:[${user.mls}]}, ${agentCreate},orgId:${orgId}}){token user{ name superiorId email agent { fullName link avatar { url } } } expires } }`;
    // let query = `mutation {addUser(userDetails:{name:"${user.name}" superiorId:${superiorId} email:"${user.email}" roleId:${Number(user.userRoleId)}, usermls:{mls:[${agent.mls}]}, ${agentCreate}}){token user{ name superiorId email agent { fullName link avatar { url } } } expires } }`;    
    console.log("query is",query);
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post<AuthResponse>(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError))
      .subscribe({
        next: (auth) => {
          return next(auth);
        },
        error: (error) => {
          // Capture server error messages
          if (error.errors != undefined) {
            if (error != undefined) {
              error(error.errors[0].message);
            }
            return;
          }
        },
      });
  }
  /** Update User */
  updateUser(
    userId: number,
    deactivated: number,
    user: any,
    next: any,
    error: any
  ) {
    this.checkAuth();
    const mlsArray = user.mls
      .map((mlsItem: any) => {
        if (typeof mlsItem === 'string') {
          return `"${mlsItem}"`;
        } else if (mlsItem?.contractedMls && mlsItem?.contractedMls?.id) {
          return `"${mlsItem.contractedMls.id}"`;
        }
        return null;
      })
      .filter((mlsItem: any) => mlsItem !== null);
      let roleId = this.loginService.user.orguserdetails[0].roleId;
      let orgId:number;
      if(roleId == 2){
        orgId = this.loginService.user.orguserdetails[0].orgId;
      }
      else {
        orgId = user.orgId;
      }

      const storedEmail = localStorage.getItem('userEmail');

      let query
      if (storedEmail === user.email) {
        query = `mutation{ updateUser(id:${userId} name:"${
          user.name
        }" firstName:"${user.firstName}" lastName:"${user.lastName}" roleId:${
          user.userRoleId
        } phone:"${user.phone}" status:${deactivated} mls: [${mlsArray.join(',')}] orgId:${orgId}){
            message }}`;
      } else {
        deactivated = 2;
        query = `mutation{ updateUser(id:${userId} name:"${
          user.name
        }" firstName:"${user.firstName}" lastName:"${user.lastName}" email:"${user.email}" roleId:${
          user.userRoleId
        } phone:"${user.phone}" status:${deactivated} mls: [${mlsArray.join(',')}] orgId:${orgId}){
            message }}`;
      }

    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError))
      .subscribe({
        next: (auth) => {
          return next(auth);
        },
        error: (error) => {
          // Capture server error messages
          if (error.errors != undefined) {
            if (error != undefined) {
              error(error.errors[0].message);
            }
            return;
          }
        },
      });
  }
  /** add or remove mls */
  addRemoveMls(id: any, mlsvalue: any, userId: number) {
    this.checkAuth();
    const query = `mutation { addRemoveMls(agentId: ${id}, mls: [${mlsvalue}],userId:${userId}) {  message  } }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** search suggestion  */
  searchSuggestion(superiorId: number, searchVal: string, roleId: number) {
    this.checkAuth();
    let orgId = this.loginService.user.orguserdetails[0].orgId;
    let query;
    if (roleId == 2) {
      query = `query{ getUsers(superiorId:${superiorId},searchVal:"${searchVal}",roleId:${roleId},orgId:${orgId}){  id status name email superiorId agent{ id link firstName lastName fullName phone avatar { url } } usermls{ contractedMls {id mlsName} } orguserdetails {roleId, orgId } } }`;    
    } else {
      query = `query{ getUsers(superiorId:${superiorId},searchVal:"${searchVal}",roleId:${roleId}){  id status name email superiorId agent{ id link firstName lastName fullName phone avatar { url } } usermls{ contractedMls {id mlsName} } orguserdetails {roleId, orgId } } }`;    
    }

    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** filter status  */
  filterUser(superiorId: number, filterData: any, roleId: any) {
    this.checkAuth();
    let orgId = this.loginService.user.orguserdetails[0].orgId;

    let query;
    if (roleId == 2) {
      query = `query{ getUsers(superiorId:${superiorId} orgId:${orgId} roleId:${roleId},mlsVal:[${filterData.mlsService.map((x: any) => Number(x))}] status:[${filterData.status.map((x: any) =>Number(x))}])
      {id status name email superiorId agent{ id link firstName lastName fullName phone avatar { url } } usermls{ contractedMls {id mlsName} } orguserdetails {roleId, orgId } } }`;
    } else {
      query = `query{ getUsers(superiorId:${superiorId} roleId:${roleId},mlsVal:[${filterData.mlsService.map((x: any) => Number(x))}] status:[${filterData.status.map((x: any) =>Number(x))}])
      {id status name email superiorId agent{ id link firstName lastName fullName phone avatar { url } } usermls{ contractedMls {id mlsName} } orguserdetails {roleId, orgId } } }`;    }

    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /*** POI category get*/
  getPoiCategory() {
    this.checkAuth();

    let query = `query{ getCatSubCategories{  id name subcategory{ id name }} }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /*** add POI category */
  addPoiCategory(data: any) {
    this.checkAuth();
    const superiorId = this.loginService.user.id;
    let query = `mutation{  saveMasterCategory(name:"${data.name}",userId:${superiorId}){  name  } }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /*** edit POI category */
  editPoiCategory(data: any) {
    this.checkAuth();
    const superiorId = this.loginService.user.id;
    let query = `mutation{ editCategory(id:${data.id},name:"${data.name}",userId:${superiorId}){ message } }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /*** add POI sub category */
  saveMasterSubCategory(name: any, catid: any, userId: number) {
    this.checkAuth();
    const query = `mutation {
      saveMasterSubCategory(name: "${name}", catId: ${catid}, userId: ${userId}) {
        name,
        id
      }
    }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /*** edit POI sub category */
  editSubCategory(id: any, name: any, userId: number) {
    this.checkAuth();
    const query = `mutation { editSubCategory(name: "${name}", id: ${id}, userId: ${userId}) { message } }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /*** delete POI sub category */
  deleteSubCategory(id: any) {
    this.checkAuth();
    const query = `mutation { deleteSubCategory(id: [${id}]) {  message   }  }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /*** delete POI category */
  deleteCategory(id: any) {
    this.checkAuth();
    const query = `mutation {
      deleteCategory(id:[${id}]) {
        message
      }
    }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  /** POI  get list*/
  getPoiList(request:string) {
    this.checkAuth();
    let query = '';
    if(request && request=='displayName'){
      query = `query{ getAllPoiList{ id displayName status phoneNumber} }`;  
    }else{
      // query = `query{ getPoiList{ id businessName displayName description address latitude longitude status  poicategory{id category{id name} poisubcategory{id subcategory{id name} } } } }`;
      query = `query{ getAllPoiList{ id businessName displayName phoneNumber description address latitude longitude status  poicategory{id category{id name} poisubcategory{id subcategory{id name} } } } }`;
    }
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** get single POI  */
  getSingleMangePoi(poiId: number) {
    this.checkAuth();
    let query = `query{ poiFilterSearchView(id:${poiId}){  id businessName displayName description phoneNumber address latitude longitude status  poicategory{id category{id name} poisubcategory{id subcategory{id name} } } } }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** add POI */
  addManagePoi(data: any, next: any, error: any) {
    // return;
    this.checkAuth();
    const superiorId = this.loginService.user.id;
    let businessName = capitalizeEachWord(data.businessName)
    let query = ` mutation{ addNewPoi(userId:${superiorId}, businessName:"${businessName}",displayName:"${data.displayName}",description:"${data.description}",address:"${data.address}",latitude:"${data.latitude}",longitude:"${data.longitude}",catId:[${data.catId}],subCatId:[${data.subCatId}],status:${data.status},phoneNumber:"${data.contactNumber}"){ message } }`;
    console.log("query is",query);
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError))
      .subscribe({
        next: (auth) => {
          return next(auth);
        },
        error: (error) => {
          // Capture server error messages
          if (error.errors != undefined) {
            if (error != undefined) {
              error(error.errors[0].message);
            }
            return;
          }
        },
      });
  }
  /** edit POI */
  editManagePoi(poiId: number, data: any, next: any, error: any) {
    this.checkAuth();
    const superiorId = this.loginService.user.id;
    let businessName = capitalizeEachWord(data.businessName)
    let query = `mutation{ editManagePoi(id:${poiId},userId:${superiorId},businessName:"${businessName}",displayName:"${data.displayName}",description:"${data.description}",phoneNumber:"${data.contactNumber}",address:"${data.address}",latitude:"${data.latitude}",longitude:"${data.longitude}",status:${data.status},catId:[${data.catId}],subCatId:[${data.subCatId}]){  message  } }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError))
      .subscribe({
        next: (auth) => {
          return next(auth);
        },
        error: (error) => {
          // Capture server error messages
          if (error.errors != undefined) {
            if (error != undefined) {
              error(error.errors[0].message);
            }
            return;
          }
        },
      });
  }
  /** delete POI */
  deleteManagePoi(id: any) {
    this.checkAuth();
    const query = `mutation{
      deleteManagePoi(id:[${id}]){message}
    }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** search suggestion POI */
  searchSuggestionPoi(searchVal: string) {
    this.checkAuth();
    let query = `query{ poiFilterSearchView(searchVal:"${searchVal}"){  id businessName displayName description address latitude longitude status  poicategory{id category{id name} poisubcategory{id subcategory{id name}}}} }`;

    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  /** filter status  */
  filterManagePoi(filterData: any) {
    this.checkAuth();

    let query = '';

    if (filterData.status != null) {
      query = `query{ poiFilterSearchView(status:${
        filterData.status
      } catId:[${filterData.catId.map((x: any) =>
        Number(x)
      )}]){  id businessName displayName description address latitude longitude status  poicategory{id category{id name} poisubcategory{id subcategory{id name} } } }}`;
    } else {
      query = `query{ poiFilterSearchView( catId:[${filterData.catId.map(
        (x: any) => Number(x)
      )}]){  id businessName displayName description address latitude longitude status  poicategory{id category{id name} poisubcategory{id subcategory{id name} } } }}`;
    }
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** get mls count  */
  getViewRequestCount(roleId: number,orgId: number) {
    this.checkAuth();
    const query = ` query{
        getViewRequestCnt(orgId:${orgId},roleId:${roleId}){
          message
        }
      }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
    }
 
  /** get user request list */
  getViewRequest(roleId: number, orgId: number) {
    this.checkAuth();
    console.log("roleId is",roleId);  
    let query;
    if(roleId == 1){
      query = ` query{  getViewRequest(roleId:${roleId}){ id    name    email  agent{phone}  createdAt  usermls{ contractedMls{   id,  mlsName    }   }   archived orguserdetails{roleId, id, organizations{ id } }   requestPending  } }`;
    }
    else{
      query = ` query{  getViewRequest(roleId:${roleId},orgId:${orgId}){ id    name    email  agent{phone}  createdAt  usermls{ contractedMls{   id,  mlsName    }   }   orguserdetails{roleId, id, organizations{ id } } archived    requestPending  } }`;
    }

    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** get user mls request list */
  getMlsViewRequest(roleId: number,orgId: number) {
    this.checkAuth();

    let query;

    if(roleId == 1){
      query = `query{ getMlsRequest(roleId:${roleId}){ id, orgId, name, phone, email,  createdAt, userId,	usermlsrequest{ contractedMls{ id, mlsName } } } }`;
    }
    else{
      query = `query{ getMlsRequest(roleId:${roleId},orgId:${orgId}){ id, orgId, name, phone, email,  createdAt, userId,	usermlsrequest{ contractedMls{ id, mlsName } } } }`;
    }

    // let query = `query{ getMlsRequest{ id, orgId, name, phone, email,  createdAt, userId,	usermlsrequest{ contractedMls{ id, mlsName } } } }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
    }
  /** get user lead request list */
  getViewLeadRequest() {
    this.checkAuth();
    let query = `query{getLeadRequest { id userName firstName lastName email phone createdAt } }`
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** approve user request */
  approveUser(userId: number,mls:any) {
    this.checkAuth();
    let query = `mutation{ existNewUser(id:[${userId}] mls:[${mls}]){ message } }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
   /** approve mls request */
   approveMlsUser(userData:any ,mls:any) {
    this.checkAuth();
    let query = `mutation{ approveMlsRequest(userId:${userData.userId},mlsreqId:${userData.id},orgId:${userData.orgId},mls:[${mls}]){ message }}`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** restore user request */
  approveRestoreUser(userId: number,mls:any) {
    this.checkAuth();
    let query = `mutation{  approveRestoreUser(id:[${userId}] mls:[${mls}]){ message } }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
   /** delete lead request list */
   deleteLeadRequest(id:number) {
    this.checkAuth();
    let query = `mutation{ deleteLeadRequest(id:${id}){ message } }`
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  approveRestoreArchivedUser(userId: number,orgId,mls:any) {
    this.checkAuth();
    let query = `mutation{  approveRestoreArchivedUser(id:${userId},orgId:${orgId},mls:[${mls}]){ message } }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  
  deleteMlsRequest(id:number) {
    this.checkAuth();
    let query = `mutation{ deleteRequestedMls(id:${id}){ message } }`
    console.log("query is",query);
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** decline user request */
  declineUser(userId: number) {
    this.checkAuth();
    let query = `mutation{ declineUser(id:[${userId}]){ message } }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  archiveDeclineUser(userId: number) {
    this.checkAuth();
    let query = `mutation{ archiveDeclineUser(id:${userId}){ message } }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  /** manage org get list */
  orgList(): Observable<any> {
    this.checkAuth();

    let query = `query{ getOrgsList{
      id,  name, address,description,status, createdAt,
      orguserdetails{roleId}
      orgmls{
        contractedMls{
          id,
          mlsName
        }
        
      }
      } }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }
  getOrgDetail(orgId: number): Observable<any>{
    this.checkAuth();
    let query = `query{ getOrgData(orgId:${orgId}){ 
      id,  name, address,description,status, NoOflicenses,NoOfadmins, createdAt,createdBy
      orgmls{ contractedMls{ id, mlsName  }} 
      pocdetails{id,name,phone,email,altName,altPhone,altEmail,orgId}
      avatar{url}
      orguserdetails{      roleId,  user{   id, name ,     email agent{  phone }  },   userroles{     id,  roleName  }  }      
     }  }`;
    //  orguserdetails{  id,  userId,  orgId,  roleId, organizations{id}, user{id name } },
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  addOrganization(data: any, next: any, error: any) {
    console.log("data is",data);
    this.checkAuth();
    const superiorId = this.loginService.user.id;
    const createdBy = this.loginService.user.id;
    let OrgName = capitalizeEachWord(data.name)
    let query = `mutation{ createOrganization(name:"${OrgName}",address:"${data.address}",description:"${data.description}",status:${data.status},NoOfadmins:${data.NoOfadmins},NoOflicenses:${data.NoOflicenses},createdBy:${createdBy},pocdetails:{name:"${data.pocdetails.name}",email:"${data.pocdetails.email.toLowerCase()}",phone:"${data.pocdetails.phone}",altName:"${data.pocdetails.altName}",altPhone:"${data.pocdetails.altPhone}",altEmail:"${data.pocdetails.altEmail.toLowerCase()}"},orgmls:{mls:[${data.orgmls}]},userDetails:{name:"${data.admin_username}",superiorId:${superiorId},email:"${data.admin_email.toLowerCase()}",roleId:2,usermls:{mls:[${data.orgmls}]} agent:{ firstName:"${data.admin_firstName}",lastName:"${data.admin_lastName}",email:"${data.admin_email.toLowerCase()}",phone:"${data.admin_phone}" }}){ id }}`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError))
      .subscribe({
        next: (auth) => {
          return next(auth);
        },
        error: (error) => {
          // Capture server error messages
          if (error.errors != undefined) {
            if (error != undefined) {
              error(error.errors[0].message);
            }
            return;
          }
        },
      });
  }
  editOrgDetail(data: any,pocdetails:any[],next: any, error: any) {
    this.checkAuth();
    const superiorId = this.loginService.user.id;
    let orgmlsIds;
    if (data.orgmls.length > 0 && typeof data.orgmls[0] === 'object') {
      orgmlsIds = data.orgmls.map(orgml => orgml.contractedMls.id);
    }
    else{
      orgmlsIds = data.orgmls;
    }
    let OrgName = capitalizeEachWord(data.name)
    let query =`mutation{
      updateOrgDetails(id:${data.id},name:"${OrgName}",address:"${data.address}",description:"${data.description}",status:${data.status},NoOfadmins:${data.NoOfadmins},NoOflicenses:${data.NoOflicenses},createdBy:1,orgmls:{mls:[${orgmlsIds}]},pocdetails:{id:${data.pocdetails.id},name:"${data.pocdetails.name}",email:"${data.pocdetails.email.toLowerCase()}",phone:"${data.pocdetails.phone}",altName:"${data.pocdetails.altName}",altPhone:"${data.pocdetails.altPhone}",altEmail:"${data.pocdetails.altEmail.toLowerCase()}"}){
        message
      }
    }`;
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError))
      .subscribe({
        next: (auth) => {
          return next(auth);
        },
        error: (error) => {
          // Capture server error messages
          if (error.errors != undefined) {
            if (error != undefined) {
              error(error.errors[0].message);
            }
            return;
          }
        },
      });
  }

  orgAvatar(data, orgId) {
		const fileExtension = (s) => {
			const lastDot = s.lastIndexOf('.');
			const filename = s.substring(0, lastDot);
			const ext = s.substring(lastDot + 1);
			return {
				filename : filename,
				extension: ext
			}
		}

		if ( data == null ) {
			return
		}
		
		this.checkAuth()
    let path =  environment.restApi.orgAvatarUpload
		let idKey = "x-metadata-org-id"
		let idValue = orgId

	  let options = { 
	  		headers : { 
  				"Authorization" : `Bearer ${this.authorization.token}`,
  				"x-metadata-file-extension" : fileExtension(data.name).extension
	  		}
	  	}

	  options.headers[idKey] = idValue

		const formData: FormData = new FormData();

		formData.append('image', data);
	  	
	  	return this.http.post(path, formData, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

  licenseAvailables(orgId: number, roleId: number): Observable<any>{
    this.checkAuth();
    let query = `query{
      licenseAvailables(orgId:${orgId},roleId:${roleId}){
        message
      }
    }`;
    console.log("query is",query);
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  validateUpdateLicenses(orgId: number, adminCnt: number, licenseCnt: number): Observable<any>{
    this.checkAuth();
    let query = `query{
      validateUpdateLicenses(orgId:${orgId},adminCnt:${adminCnt},licenseCnt:${licenseCnt}){
        message
      }
    }`;
    console.log("query is",query);
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  getAvailableLicenses(): Observable<any>{
    this.checkAuth();
    let query = `query{ getAvailableLicenses {orgId, NoOfadmins, NoOflicenses, availablesLicenses, orgName}}`;
    console.log("query is",query);
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  // API to create a bulk user
  createBulkUser(data,next: any, error: any) { 
    this.checkAuth();  
    console.log("data is",data);
    console.log("data we are getting in createBulkUser method is",data); 
    let superiorId = this.loginService.user.id;
    let roleId = this.loginService.user.orguserdetails[0].roleId;
    let orgId;
    const userDetailsArray = data.map((item: any) => {
      return `{` +
        `name:"${item.name}",` +
        `superiorId:${item.superiorId},` +
        `email:"${item.email.toLowerCase()}",` +
        `roleId:${item.roleId},` +
        `usermls:{mls:[${item.usermls.mls}]},` +
        `agent:{` +
          `firstName:"${capitalizeFirstLetter(item.agent.firstName)}",` +
          `lastName:"${capitalizeFirstLetter(item.agent.lastName)}",` +
          `email:"${item.agent.email.toLowerCase()}",` +
          `phone:"${item.agent.phone.replace(/-/g, '')}"` +
        `},` +
        `orgId:${item.orgId}` +
      `}`;
    });
  
    // Now, userDetailsArray contains the transformed data
    console.log("Transformed userDetailsArray:", userDetailsArray);

    let query = `mutation{bulkUploadUser(userDetails:[${userDetailsArray.join(',')}]){email,name,message}}`;
    console.log("Mutation Query:", query);
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post<AuthResponse>(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError))
      .subscribe({
        next: (auth) => {
          return next(auth);
        },
        error: (error) => {
          // Capture server error messages
          if (error.errors != undefined) {
            if (error != undefined) {
              error(error.errors[0].message);
            }
            return;
          }
        },
      });
  }

  createBulkPoi(data,next: any, error: any) { 
    this.checkAuth();  
    console.log("data is",data);
    const poiDetailsArray = data.map((item: any) => {
      return `{` +
        `userId:${item.userId},` +
        `businessName:"${capitalizeEachWord(item.businessName)}",` +
        `displayName:"${item.displayName}",` +
        `description:"${item.description}",` +
        `phoneNumber:"${item.phoneNumber}",` +
        `address:"${item.address}",` +
        `latitude:"${item.latitude}",`+
        `longitude:"${item.longitude}",`+
        `catId:${item.catId},` +
        `subCatId:[${item.subCatId}],` +
        `status:${item.status},` +
      `}`;
    });
  
    // Now, userDetailsArray contains the transformed data
    // console.log("Transformed userDetailsArray:", poiDetailsArray);

    let query = `mutation{addBulkPoi(bulkPoi:[${poiDetailsArray.join(',')}]){message}}`;
    console.log("Mutation Query:", query);
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post<AuthResponse>(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError))
      .subscribe({
        next: (auth) => {
          return next(auth);
        },
        error: (error) => {
          // Capture server error messages
          if (error.errors != undefined) {
            if (error != undefined) {
              error(error.errors[0].message);
            }
            return;
          }
        },
      });
  }
  /***
   * getViewArchiveRequest
   */
  getViewArchiveRequest(){
    this.checkAuth();
    let query = `query{getArchivedList { id archived requestPending usermls{ id,contractedMls{id,mlsName}} orguserdetails{orgId,roleId} name email agent {phone} createdAt } }`
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization.token}` },
    };

    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  MlsRequestsearchSuggestion(searchVal: string) {
    this.checkAuth();

    let roleId = this.loginService.user.orguserdetails[0].roleId;
    let orgId = this.loginService.user.orguserdetails[0].orgId;
    let query = `query{ getMlsRequest(roleId:${roleId},orgId:${orgId},searchVal:"${searchVal}"){ id, orgId, name, phone, email,  createdAt, userId,	usermlsrequest{ contractedMls{ id, mlsName } } } }`; 
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  LeadRequestsearchSuggestion(searchVal: string) {
    this.checkAuth();
    let query = `query{ getLeadRequest(searchVal:"${searchVal}"){ id userName firstName lastName email phone createdAt }}`; 
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  ArchivedsearchSuggestion(searchVal: string) {
    this.checkAuth();
    let query = `query{ getArchivedList(searchVal:"${searchVal}"){ id archived requestPending usermls{ id,contractedMls{id,mlsName}} orguserdetails{orgId} name email agent {phone} createdAt }}`; 
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  ViewRequestsearchSuggestion(searchVal: string) {
    this.checkAuth();
    let roleId = this.loginService.user.orguserdetails[0].roleId;
    let orgId = this.loginService.user.orguserdetails[0].orgId;
    let query = `query{ getViewRequest(roleId:${roleId},orgId:${orgId},searchVal:"${searchVal}"){ id    name    email  agent{phone}  createdAt  usermls{ contractedMls{   id,  mlsName    }   }   archived orguserdetails{roleId, id, organizations{ id } }   requestPending  }  }`; 
    let body = { query: query };
    let options = {
      headers: { Authorization: `Bearer ${this.authorization?.token}` },
    };
    return this.http
      .post(this.graphApi, body, options)
      .pipe(catchError(ServiceErrors.handleError));
  }

  ManuallySetPassword( newPassword, userId ) {	
      const query = `mutation {
        manualResetPassword(id: ${userId}, newPassword: "${newPassword}") {
          message
      }
      }`;		
		  console.log("query");
	  	let body = { "query" : query } 
	    let options = { headers : { "Authorization" : `Bearer ${this.authorization?.token}`} }

	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}
}
