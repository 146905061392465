import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule, ChangeDetectorRef, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PropertiesComponent } from './properties/properties.component';
import { GoogleMapsModule } from '@angular/google-maps'
import { DragDropModule } from '@angular/cdk/drag-drop';

// Material
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


import { CookieModule } from 'ngx-cookie';
// import { NgxSpinnerModule } from "ngx-spinner";

import { SidenavComponent } from './sidenav/sidenav.component';
import { ToursComponent } from './tours/tours.component';
import { ClientComponent } from './client/client.component';
import { HeaderActionBarComponent } from './header-action-bar/header-action-bar.component';
import { PublicLandingComponent } from './public-landing/public-landing.component';
import { AddressComponent } from './address/address.component';
import { AddressAutocompleteComponent } from './address-autocomplete/address-autocomplete.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ConfirmListComponent } from './confirm-list/confirm-list.component';
import { ShowingListDraggableComponent } from './showing-list-draggable/showing-list-draggable.component';
import { BigAssButtonComponent } from './big-ass-button/big-ass-button.component';
import { ClientListComponent } from './client-list/client-list.component';
import { TourListComponent } from './tour-list/tour-list.component';
import { NewClientFormComponent } from './new-client-form/new-client-form.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { NewTourFormComponent } from './new-tour-form/new-tour-form.component';
import { PropertyListComponent } from './property-list/property-list.component';
import { HeaderNavBarComponent } from './header-nav-bar/header-nav-bar.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
// import { ImageGridComponent } from './image-grid/image-grid.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
// import { SpinnerComponent } from './spinner/spinner.component';
import { CheckmarkComponent } from './checkmark/checkmark.component';
import { FooterBarComponent } from './footer-bar/footer-bar.component';
import { FavoritePropertyCardComponent } from './favorite-property-card/favorite-property-card.component';
import { NoResultsComponent } from './no-results/no-results.component';
import { AgentProfileComponent } from './agent-profile/agent-profile.component';
import { RequestConfirmationComponent } from './request-confirmation/request-confirmation.component';
import { RequestConfirmationListComponent } from './request-confirmation-list/request-confirmation-list.component';
import { PropertyCardComponent } from './property-card/property-card.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SuggestedResponseTimeComponent } from './suggested-response-time/suggested-response-time.component';
import { ShowingCardComponent } from './showing-card/showing-card.component';
import { ConfirmInputComponent } from './confirm-input/confirm-input.component';
import { BaseComponent } from './base/base.component';
import { TourSummaryCardComponent } from './tour-summary-card/tour-summary-card.component';
import { TourSummaryListComponent } from './tour-summary-list/tour-summary-list.component';
import { TourSummaryComponent } from './tour-summary/tour-summary.component';
import { TourSummaryDetailsCardComponent } from './tour-summary-details-card/tour-summary-details-card.component';
import { ImageViewerElementComponent } from './image-viewer-element/image-viewer-element.component';
import { ImageRowComponent } from './image-row/image-row.component';
import { AgentCardComponent } from './agent-card/agent-card.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataVisualizationComponent } from './data-visualization/data-visualization.component';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import {APP_BASE_HREF} from '@angular/common';
import { PropertyNewComponent } from './property-new/property-new.component';
import { ViewClientListNewComponent } from './view-client-list-new/view-client-list-new.component';
import { TourHistoryNewComponent } from './tour-history-new/tour-history-new.component';
import { SearchPropertyNewComponent } from './search-property-new/search-property-new.component';
import { MembershipNewComponent } from './membership-new/membership-new.component';
import { SaveMembershipProfileComponent } from './save-membership-profile/save-membership-profile.component';
import { AgentProfileEditComponent } from './agent-profile-edit/agent-profile-edit.component';
import { CancelButtonComponent } from './cancel-button/cancel-button.component';
import { ClientProfileComponent } from './client-profile/client-profile.component';
// import { StrictNumberOnlyDirective } from 'src/app/utils/StrictNumbersOnlyDirective'
// import { UsernameDirective } from 'src/app/utils/UserNameDirective'
// import { FirstNameDirective } from 'src/app/utils/FirstNameDirective'
// import { LastNameDirective } from 'src/app/utils/LastNameDirective'
import { CityDirective } from 'src/app/utils/CityDirective'
import { ZipCodeDirective } from 'src/app/utils/ZipcodeDirective';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { RegisterComponent } from './register/register.component';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordModalComponent } from './forgot-password-modal/forgot-password-modal.component';
import { SharedModule } from './shared/shared.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { licenseNumberDirective } from './utils/LicenceNumberDirective';
import { PhoneDirective } from './utils/PhoneDirective';
import { WishListComponent } from './wish-list/wish-list.component';
import { LicenceRegionDirective } from './utils/LicenceRegionDirective';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TourPreviewComponent } from './tour-preview/tour-preview.component';
import { AddressCompleteComponent } from './address-complete/address-complete.component';
import { NameDirective } from './utils/NameDirective';
import { CharacterDirective } from './utils/CharacterDirective';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PropertiesComponent,
    SidenavComponent,
    ToursComponent,
    ClientComponent,
    HeaderActionBarComponent,
    PublicLandingComponent,
    AddressComponent,
    AddressAutocompleteComponent,
    ConfirmComponent,
    ConfirmListComponent,
    ShowingListDraggableComponent,
    BigAssButtonComponent,
    ClientListComponent,
    TourListComponent,
    NewClientFormComponent,
    SearchBarComponent,
    NewTourFormComponent,
    PropertyListComponent,
    HeaderNavBarComponent,
    PropertyDetailsComponent,
    // ImageGridComponent,
    ImageViewerComponent,
    // SpinnerComponent,
    CheckmarkComponent,
    FooterBarComponent,
    FavoritePropertyCardComponent,
    NoResultsComponent,
    AgentProfileComponent,
    RequestConfirmationComponent,
    RequestConfirmationListComponent,
    PropertyCardComponent,
    PageNotFoundComponent,
    SuggestedResponseTimeComponent,
    ShowingCardComponent,
    ConfirmInputComponent,
    BaseComponent,
    TourSummaryCardComponent,
    TourSummaryListComponent,
    TourSummaryComponent,
    TourSummaryDetailsCardComponent,
    ImageViewerElementComponent,
    ImageRowComponent,
    AgentCardComponent,
    DashboardComponent,
    DataVisualizationComponent,
    PropertyNewComponent,
    ViewClientListNewComponent,
    TourHistoryNewComponent,
    SearchPropertyNewComponent,
    MembershipNewComponent,
    SaveMembershipProfileComponent,
    AgentProfileEditComponent,
    CancelButtonComponent,
    ClientProfileComponent,
    // StrictNumberOnlyDirective,
    // UsernameDirective,
    // FirstNameDirective,
    // LastNameDirective,
    licenseNumberDirective,
    CityDirective,
    ZipCodeDirective,
    ErrorModalComponent,
    RegisterComponent,
    PhotoGalleryComponent,
    ChangePasswordComponent,
    // AlertDialogComponent,
    ForgotPasswordComponent,
    ForgotPasswordModalComponent,
    PhoneDirective,
    WishListComponent,
    LicenceRegionDirective,
    ContactFormComponent,
    SubscriptionComponent,
    TourPreviewComponent,
    AddressCompleteComponent,
    NameDirective,
    CharacterDirective,
  ],
  imports: [
    // NgxSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatTabsModule,
    MatSelectModule,
    MatExpansionModule,
    MatTableModule,
    MatCheckboxModule,
    MatGridListModule,
    MatSidenavModule,
    MatDividerModule,
    MatDialogModule,
    DragDropModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatSortModule,
    MatSnackBarModule,
    MatPaginatorModule,
    CarouselModule.forRoot(),
    NgbModule,
    
    // FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    GoogleMapsModule,
    NgxMatSelectSearchModule,
    CookieModule.forRoot(),
    SharedModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    MatTooltipModule,
  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
   // {provide: APP_BASE_HREF, useValue: '/livepad'}
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
