<form [formGroup]="changePasswordForm" (ngSubmit)="changePasswordFormSubmit()">
  <div class="logo-modal-position">
                    <img src="./assets/logo-symbol.png" alt="Livepad Logo" tabindex="0">
                    <h5 class="modal-title" tabindex="0">Change Password</h5>
                   </div>
    <div>
      <label for="oldPassword" tabindex="0">Old Password <span class="red">*</span></label>
      <div class="position-relative">
        <input  type="{{ showOldPassword ? 'text' : 'password' }}" id="oldPassword" formControlName="oldPassword" autocomplete="off" (focus)="fromStartFrom()"/>
        <span (click)="toggleFieldType('old')" class="position-absolute" style="top: 50%;right: 1px;transform: translate(-50%, -50%);cursor: pointer;" *ngIf="(changePasswordForm.get('oldPassword')?.value)">
          <img src="{{showOldPassword?'assets/eye-password.svg':'assets/eye-off-password.svg'}}" width="16" alt="view icon1"/>
        </span>
      </div>
      <div *ngIf="changePasswordForm.get('oldPassword').invalid && changePasswordForm.get('oldPassword').touched">
        <div class="error-message" *ngIf="changePasswordForm.get('oldPassword').errors.required">Old Password is required.</div>
      </div>
    </div>
  
    <div>
      <label for="newPassword" tabindex="0">New Password <span class="red">*</span></label>
      <div class="position-relative">
        <input type="{{ showNewPassword ? 'text' : 'password' }}" id="newPassword" formControlName="newPassword" autocomplete="off" (focus)="fromStartFrom()"/>
        <span (click)="toggleFieldType('new')" class="position-absolute" style="top: 50%;right: 1px;transform: translate(-50%, -50%);cursor: pointer;" *ngIf="(changePasswordForm.get('newPassword')?.value)">
          <img src="{{showNewPassword?'assets/eye-password.svg':'assets/eye-off-password.svg'}}" width="16" alt="view icon2"/>
        </span>
      </div>
      <div *ngIf="changePasswordForm.get('newPassword').invalid && changePasswordForm.get('newPassword').touched">
        <!-- <div class="error-message" *ngIf="changePasswordForm.get('newPassword').errors.required">New Password is required.</div> -->
        <!-- <div class="error-message" *ngIf="changePasswordForm.get('newPassword').errors.minlength">
          New Password must be at least 8 characters long.
        </div> -->
        <div   *ngIf="changePasswordForm.get('newPassword').invalid && (changePasswordForm.get('newPassword').dirty || changePasswordForm.get('newPassword').touched)">
          <div *ngIf="changePasswordForm.get('newPassword').errors.required" class="error-message">New Password is required.</div>
          <div *ngIf="changePasswordForm.get('newPassword').errors.invalidPassword" class="error-message">Password is not strong enough</div>
          <div *ngIf="changePasswordForm.get('newPassword').errors.invalidPassword" >
            <p [style.color]="!changePasswordForm.get('newPassword').errors.minlength?'green':'error-message'"><img alt="mark icon1" [src]="!changePasswordForm.get('newPassword').errors.minlength?'assets/green-check-mark-tick.svg':'assets/close-icon.svg'" width="16"/> &nbsp;New Password must be at least 8 characters long</p>
            <p [style.color]="changePasswordForm.get('newPassword').errors.hasUpper && changePasswordForm.get('newPassword').errors.hasLower?'green':'error-message'"><img alt="mark icon" [src]="changePasswordForm.get('newPassword').errors.hasUpper && changePasswordForm.get('newPassword').errors.hasLower?'assets/green-check-mark-tick.svg':'assets/close-icon.svg'" width="16"/> &nbsp;Includes a upper and lowercase letter</p>
            <p [style.color]="changePasswordForm.get('newPassword').errors.hasNumber?'green':'error-message'"><img alt="mark icon2" [src]="changePasswordForm.get('newPassword').errors.hasNumber?'assets/green-check-mark-tick.svg':'assets/close-icon.svg'" width="16"/> &nbsp;At least one number</p>
            <p [style.color]="changePasswordForm.get('newPassword').errors.hasSymbol?'green':'error-message'"><img alt="mark icon3" [src]="changePasswordForm.get('newPassword').errors.hasSymbol?'assets/green-check-mark-tick.svg':'assets/close-icon.svg'" width="16"/> &nbsp;Includes a symbol</p>
          </div>
        </div>
        <!-- <div class="error-message" *ngIf="changePasswordForm.get('newPassword').errors.weakPassword">
          New Password must contain at least one uppercase letter, one lowercase letter, and one number.
        </div> -->
      </div>
    </div>
  
    <div>
      <label for="confirmPassword" tabindex="0">Confirm Password <span class="red">*</span></label>
      <div class="position-relative">
        <input type="{{ showPassword ? 'text' : 'password' }}" id="confirmPassword" formControlName="confirmPassword" autocomplete="off" (focus)="fromStartFrom()"/>
        <span (click)="toggleFieldType('confirm')" class="position-absolute" style="top: 50%;right: 1px;transform: translate(-50%, -50%);cursor: pointer;" *ngIf="(changePasswordForm.get('confirmPassword')?.value)">
          <img src="{{showPassword?'assets/eye-password.svg':'assets/eye-off-password.svg'}}" width="16" alt="view icon3"/>
        </span>
      </div>
      <div
        *ngIf="changePasswordForm.get('confirmPassword').invalid && (changePasswordForm.get('confirmPassword').dirty || changePasswordForm.get('confirmPassword').touched)"
      >
        <div class="error-message" *ngIf="changePasswordForm.get('confirmPassword').errors.required">Confirm Password is required.</div>
        <div class="error-message" *ngIf="changePasswordForm.get('confirmPassword').errors?.passwordMismatch">
          New Password and Confirm Password do not match.
        </div>
      </div>
    </div>
  
    <div class="form-actions">
        <button type="submit" [disabled]="changePasswordForm.invalid" class="btn-primary">CHANGE PASSWORD</button>
        <button type="reset" (click)="cancelChanges($event)" class="btn-secondary">CANCEL</button>
    </div>

    <div *ngIf="errorMessage">
        <div class="alert-error">{{ errorMessage }}</div>
    </div>
  </form>
  <app-spinner [show]="showSpinner"></app-spinner>

  
