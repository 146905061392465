<!-- <div class="container">

<div class="image">

	<button mat-fab class="skip-button" (click)="previous()" color="primary">
		<mat-icon class="skip-button">skip_previous</mat-icon>
	</button>

	<img *ngIf="isImage( getActiveImage() )" [src]="getActiveImage().url " >

	<video *ngIf="!isImage( getActiveImage() ) " controls >
		<source [src]="getActiveImage().url" type="video/{{ getActiveImage().type }}">
	</video>

	<button mat-fab class="skip-button" (click)="next()" color="primary">
		<mat-icon class="skip-button">skip_next</mat-icon>
	</button>

</div>
</div> -->

<!-- <div class="container">
<div class="image">
	<ng-container *ngFor="let image of viewerData.images">
	<img *ngIf="isImage(image)" [src]="image.url">
	<video *ngIf="!isImage(image)" controls>
		<source [src]="image.url" [type]="'video/' + image.type">
	</video>
	</ng-container>
</div>
</div> -->

<div class="gallery-carousel">
	<div class="image image-carosel">
		<ng-container *ngFor="let image of viewerData.images; index as i">
			<img *ngIf="isImage(image)" [src]="image.url" (click)="enlargeImageSlider(image.url, i)" alt="Images" />
			<video *ngIf="!isImage(image)" controls>
				<source [src]="image.url" [type]="'video/' + image.type" />
			</video>
		</ng-container>
	</div>

	<div *ngIf="enlargedImageSlider" class="enlarged-image popupSlider">
		<div style="position:relative;">
			<carousel [(activeSlide)]="popupIndex" class="slider" [showIndicators]="false" id="carouselCaptions">
				<slide *ngFor="let image of viewerData.images">
					<img [src]="image.url" [alt]="'Images'+i" />
				</slide>
			</carousel>
			<div class="carousel-indicators">
				<ng-container *ngFor="let image of viewerData.images;let i=index">
					<span class="dot" [class.active]="i===popupIndex" type="button" data-bs-target="#carouselCaptions"
						[data-bs-slide-to]="i" [aria-current]="i==popupIndex?true:false" (click)="currentSlide(i)"><b
							class="adafix">.</b></span>
				</ng-container>
			</div>
			<div (click)="closeEnlargedImageSlider()" class="closeButton" title="Close">X</div>
		</div>
	</div>