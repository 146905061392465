<div style="position: relative;">
	<!-- <div class="welcome" tabindex="0">
		<label class="horizontalline"><b class="adafix">.</b></label>
		{{"WELCOME"}}
		<label class="horizontalline"><b class="adafix">.</b></label>
	</div> -->
	<div align="center" *ngIf="!showRegistration">
		<div>
			<!-- <div class="triangle-bg">
				<img src="assets/livepad-logo-black.svg" alt="Livepad Logo" class="livpadLogo" tabindex="0">
				<div class="empty-space"></div>
			</div> -->	
			<div class="row">
			<div class="col-md-6">
				  <div class="login_background-img">
				    <div class="livepad-white-logo"><img src="./assets/logo-white.png" alt="livepad-white-logo"></div>
				  </div>
				</div>
			
		<div class="col-md-6">
		  <div class="login-form-box">
		    <div class="text-center"><img src="./assets/logo-symbol.png" width="54" height="40" alt="Livepad logo-symbol" tabindex="0"></div>
		    <h1 tabindex="0">Welcome</h1>
		    <p>Sign in to your account</p>
		    <div class="form-group mt-5">
		      <label for="loginEmail">Email</label>
		      <input type="text" [formControl]="emailControl" id="loginEmail" aria-labelledby="loginEmail" class="mt-3 inputLine form-control"
								style="background:transparent;" tabindex="0" (keyup.enter)="onEnter()" (focus)="formStartEvent()" [autocomplete]="'off'"/>
		    </div>
		    <div class="form-group">
		      <label for="loginPassword">Password</label>
		      <div class="position-relative">
								<input [type]="fieldTextType ? 'text' : 'password'" [formControl]="passwordControl" id="loginPassword" aria-labelledby="loginPassword" class="mt-3 inputLine form-control"
								style="background:transparent ;" tabindex="0" (keyup.enter)="onEnter()"/>
								<span (click)="toggleFieldTextType()" class="position-absolute eye-icon" *ngIf="(passwordControl.value)">
									<img src="{{fieldTextType?'assets/eye-password.svg':'assets/eye-off-password.svg'}}" width="16" alt="view icon"/>
								</span>
							</div>
		    </div>
			<div>
				<div class="error-message" *ngIf="emailControl.invalid || passwordControl.invalid || error">
					<ng-container *ngIf="emailControl.invalid && passwordControl.invalid">
						Please enter valid email and password
					</ng-container>
					<ng-container *ngIf="emailControl.invalid && !passwordControl.invalid">
						Valid email required
					</ng-container>
					<ng-container *ngIf="!emailControl.invalid && passwordControl.invalid">
						Valid password required
					</ng-container>
					<ng-container *ngIf="error && !emailControl.invalid && !passwordControl.invalid">
						{{ error }}
					</ng-container>
				</div>
				<div class="error-message" *ngIf="!(emailControl.invalid || passwordControl.invalid || error)">
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				</div>
			</div>
		    <button class="signin-btn" (click)="onLogin();pageCtaClick($event)">SIGN IN</button>
		    <div class="button-margin"><a  class="login-link text-start mt-1" tabindex="0" role="link" (click)="openForgotPasswordModal();pageCtaClick($event)" (keyup.enter)="openForgotPasswordModal()">Forgot password?</a></div>    
		     <div class="new-member">New member? <a class="login-link text-start mt-1" (click)="beginRegistration();pageCtaClick($event)" tabindex="0" (keyup.enter)="beginRegistration();">Create account</a></div>
		  </div>
		 
		</div>
			
		</div>
			<div class="">	
				<div class="">
					<div class="">
						<div class="" align="left">
							<!-- <label class="mt-3 labelName" tabindex="0">Email <span style="color: red">*</span></label>
							<input type="text" [formControl]="emailControl" id="loginEmail" aria-labelledby="loginEmail" class="mt-3 inputLine form-control"
								style="background:transparent;" tabindex="0" (keyup.enter)="onEnter()" (focus)="formStartEvent()" [autocomplete]="'off'"/> -->
							<!-- <div class="error-message" *ngIf="emailControl.invalid">Please enter a valid email</div> -->
							<!-- <div class="error-message">{{ emailControl.invalid ? 'Please enter a valid email' : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' }}</div> -->
							<!-- <label class="labelName passwordLabel" tabindex="0">Password <span style="color: red">*</span></label>
							<div class="position-relative">
								<input [type]="fieldTextType ? 'text' : 'password'" [formControl]="passwordControl" id="loginPassword" aria-labelledby="loginPassword" class="mt-3 inputLine form-control"
								style="background:transparent ;" tabindex="0" (keyup.enter)="onEnter()"/>
								<span (click)="toggleFieldTextType()" class="position-absolute" style="top: 50%;right: 1px;transform: translate(-50%, -50%);cursor: pointer;" *ngIf="(passwordControl.value)">
									<img src="{{fieldTextType?'assets/eye-password.svg':'assets/eye-off-password.svg'}}" width="16" alt="view icon"/>
								</span>
							</div> -->
							<!-- <div class="error-message" *ngIf="passwordControl.invalid">Please enter a valid password</div> -->
							<!-- <div class="error-message">{{ passwordControl.invalid ? 'Please enter a valid password' : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' }}</div> -->
							<!-- <div>
								<div class="error-message" *ngIf="error != null">{{error}}</div>
							</div> -->
							
							<!-- <div style="position: relative;"> -->
								<!-- <div class="btnsClass">
									<button type="submit" class="btn btnColor form-control" (click)="onLogin();pageCtaClick($event)" tabindex="0" role="button">
										LOG IN </button>
								</div> -->
								<!-- <span class="forgot-password-link" tabindex="0" role="link" (click)="openForgotPasswordModal();pageCtaClick($event)" (keyup.enter)="openForgotPasswordModal()">Forgot Password?</span> -->
								<!-- <div class="login-btns-container btnsClass">
									<span class="btn-text form-control newUser" tabindex="0">
										New Member?
									</span>
									<button type="submit" class="btn signup form-control"
											(click)="beginRegistration();pageCtaClick($event)" tabindex="0" role="button">
											SIGN UP
									</button>									
								</div> -->
							<!-- </div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #passwordPolicyDialog style="width: 50%;">
	<div>
	  <h2>We've updated our password policy.</h2>
	  <p>Please change your password accordingly to ensure compliance. Thank you.</p>
	  <button mat-button class="highlight-on-click"  (click)="closePasswordPolicyDialog()">Okay</button>
	</div>
</ng-template>
<app-spinner [show]="showSpinner"></app-spinner>
<app-checkmark *ngIf="showDone"></app-checkmark>