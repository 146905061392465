<ng-container *ngIf="!isEditing()">
	<app-agent-profile-edit 
		[agent]="agent"
		[mlsList]="mlsList"
		(buttonAction)="toggleEdit()" >
	</app-agent-profile-edit>
</ng-container>
<div *ngIf="isEditing()">
    <div class="content-container">
    	<h1 class="title" tabindex="0">
			<!-- <img src=" assets/dashboard-icon.svg" alt="Image" width="30" tabindex="0">&nbsp; -->
			{{ getTitle() }}
		</h1>
		<div class="row mt-2">
			<!-- <div class="col-md-2">
				<div class="imagePlacing" style="text-align:center;">
					<mat-label tabindex="0">Upload Profile Picture</mat-label>
					<img class="image" [src]="isValidImageUrl(agent.picture) ? agent.picture : './assets/avatar.jpg'" alt="Agent Picture" (click)="fileInput.click()" tabindex="0" />
					<input style="display: none" type="file" id="uploadImage" aria-labelledby="uploadImage" (change)="onFileChanged($event)" #fileInput tabindex="0">
				</div>
			</div> -->
			<div class="col-lg-2 col-md-3" (click)="fileInput.click()">
				<div class="imagePlacing" style="text-align:center;">
				  <p><img class="image" [src]="isValidImageUrl(agent.picture) ? agent.picture : './assets/avatar.jpg'" alt="Agent avatar" tabindex="0" /></p>
				  <mat-label class="upload-profile" tabindex="0">Upload Profile Picture</mat-label>
				  <input style="display: none" type="file" id="uploadImage" aria-labelledby="uploadImage" (change)="onFileChanged($event)" #fileInput tabindex="0" accept=".png, .jpg, .jpeg">
				</div>
			</div>
			<div class="col-lg-10 col-md-9 margin-shift">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="form-group" style="text-align:left;">
                            <label class="mt-3 profile-label" tabindex="0">First Name <span style="color: red">*</span></label>
                            <input type="text" [formControl]="firstNameControl" [(ngModel)]="agent.firstName" name="firstName" placeholder="First name" value="" class="inputLine form-control" id="fname" aria-labelledby="fname" style="background:transparent;" tabindex="0" FirstName autocomplete="off" (focus)="fromStartFrom()"/>
							<div class="error-message" *ngIf="firstNameControl.invalid">First name with no symbols, digits and space is required</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="form-group" style="text-align:left;">
                            <label class="mt-3 profile-label" tabindex="0">Last Name <span style="color: red">*</span></label>
                            <input type="text" [formControl]="lastNameControl" [(ngModel)]="agent.lastName" name="lastName" placeholder="Last name" class="inputLine form-control" id="lname" aria-labelledby="lname" style="background:transparent;" tabindex="0" LastName autocomplete="off" (focus)="fromStartFrom()"/>
							<div class="error-message" *ngIf="lastNameControl.invalid">Last name with no symbols, digits and space is required</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="form-group" style="text-align:left;">
                            <label class="mt-3 profile-label" tabindex="0">Phone <span style="color: red">*</span></label>
                            <input type="tel" [formControl]="phoneControl" [(ngModel)]="agent.phone" name="phone" placeholder="123-555-0121" value="" class="inputLine form-control" id="phone" aria-labelledby="phone" style="background:transparent;" tabindex="0" autocomplete="off" (keydown.Tab)="onKey($event);" appFormPhone (focus)="fromStartFrom()"/>
							<div class="error-message" *ngIf="phoneControl.invalid || hasValidationError">Valid phone number is required</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="form-group" style="text-align:left;">
                            <label #Email class="mt-3 profile-label" tabindex="0">Email <span style="color: red">*</span></label>
                            <input type="text" tabindex="0" [formControl]="emailControl" [readonly]="true" [(ngModel)]="agent.email" name="email" placeholder="someone@somewhere.com" value=""  class="inputLine form-control" id="email" aria-labelledby="email" style="background:transparent; pointer-events: none;" tabindex="0" autocomplete="off" (focus)="fromStartFrom()"/>
							<div class="error-message" *ngIf="emailControl.invalid">Valid email is required</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 custom-address">
						<label class="mt-3 profile-label" tabindex="0">Address</label>
						<app-address-autocomplete *ngIf="agent.address != undefined"
                            [(address)]="agent.address[0].fullAddress"
                            (cityChange)="agent.address[0].city = $event"
                            (zipCodeChange)="agent.address[0].zip = $event"
							(stateChange)="agent.address[0].state = $event" (focus)="fromStartFrom()">
                        </app-address-autocomplete>
                    </div>
					<div class="col-lg-2 col-md-6 ">
						<label class="mt-3 profile-label" tabindex="0">City</label>
						<input type="text" *ngIf="agent.address != undefined" [(ngModel)]="agent.address[0].city" class="inputLine form-control" tabindex="0"
							style="background:transparent ;" id="city" aria-labelledby="city" autocomplete="off" City (focus)="fromStartFrom()"/>
					</div>
					<div class="col-lg-2 col-md-6">
						<label class="mt-3 profile-label" tabindex="0">State</label>
						<input type="text" *ngIf="agent.address != undefined" [(ngModel)]="agent.address[0].state"  class="inputLine form-control" id="State" aria-labelledby="State"
							style="background:transparent ;" tabindex="0" autocomplete="off" state (focus)="fromStartFrom()"/>
					</div>
					<div class="col-lg-4 col-md-6">
						<label class="mt-3 profile-label" tabindex="0">ZIP Code</label>
						<input type="text" *ngIf="agent.address != undefined" [(ngModel)]="agent.address[0].zip" class="inputLine form-control" tabindex="0"
							style="background:transparent ;" id="zipcode" aria-labelledby="zipcode" autocomplete="off" ZipCode (focus)="fromStartFrom()"/>
						<div class="error-message" *ngIf="zipCodeError">Valid zip code is required</div>
					</div>
                </div>
				<div class="row textLine">
					<!-- <div class="section-header">
						<div class="title mt-4 mb-4">								
							<h2 tabindex="0">Memberships <i class="bi bi-plus-square plusicon" [ngClass]="{disabled: !isEditing()}" (click)="onAddMls()"></i></h2>
						</div>		
					</div> -->
					<div class="section-header">
						<div class="title mt-2">								
						  <h2 tabindex="0">Memberships</h2>
						</div>		
					</div>
					<!-- <div class="column flex-wrap">
						<div *ngFor="let agentMls of agent.mls; index as i">
							<div class="create-form">
								<div class="label-wrapper">
									<mat-label class="mls-label" tabindex="0">MLS Memberships</mat-label>
								</div>
								<input *ngIf="!isEditing()" matInput [(ngModel)]="agentMls.mlsName" name="{{agentMls.mlsName}}" disabled="true" tabindex="0" autocomplete="off">
								<mat-select *ngIf="isEditing()" [(value)]="agent.mls[i].mlsName" (selectionChange)="onSelectedMls(i, $event)" tabindex="0" disabled>
									<mat-option *ngFor="let mls of mlsList"  [disabled]="isMlsAdded(mls)" [value]="mls.mlsName">{{mls.mlsName}}</mat-option>
								</mat-select> 
							</div>
						</div>
					</div> -->
					<div class="row mt-2">
						<div class="membership-wrap">
						<div *ngFor="let agentMls of agent.mls; index as i">                                
						   <!-- <div class="label-wrapper">
							   <mat-label tabindex="0">MLS Memberships</mat-label>
						   </div> -->
						   <div class="membership-item" tabindex="0">{{agentMls.mlsName}}</div>                                    
						   <!-- <mat-select *ngIf="isEditing()" [(value)]="agent.mls[i].mlsName" (selectionChange)="onSelectedMls(i, $event)" tabindex="0">
							   <mat-option *ngFor="let mls of mlsList" [disabled]="isMlsAdded(mls)" [value]="mls.mlsName">{{mls.mlsName}}</mat-option>
						   </mat-select>                               -->
					   </div>
					</div>
					</div>
					<div class="section-header mt-2">
						<mat-label #licenses><h2>Licenses <button class="add-more-button" mat-icon-button color="link" (click)="onAddLicense()">
						  <i class="bi bi-plus-square plusicon large-icon"><b class="adafix">.</b></i>
						</button></h2></mat-label>
						
					</div>
					  
					<div class="licenses-container">
						<div class="license-item row" *ngFor="let license of agent.license; index as i">
							<!-- <button class="add-more-button" mat-icon-button color="warn" [disabled]="!isEditing() || agent.license.length === 1" (click)="onRemoveLicense(license, i, $event)">
								<mat-icon class="add-more-button-icon">remove_circle_outline</mat-icon>
						  	</button> -->
							<div class="col-md-1 delete-column">
								<button class="add-more-button" mat-icon-button color="warn" (click)="onRemoveLicense(license, i, $event)">
									<mat-icon class="add-more-button-icon">remove_circle_outline</mat-icon>
								</button>
							</div>
							<div class="col-md-5">
								<div class="create-form-field">
									<label for="license-name-{{i}}">License Number</label>
									<input  [(ngModel)]="license.licenseNumber" name="name-{{i}}" id="LicNumber" licenseNumber (focus)="fromStartFrom()" class="form-control" aria-labelledby="LicNumber" />
									<div class="note">(Alphabets, numbers and special characters are allowed)</div>
								</div>
							</div>
							<div class="col-md-5">
								<div class="create-form-field">
									<label for="license-region-{{i}}">License Region</label>
									<input  [(ngModel)]="license.licenseRegion" name="region-{{i}}" id="LicReg" aria-labelledby="LicReg" appLicenceRegion (focus)="fromStartFrom()" class="form-control"/>
									<div class="note">(Only alphabets allowed)</div>
								</div>
							</div>
							<div class="col-md-1">
							</div>
							<div class="col-md-1">
							</div>
							<div class="col-md-5">
								<div class="create-form-field">
									<label for="brokerage-name-{{i}}">Brokerage Firm Name</label>
									<input [(ngModel)]="license.brokerageFirmName" name="brokerage-{{i}}" id="Brokerage" class="form-control" appName aria-labelledby="Brokerage" />
									<div class="note">(Only alphabets and space allowed)</div>
								</div>
							</div>
							<div class="col-md-5">
								<div class="create-form-field">
									<label for="firm-name-{{i}}">Address</label>
									<input name="firm-{{i}}" [value]="license.firmAddress" autocomplete="off" id="firmName" class="form-control" aria-labelledby="firmAddress" (focus)="fromStartFrom()" class="form-control" (input)="onAddressInput($event,license)"/>
									<app-address-complete *ngIf="showdropdown"
									[address]="license.firmAddress" 
									(addressChange)="onAddressSelected($event, license)">
									</app-address-complete>
								</div>
							</div>
							<div class="col-md-1">
							</div>

							<div class="col-md-1">
							</div>
							<div class="col-md-5">
								<div class="create-form-field">
									<label for="firm-name-{{i}}">Contact Number</label>
									<input [(ngModel)]="license.firmPhoneNumber" name="firm-{{i}}" id="firmNumber" appFormPhone class="form-control" aria-labelledby="firmNumber" />
								</div>
							</div>


						</div>
					</div>
					<mat-card-actions class="mt-4 button-container agent-buttons">
			<span *ngIf="isEditing()">
				<button type="submit" class="big-ass-button profileBtns" (click)="onConfirmEdit()" tabindex="0">
                    {{ getActionButtonTitle() }}
                </button>
         
                <button type="submit" class="cancel-button profileBtns" (click)="onCancelEdit()" tabindex="0">
                    CANCEL
                </button>
			</span>
			<span *ngIf="!isEditing() && canEdit()">
				<app-big-ass-button class="big-ass-button" [buttonStyle]="buttonStyle" buttonTitle="Edit" [buttonImageStyle]="logoStyle" (buttonAction)="toggleEdit()" tabindex="0"></app-big-ass-button>
			</span>
		</mat-card-actions>
					<!-- <div class="error-message" *ngIf="isLicenseFieldsInvalid || isLicenseInvalid">Both License Number and Region Required</div> -->
				</div>
            </div>
		</div>
		<mat-error class="left-align" *ngIf="error != null">{{error}}</mat-error>
		
	</div>
	<app-spinner [show]="showSpinner"></app-spinner>
	<app-checkmark *ngIf="showCheck"></app-checkmark>
</div>