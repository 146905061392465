<div class="position-relative fixed-height">
    <div class="row inner-style">
      <div class="col-sm-12">
        <div class="frame-wrapper">
          <div id="tour-map" width="100%"  style="position:relative;border:0;height:calc(100vh - 100px);">
            <div class="preview-card" id="sidebar" *ngIf="showCard">
              <ul id="steps-list"></ul>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>