import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Showing, RequestActions } from '../showing'
import { Tour } from '../tour'
import { Property } from '../propertySearch'
import { Client } from '../client'
import { ImageViewerComponent, ImageViewerData } from '../image-viewer/image-viewer.component'
import { bathroomCalculation, addCommasToPrice, firstOf } from '../utils'
import { AgentCardComponent } from '../agent-card/agent-card.component'

export enum ImageSet {
  listingPhotos,
  clientPhotos
}

@Component({
  selector: 'app-tour-summary-details-card',
  templateUrl: './tour-summary-details-card.component.html',
  styleUrls: ['./tour-summary-details-card.component.css']
})
export class TourSummaryDetailsCardComponent implements OnInit {

  @Input()
  property: Property
  
  @Input()
  tour: Tour

  categoryIds:any;


  @Input()
  client: Client

  @Input()
  showing: Showing

  @Input() propertyIndex: number;

  enlargedImage: string | null = null;

  thumbnailImageIndex: number

  imageSet = ImageSet.listingPhotos

  constructor() { }

  ngOnInit(): void {
  }


  /**
   Client comments
  */
  comments () {

    if (this.showing['clientComments']?.length < 1) {
      return ["No comments"]
    }

    return this.showing['clientComments']?.map( each => { return each.comments })
  }

  filterImagesByType(images: any[], type: string): any[] {
    return images.filter(image => image.type === type);
  }

  hasComments() {
    return this.showing['clientComments'] && this.showing['clientComments']?.length > 0;
  }

  hasVideos(): boolean {
    if (this.showing['clientPhotos']) {
      for (const image of this.showing['clientPhotos']) {
        if (image.endsWith('.mp4')) {
          return true;
        }
      }
    }
    return false;
  }

  getPropertyListingPhotosData(event) {
    return {
      images: this.showing.property.propertyPhotos,
      activeImage: 0
    }
  }

  onSelectThumbnail(newIndex) {
    this.imageSet = ImageSet.listingPhotos
    this.thumbnailImageIndex = newIndex
  }

  // onSelectClientThumbnail(newIndex) {
  //   this.imageSet = ImageSet.clientPhotos
  //   this.thumbnailImageIndex = newIndex
  // }
  onSelectClientThumbnail(image: string): void {
    this.enlargedImage = image;
  }

  closeEnlargedImage(): void {
    this.enlargedImage = null;
  }

  getImageSet() {
    switch(this.imageSet) {
      case ImageSet.listingPhotos:
        return this.showing.property.propertyPhotos
      case ImageSet.clientPhotos:

        return this.showing.property.clientPhotos
    }    
  }

  areListingPhotosSelected() {
    return this.imageSet == ImageSet.listingPhotos ? true : null
  }

  areClientPhotosSelected() {
    return this.imageSet == ImageSet.clientPhotos ? true : null
  }
  

  listPrice() {
    return `$${addCommasToPrice(this.showing.property.propertyFeatures['featureData'].ListPrice)}`
  }

  numBedrooms() {
    return this.showing.property?.propertyFeatures['featureData'].BedroomsTotal || 0 
  }

  numBathrooms() {
    return bathroomCalculation(this.showing.property)
  }

  squareFeet() {
    return this.showing.property?.propertyFeatures['featureData'].LivingArea
  }

  lotSize() {
    return this.showing.property?.propertyFeatures['featureData'].LotSizeAcres
  }

  favoriteStatus(showing: any): string {
    if (showing?.clientFavorites[0]?.favoriteStatus == 1) {
      return "./assets/ph_heart-fill.png";
    }
    
    return "./assets/solar_heart-outline.png";
  }

  RatingStatus(showing: any): string {
    const rating = showing?.propertyRatings?.[0]?.starRating;
  
    if (rating && rating >= 1 && rating <= 5) {
      const filledStars = rating;
      const emptyStars = 5 - rating;
      
      const filledStarHtml = '<img src="./assets/ph_star-fill-rating.png" alt="Filled Star" width="25">&nbsp'.repeat(filledStars);
      const emptyStarHtml = '<img src="./assets/ph_star-non-rating.png" alt="Empty Star" width="25">&nbsp'.repeat(emptyStars);
      
      return filledStarHtml + emptyStarHtml;
    }
    
    const emptyStarHtml = '<img src="./assets/ph_star-non-rating.png" alt="Empty Star" width="25">&nbsp;'.repeat(5);
    return emptyStarHtml;
  }

//   startTour(showing: any, event: Event): void {
//     this.categoryIds = this.tour?.clients[0]?.clientcategory.map(category => category.catId);
//     const queryParams = new URLSearchParams({
//       categoryIds: JSON.stringify(this.categoryIds),
//       index: '0',
//       fullAddress: showing?.property?.address?.fullAddress || '',
//       latitude: showing?.property?.address?.latitude?.toString() || '',
//       longitude: showing?.property?.address?.longitude?.toString() || '',
//       listingKey: showing?.property?.listingKey || '',
//       showings: showing?.id || '',
//       propertyId: showing?.property?.id || ''
//     }).toString();

//     // Construct the full URL manually
//     const textUrl = `${window.location.protocol}//${window.location.host}/#/tour-preview/?${queryParams}`;
    
//     // Open the URL in a new tab
//     window.open(textUrl, '_blank');
// }

// startTour(showing: any, event: Event): void {
//   this.categoryIds = this.tour?.clients[0]?.clientcategory.map(category => category.catId);
//   const categoryIds = this.categoryIds ? this.categoryIds.join(',') : ''; // Convert array to comma-separated string
//   const index = 0; // Assuming static index
//   const fullAddress = showing?.property?.address?.fullAddress || '';
//   const latitude = showing?.property?.address?.latitude?.toString() || '';
//   const longitude = showing?.property?.address?.longitude?.toString() || '';
//   const listingKey = showing?.property?.listingKey || '';
//   const showings = showing?.id || '';
//   const propertyId = showing?.property?.id || '';

//   const textUrl = `${window.location.protocol}//${window.location.host}/#/tour-preview/?categoryIds=${categoryIds}&index=${index}&fullAddress=${fullAddress}&latitude=${latitude}&longitude=${longitude}&listingKey=${listingKey}&showings=${showings}&propertyId=${propertyId}`;
//   window.open(textUrl, '_blank');
// }

startTour(showing: any, event: Event): void {

  console.log("this.tour?.clients[0]-------------------------------------",this.tour?.clients[0]);

  const clientData = this.tour?.clients[0];

  const subcatIds = clientData.clientcategory?.flatMap(cat => 
    cat.clientsubcategory?.map(subcat => subcat.subcatId) || []
  );

  console.log("subcats are-------------------------------------",subcatIds)

  this.categoryIds = this.tour?.clients[0]?.clientcategory.map(category => category.catId);

  const categoryIds = subcatIds ? subcatIds.join(',') : ''; // Convert array to comma-separated string
  const index = 0; // Assuming static index
  const fullAddress = showing?.property?.address?.fullAddress || '';
  const latitude = showing?.property?.address?.latitude?.toString() || '';
  const longitude = showing?.property?.address?.longitude?.toString() || '';
  const listingKey = showing?.property?.listingKey || '';
  const showings = showing?.id || '';
  const propertyId = showing?.property?.id || '';

  const textUrl = `${window.location.protocol}//${window.location.host}/#/tour-preview/?categoryIds=${categoryIds}&index=${index}&fullAddress=${fullAddress}&latitude=${latitude}&longitude=${longitude}&listingKey=${listingKey}&showings=${showings}&propertyId=${propertyId}`;
  window.open(textUrl, '_blank');
}
}
