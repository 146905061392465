import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import * as mapboxgl from 'mapbox-gl';
import { Property } from '@app/propertySearch';
import { ChangeDetectorRef } from '@angular/core';
import { ClientService } from '@app/client-mobile-panel/service/client.service';
import { filter, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoginService } from '@app/services/login.service';

@Component({
  selector: 'app-tour-preview',
  templateUrl: './tour-preview.component.html',
  styleUrls: ['./tour-preview.component.css']
})
export class TourPreviewComponent {
  coordinates: any = {
    lat: null,
    lng: null
  };
  error:any;
  listingKey:any;
  clientId:string;
  showingId:string;
  propertyId:string;
  property: Property = null
  showCard:boolean=false;
  showStart:boolean = false;
  showingIndex: any;
  latitude:any;
  longitude:any;
  fullAddress:any;
  categoryIds:any;
  coordinatesForRoute:any;
  propertyCoordinates = [];
  poiCoordinates:{coord:any,address:string,displayName:string,latitude:string,longitude:string}[]=[]
  travelTime: { hours: number; minutes: number } = { hours: 0, minutes: 0 };
  distance: number = 0;
  showDistanceTimeSection: boolean = false;
  map: mapboxgl.Map; // Add this property to store the map instance
  markers: mapboxgl.Marker[] = [];
  marker: mapboxgl.Marker;

  constructor(
      protected router: Router,
      public route: ActivatedRoute,
      private cdr: ChangeDetectorRef,
      private service:ClientService,
      private loginservice:LoginService
      ) {}
    ngOnInit(){
      this.route.queryParams
    .subscribe( (params) => {
      console.log("object",params);
      this.showingIndex = params['index']
      this.latitude = params['latitude']
      this.longitude = params['longitude']
      this.fullAddress = params['fullAddress']
      this.listingKey = params['listingKey']
      this.categoryIds = params['categoryIds']
      this.clientId = params['clientId']?params['clientId']:null;
      this.propertyId = params['propertyId']?params['propertyId']:null;
      this.showingId = params['showings']?params['showings']:null;
    })
    }
    async ngAfterViewInit() {
      await this.setupDevices();
    }
    async setupDevices() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) =>{
          this.coordinates = [position.coords.longitude,position.coords.latitude];
          this.onShowMap();
        },(error: any) => console.log(error));
      }else{
        this.error = "You have no output video device";
      }
    }
  
    getPoiListFunction(): Observable<any> {
      return this.loginservice.getPoiList().pipe(
        map(data => {
          // const filteredPois = data['data']['getPoiList'].filter((poi: any) => {
          //   return this.categoryIds.includes(poi.poicategory[0].category.id);
          // });

          const categoryIdsArray = this.categoryIds.split(',');

          // const filteredPois = data['data']['getPoiList'].filter((poi: any) => {
          //   return categoryIdsArray.includes(poi.poicategory[0].category.id);
          // });
          
          const filteredPois = data['data']['getPoiList'].filter((poi: any) => {
            return poi.poicategory?.some(category =>
                category.poisubcategory?.some(subcat =>
                    categoryIdsArray.includes(subcat.subcategory.id)
                )
            );
        });
    
          return filteredPois;
        }),
        tap((pois) => {
          pois.forEach((poi: any) => {
            const categoryName = poi.poicategory[0].category.name;
            const address = poi.address;
            const displayName = poi.displayName;
            const phoneNumber = poi?.phoneNumber;
            if (poi.latitude !== 'undefined' && poi.longitude !== 'undefined') {
              const poiCoordinates = [parseFloat(poi.longitude), parseFloat(poi.latitude)];
              const distance = this.calculateDistance1([this.longitude, this.latitude], poiCoordinates);
              if (this.isWithin5km([this.longitude, this.latitude], poiCoordinates)) {
                this.propertyCoordinates.push({
                  coordinates: poiCoordinates,
                  type: 'poi',
                  name: displayName,
                  category: categoryName,
                  address: address,
                  distance: distance,
                  phoneNumber: phoneNumber?phoneNumber:''
                });            
              }
            }
          });
        })
      );
    }

    isWithin5km(coord1: number[], coord2: number[]): boolean {
       let  distance = new (mapboxgl as typeof mapboxgl).LngLat(coord1[0], coord1[1]).distanceTo(new mapboxgl.LngLat(coord2[0], coord2[1])) / 1000; // Distance in kilometers
      return distance <= 5;
    }

    onShowMap(){
      (mapboxgl as typeof mapboxgl).accessToken = environment.map.mapbox.apiKey;
      this.map = new mapboxgl.Map({
        container: 'tour-map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: 1, 
        attributionControl: false,
        });
        const Property_coordinates = [this.longitude, this.latitude];
        this.propertyCoordinates.push({ coordinates: Property_coordinates, type: 'property',name: this.fullAddress });
        this.getPoiListFunction().subscribe({
          next: (pois) => {
            this.displayMarkers();
          },
          error: (err) => {
            console.log("error", err);
          }
        });
    }

    // displayMarkers() {
    //   this.propertyCoordinates.forEach(item => {
    //     const div = document.createElement('div');
    //     this.service.renderLinkIcon(div);
    //     const routeBadge: HTMLElement = div;
    //     const coordinates = Array.isArray(item) ? item : item.coordinates;
    //     const markerType = item.type === 'property' ? 'property-marker' : 'poi-marker';
    //     if(markerType == 'property-marker'){
    //       console.log("comes inside first condition");
    //       this.marker = new mapboxgl.Marker(routeBadge)
    //       .setLngLat(coordinates)
    //       .setPopup(new mapboxgl.Popup().setHTML(item.name))
    //       .addTo(this.map);
    //     }
    //     else{
    //       let markerImage = ''; // Default image
    //       const img = document.createElement('img');
    //       img.src = this.getImageForCategory(item.category);
    //       img.width = 30; // Set the width as needed
    //       const distanceInMiles = item.distance * 0.621371;
    //       let popupContent = `
    //         <div>
    //           <p><strong>Name:</strong> ${item.name}</p>
    //           <p><strong>Distance:</strong> ${distanceInMiles.toFixed(2)} miles</p>
    //           <p><strong>Address:</strong> ${item.address}</p>
    //       `;
    //       if (item.phoneNumber && item.phoneNumber.trim() !== "") {
    //           popupContent += `<p><strong>Phone:</strong> ${item.phoneNumber}</p>`;
    //       }
    //       popupContent += `</div>`;
    //       this.marker = new mapboxgl.Marker({ element: img })
    //       .setLngLat(coordinates)
    //       .setPopup(new mapboxgl.Popup().setHTML(popupContent))
    //       .addTo(this.map);
    //     }
    //     this.map.setCenter(coordinates);
    //     this.map.setZoom(10);
    //   });
    // }

    displayMarkers() {
      const bounds = new mapboxgl.LngLatBounds();
    
      this.propertyCoordinates.forEach(item => {
        const div = document.createElement('div');
        this.service.renderLinkIcon(div);
        const routeBadge: HTMLElement = div;
        const coordinates = Array.isArray(item) ? item : item.coordinates;
    
        // Expand bounds to include current marker coordinates
        bounds.extend(coordinates as [number, number]);
    
        const markerType = item.type === 'property' ? 'property-marker' : 'poi-marker';
    
        if (markerType === 'property-marker') {
          this.marker = new mapboxgl.Marker(routeBadge)
            .setLngLat(coordinates)
            .setPopup(new mapboxgl.Popup().setHTML(item.name))
            .addTo(this.map);
        } else {
          const img = document.createElement('img');
          img.src = this.getImageForCategory(item.category);
          img.width = 30;
          const distanceInMiles = item.distance * 0.621371;
          let popupContent = `
            <div>
              <p><strong>Name:</strong> ${item.name}</p>
              <p><strong>Distance:</strong> ${distanceInMiles.toFixed(2)} miles</p>
              <p><strong>Address:</strong> ${item.address}</p>
          `;
          if (item.phoneNumber && item.phoneNumber.trim() !== "") {
            popupContent += `<p><strong>Phone:</strong> ${item.phoneNumber}</p>`;
          }
          popupContent += `</div>`;
    
          this.marker = new mapboxgl.Marker({ element: img })
            .setLngLat(coordinates)
            .setPopup(new mapboxgl.Popup().setHTML(popupContent))
            .addTo(this.map);
        }
      });
    
      // Adjust the map view to fit all markers
      if (!bounds.isEmpty()) {
        this.map.fitBounds(bounds, { padding: 50, maxZoom: 13, duration: 1000 });
      }
    }
  

    isValidLatitude(latitude: number): boolean {
      return latitude >= -90 && latitude <= 90;
    }
    
     isValidLongitude(longitude: number): boolean {
      return longitude >= -90 && longitude <= 90;
    }
  
  
    getImageForCategory(category: string): string {
      switch (category) {
        case 'Food':
          return 'assets/poi_food_marker.svg';
        case 'Hobbies':
          return 'assets/poi_Hobbies_marker.svg';
        case 'Pets':
          return 'assets/poi_pets_marker.svg';
        case 'Children':
          return 'assets/poi_children_marker.svg';
        case 'Fashion':
          return 'assets/poi_fashion_marker.svg';
        case 'Fitness':
          return 'assets/poi_Hobbies_marker.svg';
        default:
          return 'assets/poi_default_marker.svg';
      }
    }
  
    calculateDistance1(coord1: number[], coord2: number[]): number {
      return new (mapboxgl as typeof mapboxgl).LngLat(coord1[0], coord1[1]).distanceTo(new mapboxgl.LngLat(coord2[0], coord2[1])) / 1000; // Distance in kilometers
    }

}
