import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appName]'
})
export class NameDirective {

  private regex: RegExp = /^[a-zA-Z\s]*$/;

  constructor(private elementRef: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const inputValue: string = this.elementRef.nativeElement.value.concat(event.key);
    if (inputValue && !String(inputValue).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    const clipboardData = (event.originalEvent || event).clipboardData.getData('text/plain');
    if (clipboardData) {
      if (!this.regex.test(clipboardData)) {
        event.preventDefault();
      }
    }
  }

  // @HostListener('ngModelChange', ['$event']) onInputChange(event) {
  //   const inputVal = event.replace(/[^a-zA-Z\s]/g, '').substring(0, 50);
  //   this.elementRef.nativeElement.value = inputVal;
  // }
  @HostListener('ngModelChange', ['$event']) onInputChange(event) {
    if (event) {
      const inputVal = this.capitalizeFirstLetter(event.replace(/[^a-zA-Z\s]/g, '')).substring(0, 50);
      this.elementRef.nativeElement.value = inputVal;
    }
  }

  private capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

}
