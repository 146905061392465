<app-spinner [show]="showSpinner"></app-spinner>
<div *ngIf="!showSpinner && tokenValid" class="my-5">
  <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
    <!-- <h1 class="pg-heading" >Reset Password</h1> -->
    <div class="logo-modal-position">
      <img src="./assets/logo-symbol.png" alt="Livepad Logo" tabindex="0">
      <h5 class="modal-title" tabindex="0">Reset Password</h5>
    </div>
    <div>
      <label for="newPassword">New Password <span class="red">*</span></label>
      <div class="position-relative">
        <input type="{{ showNewPassword ? 'text' : 'password' }}" formControlName="newPassword" required name="newPassword"/>
        <span (click)="toggleNewFieldType()" class="position-absolute" style="top: 50%;right: 1px;transform: translate(-50%, -50%);cursor: pointer;" *ngIf="(getPassForm['newPassword']?.value)">
          <img src="{{showNewPassword?'assets/eye-password.svg':'assets/eye-off-password.svg'}}" width="16" alt="view icon"/>
        </span>
      </div>
      <div *ngIf="getPassForm['newPassword'].invalid && (getPassForm['newPassword'].dirty || getPassForm['newPassword'].touched)">
        <div *ngIf="getPassForm['newPassword'].errors.required" style="color: red;">Password is required.</div>
        <div *ngIf="getPassForm['newPassword'].errors.invalidPassword" style="color: red;">Password is not strong enough</div>
        <div *ngIf="getPassForm['newPassword'].errors.invalidPassword" >
          <p [style.color]="!getPassForm['newPassword'].errors.minlength?'green':'red'"><img alt="mark icon" [src]="!getPassForm['newPassword'].errors.minlength?'assets/green-check-mark-tick.svg':'assets/close-icon.svg'" width="16"/> &nbsp;Password must be at least 8 characters long</p>
          <p [style.color]="getPassForm['newPassword'].errors.hasUpper && getPassForm['newPassword'].errors.hasLower?'green':'red'"><img alt="mark icon" [src]="getPassForm['newPassword'].errors.hasUpper && getPassForm['newPassword'].errors.hasLower?'assets/green-check-mark-tick.svg':'assets/close-icon.svg'" width="16"/> &nbsp;Includes a upper and lowercase letter</p>
          <p [style.color]="getPassForm['newPassword'].errors.hasNumber?'green':'red'"><img alt="mark icon" [src]="getPassForm['newPassword'].errors.hasNumber?'assets/green-check-mark-tick.svg':'assets/close-icon.svg'" width="16"/> &nbsp;At least one number</p>
          <p [style.color]="getPassForm['newPassword'].errors.hasSymbol?'green':'red'"><img alt="mark icon" [src]="getPassForm['newPassword'].errors.hasSymbol?'assets/green-check-mark-tick.svg':'assets/close-icon.svg'" width="16"/> &nbsp;Includes a symbol</p>
        </div>
      </div>
    </div>  
    <div>
      <label for="confirmPassword">Confirm Password <span class="red">*</span></label>
      <div class="position-relative">
        <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="confirmPassword" required name="confirmPassword"/>
        <span (click)="toggleFieldTextType()" class="position-absolute" style="top: 50%;right: 1px;transform: translate(-50%, -50%);cursor: pointer;" *ngIf="(getPassForm['confirmPassword']?.value)">
          <img src="{{showPassword?'assets/eye-password.svg':'assets/eye-off-password.svg'}}" width="16" alt="view icon"/>
        </span>
      </div>
      <div *ngIf="getPassForm['confirmPassword'].invalid && (getPassForm['confirmPassword'].dirty || getPassForm['confirmPassword'].touched)">
        <div *ngIf="getPassForm['confirmPassword'].errors.required" style="color: red;">Confirm password is required.</div>
        <div *ngIf="getPassForm['confirmPassword'].errors.PasswordNoMatch" style="color: red;">Passwords do not match!</div>
      </div>    
    </div>
    <button type="submit" [disabled]="passwordForm.invalid">SUBMIT</button>
  </form>
</div>

<div *ngIf="!showSpinner && !tokenValid" class="error-message">
  <p>Sorry, The forgot password link is invalid or expired!</p>
</div>
