<div class="modal-container">	
	<div class="logo-modal-position"><img src="./assets/logo-symbol.png" alt="Livepad Logo" tabindex="0"><h5 class="modal-title inputPopTitleText" tabindex="0">{{message}}</h5></div>
	<div class="form-group" *ngFor="let input of inputs" align="left">
		<label class="label mt-3" for="{{ input.name }}" tabindex="0">{{ input.name }}</label>
		<input type="text" name="input" id="{{ input.name }}" aria-labelledby="{{ input.name }}" [(ngModel)]="input.value" (keyup.enter)="onEnter()" (focus)="startWritingForm($event)" class="inputLine form-control" style="background:transparent;" tabindex="0" maxlength="250" *ngIf="input.name.toLowerCase().includes('tour name'); else noDirective"appCharacter/>
		<ng-template #noDirective>
		<input type="text" name="input" id="{{ input.name }}" aria-labelledby="{{ input.name }}" [(ngModel)]="input.value" (keyup.enter)="onEnter()" (focus)="startWritingForm($event)" class="inputLine form-control" style="background:transparent;" tabindex="0" maxlength="250"/>
		</ng-template>
		<span class="required-text" *ngIf="input.name.toLowerCase().includes('tour name')">Special characters are not allowed</span>
	</div>
</div>
<mat-dialog-actions class="btn-container btn-input">
	<div class="col-md-12">
	<button [disabled]="positiveButtonDisabled()" (click)="onConfirmClick();pageCtaClick($event)" role="button" tabindex="0" class="btn btn-primary confirmOk">{{confirmButtonText}}</button>
	<button mat-dialog-close tabindex="0" class="btn btn-secondary confirmcancel" (click)="pageCtaClick($event)" role="button" tabindex="0">{{cancelButtonText}}</button>
</div>
</mat-dialog-actions>
