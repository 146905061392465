import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.css']
})
export class PhotoGalleryComponent implements OnInit{

  images: string[] = [];
  videos: string[] = [];
  selectedIndex: number = 0;
  isVideo: boolean = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const imagesParam = params['images'];
      const selectedImageParam = params['selectedImage'];
      if (imagesParam && selectedImageParam) {
        const images = imagesParam.split(',');
        const videos = imagesParam.split(',');
        this.selectedIndex = images.indexOf(decodeURIComponent(selectedImageParam));
        //this.images = images;        
        const Un=/^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi
        // this.images = images.filter((image) => image.includes('.jpg' || '.png'));
        this.images = images.filter((image) => image.includes('.jpg') || image.includes('.png') || image.includes('.jpeg') || image.includes('jpeg') || image.includes('Jpeg'));
        this.videos = videos.filter((vdeo) => vdeo.includes('.mp4' || '.mp4' || 'mp4'));
      }
      else{
        const videos = imagesParam.split(',');
        this.videos = videos.filter((vdeo) => vdeo.includes('.mp4' || '.mp4' || 'mp4'));
        this.isVideo = true;
      }
    });
  }
  currentSlide(index:number){
    this.selectedIndex =index
  }

}
