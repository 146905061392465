import { Component, OnInit, EventEmitter, Output, Input, ViewChild, SimpleChanges } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatSortModule } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ToursService } from '../services/tours.service'

import { Client } from '../client'
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {

	@ViewChild(MatSort, { static: true }) sort: MatSort;
	dataSource: MatTableDataSource<Client>;

	/**
	 Table columns for clients
	*/
	displayedColumns: string[] = ['checkbox', 'avatar', 'firstName', 'email', 'createdAt','timeframe','Tour','Delete'];

	selectAllChecked = false

	showSpinner = false;

	filterText: string = ""

	toursCount = 0

	checkedCount = 0

	@Input()
	clients: Client[]

	@Input()
	error: string = null

	@Output()
	onDelete: EventEmitter<{client:Client[],eventFrom:string}> = new EventEmitter<{client:Client[],eventFrom:string}>()

	@Output()
	onAddToTour: EventEmitter<{client:Client[],eventFrom:string}> = new EventEmitter<{client:Client[],eventFrom:string}>()

	@Output()
	onClientItemTrigger: EventEmitter<string> = new EventEmitter<string>()

	@Output()
	onDeleteClient: EventEmitter<{client:Client[],eventFrom:string}> = new EventEmitter<{client:Client[],eventFrom:string}>()

	@Output()
	onsingleAddToTour: EventEmitter<{client:Client[],eventFrom:string}> = new EventEmitter<{client:Client[],eventFrom:string}>()

	private _filteredClients: Client[]


	get filteredClients() {
		return this.clients.filter( x => 
			x.firstName
				.toLowerCase()
				.includes(this.filterText.toLowerCase()) || 
			x.lastName
				.toLowerCase()
				.includes(this.filterText.toLowerCase()) )
	}
	set filteredClients(value) {
		this.clients = value
	}


	constructor(private router: Router,
		private toursService: ToursService) { 
			this.toursService
				.fetchToursCount()
				.subscribe( response => {
		  			if ( response["errors"] != undefined ) {
		  				this.error = response["errors"][0].message
		  				return
		  			}
		  			this.toursCount = Number(response['data'].toursCount)
				})
		}
	originalClients: Client[];

	ngOnInit(): void {
		this.dataSource = new MatTableDataSource(this.clients);
    	this.dataSource.sort = this.sort;
    	this.originalClients = [...this.clients];
	}

	ngOnChanges(changes: SimpleChanges) {
		this.dataSource = new MatTableDataSource(this.clients);
    	this.dataSource.sort = this.sort;
    	this.originalClients = [...this.clients];
	}

	// formatDate(dateString: string): string {
	// 	const date = new Date(dateString);
	// 	const hours = date.getHours().toString().padStart(2, '0');
	// 	const minutes = date.getMinutes().toString().padStart(2, '0');
	// 	const seconds = date.getSeconds().toString().padStart(2, '0');
	// 	const day = date.getDate().toString().padStart(2, '0');
	// 	const month = (date.getMonth() + 1).toString().padStart(2, '0'); // add 1 to month because it is 0-indexed
	// 	const year = date.getFullYear();
	// 	return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} `;
	// }

	formatDate(dateString: string): string {
		const date = new Date(dateString);
		const hours = date.getHours();
		const minutes = date.getMinutes().toString().padStart(2, '0');
		const seconds = date.getSeconds().toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // add 1 to month because it is 0-indexed
		const year = date.getFullYear();
		const amPM = hours >= 12 ? 'PM' : 'AM';
		const twelveHourFormat = hours % 12 || 12; // Convert hours to 12-hour format
		return `${month}-${day}-${year} ${twelveHourFormat}:${minutes} ${amPM}`;
	}
	

	avatarUrlFor(client) {
		let lewisBlack = "./assets/avatar.jpg"
		return client.avatar?.url ?? lewisBlack
	}


	// filterChange(searchText) {
	// 	this.filterText = searchText
	// }

	filterChange(searchText: string) {
		this.filterText = searchText;
		this.dataSource.filter = searchText.trim().toLowerCase();
	}
	

	// onSelectAllClients() {
	// 	this.selectAllChecked = this.selectAllChecked == true ? false : true
	//     this.clients.forEach(each => { 
	//     	each.checked = this.selectAllChecked 
	//     })
	// }

	onSelectAllClients() {
		this.selectAllChecked = !this.selectAllChecked;
		this.checkedCount = this.selectAllChecked ? this.clients.length : 0;
		this.clients.forEach(property => {
			property.checked = this.selectAllChecked;
		});
		// Update the checked state and count for each property
		// this.clients.forEach(property => {
		//   property.checked = this.selectAllChecked;
		//   if (this.selectAllChecked) {
		// 	this.checkedCount += 1;
		//   } else {
		// 	this.checkedCount = 0;
		//   }
		// });
	}

	onClientChecked(client) {
		if (client.checked) {
			this.checkedCount += 1;
		} else {
			this.checkedCount -= 1;
		}
	
		// Update selectAllChecked based on the checkedCount and properties length
		this.selectAllChecked = this.checkedCount === this.clients.length;
	}
	onSelectOneClient(client, event ) {

	    client.checked = client.checked == true ? false : true 

		// event.stopPropagation();
	}

	/**
 	 This is inefficient. Refactor
	*/	
	selectedClients() {

		return this.clients.filter( x => { return x.checked == true })
		
	}

	onDeleteClients(event: Event) {
		event.stopPropagation();

		if ( this.onDelete != null ) {
			this.onDelete.emit({client: this.selectedClients(),eventFrom:'clients' })
		}

		return []
	}

	onAddClientsToTour(event: Event) {
		event.stopPropagation();
		// if ( this.toursCount < 1 ) {
		// 	this.router.navigate([`/new-tour`])
		// 	return
		// }
		this.onAddToTour.emit({client:this.selectedClients(),eventFrom:'clients' })	
	}

	DeleteClients(client: any, event: Event) {
		event.stopPropagation();
		if (this.onDeleteClient != null) {
			this.onDeleteClient.emit({client:[client],eventFrom:'in_list'});
		}
		// if (client.checked && this.onDelete != null) {
		//   this.onDelete.emit({client:[client],eventFrom:'in_list'});
		// } else {
		//   this.error = "Please Select a Proper client to delete"
		// }
		return [];
	  }

	AddClientsToTour(client: any, event: Event) {
		event.stopPropagation();
		this.onsingleAddToTour.emit({client:[client],eventFrom:'in_list'});
		// if ( this.toursCount < 1 ) {
		// 	this.router.navigate([`/new-tour`])
		// 	return
		// }
		// if (client.checked) {
		// 	this.onAddToTour.emit({client:[client],eventFrom:'in_list'});
		//   } else {
		// 	this.error = "Please Select a Proper client to add to tour"
		//   }
	}

	chipColor(timeframeId) {

		let timeframes = {
			"0" : "warn",
			"1" : "accent",
			"2" : "primary"
		}

		return 'primary'; //timeframes[timeframeId]

	}

	onSortChange(event) {
			  
		this.clients.sort((lhs, rhs) => {
		let lhsValue, rhsValue;
		  if (event.active == 'timeframe') {
			lhs['timeframe'] = lhs['clientTimeframe'].name;
			rhs['timeframe'] = rhs['clientTimeframe'].name;
		  }
		  else if(event.active == 'createdAt') {
			lhsValue = new Date(lhs[event.active]).getTime();
      		rhsValue = new Date(rhs[event.active]).getTime();
		  }
		  else {
		 	lhsValue = `${lhs[event.active]}`.toLowerCase();
		  	rhsValue = `${rhs[event.active]}`.toLowerCase();
		  }
	  
		  switch (event.direction) {
			case 'asc':
			  if (lhsValue < rhsValue) return -1;
			  break;
			case 'desc':
			  if (lhsValue > rhsValue) return -1;
			  break;
			case '':
			this.clients = [...this.originalClients];
		  }
		});
	}

	// onSortChange(event) {
	// 	this.clients.sort((lhs, rhs) => {
	// 	  let lhsValue, rhsValue;
	  
	// 	  if (event.active === 'timeframe') {
	// 		lhs['timeframe'] = lhs['clientTimeframe'].name;
	// 		rhs['timeframe'] = rhs['clientTimeframe'].name;
	// 	  } else if (event.active === 'effectiveDate') {
	// 		lhsValue = new Date(lhs[event.active]).getTime();
	// 		rhsValue = new Date(rhs[event.active]).getTime();
	// 	  } else {
	// 		lhsValue = `${lhs[event.active]}`.toLowerCase();
	// 		rhsValue = `${rhs[event.active]}`.toLowerCase();
	// 	  }
	  
	// 	  if (event.direction === 'asc') {
	// 		return lhsValue - rhsValue;
	// 	  } else if (event.direction === 'desc') {
	// 		return rhsValue - lhsValue;
	// 	  } else {
	// 		return 0;
	// 	  }
	// 	});
	//   }
	  


	/**
 	 Go to client details 
	*/

	onRowClick(client) {
        this.showSpinner = true;
		// Navigate to the profile page
		this.onClientItemTrigger.emit(client?.id);
        this.router.navigate([`/client/${client.id}`]).then(() => {
          // Hide the spinner once the navigation is complete
         this.showSpinner = false;});
	}

}
