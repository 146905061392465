<script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.1/moment.min.js"></script>
<div class="contentBox">
	<div class="filter">
		<div style="display: flex;" class="contentBoxInnerDev">
			
		<app-search-bar (onFilterChange)="filterChange($event)"></app-search-bar>
		
		<button title="Delete Client" class="trash-button my-button filter-position-trash" mat-mini-fab (click)="onDeleteClients($event)"
    		[disabled]="selectedClients().length == 0" role="overall_delete_btn">
    		<img src="./assets/delete-icon.png" alt="Delete icon" matSuffix [ngClass]="{'disabled': selectedClients().length == 0}" />
		</button>
		<button title="Add To Tour" class="add-to-tour-button my-button filter-position-add" mat-mini-fab color="primary" (click)="onAddClientsToTour($event)"
			[disabled]="selectedClients().length == 0" role="overall_add_btn"> 
			<mat-icon matSuffix tabindex="0">group_add</mat-icon>
		</button>
	</div>
	</div>
		<div class="table-responsive">
		<table mat-table matSort [dataSource]="dataSource" class="client-table-list"
			(matSortChange)="onSortChange($event)" aria-label="tour list">
			<ng-container matColumnDef="checkbox">
				<th mat-header-cell *matHeaderCellDef>
					<mat-checkbox class="client-title tour-checkbox" [checked]="selectAllChecked"
						(change)="onSelectAllClients()"><b class="adafix">.</b></mat-checkbox>
				</th>
				<td mat-cell *matCellDef="let client" class="clientCheckbox" >
					<mat-checkbox class="client-title tour-checkbox" [(ngModel)]="client.checked"
						(click)="$event.stopPropagation();" (change)="onClientChecked(client)"><b class="adafix">.</b></mat-checkbox>
				</td>
			</ng-container>
			<ng-container matColumnDef="avatar">
				<th mat-header-cell *matHeaderCellDef><b class="adafix">.</b></th>
				<td mat-cell *matCellDef="let client" class="clientThumbnail">
  					<button class="thumbnail-button" (click)="onRowClick(client)" style="background: none; border: none; padding: 0; margin: 0; text-decoration: underline; cursor: pointer;" tabindex="0">
    					<img class="thumbnail" src="{{ avatarUrlFor(client) }}" alt="user avatar" onerror="this.src='./assets/avatar.jpg'"/>
  					</button>
				</td>
			</ng-container>
			<ng-container matColumnDef="firstName">
				<th mat-header-cell *matHeaderCellDef mat-sort-header [matSortDisableClear]="true"  [disableClear]="true" tabindex="0">First Name</th>
				<td mat-cell *matCellDef="let client" tabindex="0" class="clientFirstName"> {{client.firstName}} </td>
			</ng-container>
			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef tabindex="0">Email ID</th>
				<td mat-cell *matCellDef="let client" tabindex="0" class="clientEmailId"> {{client.email}} </td>
			</ng-container>
			<ng-container matColumnDef="createdAt">
				<th mat-header-cell *matHeaderCellDef mat-sort-header [matSortDisableClear]="true"  [disableClear]="true">Effective Date</th>
				<td mat-cell *matCellDef="let client" tabindex="0" class="clientDate"> {{ formatDate(client.createdAt) }} </td>
			</ng-container>
			<ng-container matColumnDef="timeframe">
				<th mat-header-cell *matHeaderCellDef tabindex="0">Timeframe</th>
				<td mat-cell *matCellDef="let client" tabindex="0" class="clientTimeFrame">
					<div class="timeframe timeframe-{{client.clientTimeframe.id}}">{{client.clientTimeframe.name}}</div>
				</td>
			</ng-container>
			<!-- <ng-container matColumnDef="Tour">
				<th mat-header-cell *matHeaderCellDef style="font-size: 17px; font-weight: bold;">Tour</th>
				<td mat-cell *matCellDef="let client">
					<button title="Add To Tour" mat-icon-button color="primary" (click)="onAddClientsToTour($event)">
						<mat-icon>group_add</mat-icon>
					</button>
				</td>
			</ng-container>
			<ng-container matColumnDef="Delete">
				<th mat-header-cell *matHeaderCellDef style="font-size: 17px; font-weight: bold;">Delete</th>
				<td mat-cell *matCellDef="let client">
					<button mat-icon-button color="warn" (click)="onDeleteClients($event)">
						<img src="assets/trash.svg" alt="delete" width="16px">
					</button>
				</td>
			</ng-container> -->
			<ng-container matColumnDef="Tour">
				<th mat-header-cell *matHeaderCellDef tabindex="0">Tour</th>
				<td mat-cell *matCellDef="let client" class="clientTourIcon">
					<button title="Add To Tour" mat-icon-button color="primary" class="add-to-tour-mobile" (click)="AddClientsToTour(client, $event)" tabindex="0">
						<!-- <mat-icon>group_add</mat-icon> -->
						<img src="assets/add-icon.png" alt="Add Icon">
					</button>
				</td>
			</ng-container>
			<ng-container matColumnDef="Delete">
				<th mat-header-cell *matHeaderCellDef tabindex="0">Delete</th>
				<td mat-cell *matCellDef="let client" class="clientDelete">
					<button mat-icon-button color="warn" class="delete-mobile" (click)="DeleteClients(client, $event)">
						<!-- <img src="assets/trash.svg" alt="delete" width="16px"> -->
						<a class="delete-btn">Delete</a>
					</button>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"><b class="adafix">.</b></tr>
			<tr mat-row *matRowDef="let client; columns: displayedColumns;" class="client-table-row"
				(click)="onRowClick(client)"><b class="adafix">.</b>
			</tr>
		</table>
	</div>
	
	
</div>
<span class="left-align" *ngIf="error">{{error}}</span>
<app-spinner [show]="showSpinner"></app-spinner>