import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Authorization } from '@app/authorization';
import { LoginService } from '@app/services/login.service';
import { ServiceErrors } from '@app/services/service-errors';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, scan } from 'rxjs/operators';
import { LocalService } from './local.service';
import { formatDate } from '@app/utils';
// import { UploadService } from './upload.service';
interface propertyFeedback{

starRating:number;
favoriteStatus:string;
comments:string[]|string;
propertyId:number;
showingId:number;
tourId?:number
}
@Injectable({
  providedIn: 'root'
})
export class ClientService {
  linkIsNotValid:boolean=false;
	private graphApi:string = environment.graphApi;
	private authorization: Authorization
  constructor(private http: HttpClient,private loginService: LoginService,private localStorage:LocalService,
    // private uploadService:UploadService
    ) {
    // this.savePropertyFeedback.onSelectedProperty();
    const savedFiles: any = this.localStorage.getData('showingsImage');
    this.fileListSubject.next(savedFiles || []);
    }
  private selectedProperty = new BehaviorSubject<{showDrag:boolean,propertyItem:[],showings:[]}>({showDrag:false,propertyItem:[],showings:[]});

  private showingIndexSource = new BehaviorSubject<number>(null);
  showingIndex$ = this.showingIndexSource.asObservable();

  private fileListSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  // fileList$: Observable<any[]> = this.fileListSubject.asObservable().pipe(
  //   scan((acc, value) => [...acc, ...value], [])
  // );
  fileList$: Observable<any[]> = this.fileListSubject.asObservable();

  private agentIdSubject = new BehaviorSubject<string>('');
  private clientIdSubject = new BehaviorSubject<string>('');

  agentId$ = this.agentIdSubject.asObservable();
  clientId$ = this.clientIdSubject.asObservable();

  private listingKeySource = new BehaviorSubject<string>('');
  private showingsSource = new BehaviorSubject<string>('');

  currentListingKey = this.listingKeySource.asObservable();
  currentShowings = this.showingsSource.asObservable();

  private backButtonClickedSource = new BehaviorSubject<boolean>(false);
  backButtonClicked$ = this.backButtonClickedSource.asObservable();

  setBackButtonClicked(value: boolean) {
    this.backButtonClickedSource.next(value);
  }

  getBackButtonClickedValue(): Observable<boolean> {
    return this.backButtonClicked$;
  }
  setShowingIndex(index: number) {
    this.showingIndexSource.next(index);
  }
  // setCurrentListingKey(id: string) {
  //   this.listingKeySource.next(id);
  // }
  // setCurrentShowings(id: string) {
  //   this.showingsSource.next(id);
  // }
  onSelectedProperty(message: {showDrag:boolean,propertyItem:[],showings:[],prevShowings:[]}) {
	  this.selectedProperty.next(message);
  }

  onClearProperty() {
	  this.selectedProperty.next({showDrag:false,propertyItem:[],showings:[]});
  }

  getSelectedProperty(): Observable<any> {
	  return this.selectedProperty.asObservable();
  }
  skipRecord(index: number): void {
    const currentRecords = this.selectedProperty.getValue();
    currentRecords.propertyItem.splice(index, 1);
    this.selectedProperty.next(currentRecords);
  }
  /****
   * started tour property selection tracking
   * @propertyOnMap
   * @setPropertyOnMap
   * @onClearPropertyOnMap
   * @getPropertyOnMap
   */
  private propertyOnMap = new BehaviorSubject<any[]>([]);
 
  setPropertyOnMap(message: []) {
	 this.localStorage.saveData('currentProperty',message);
	  this.propertyOnMap.next(message);
  }

  onClearPropertyOnMap() {
	  this.propertyOnMap.next([]);
  }

  getPropertyOnMap(): Observable<any> {
	  return this.propertyOnMap.asObservable();
  }
  /***
   * @propertyList tour property list record
   * @setPropertyList set the tour property list record
   * @onClearPropertyList clear tour property list record
   * @getPropertyList   subscribe to get the tour property list record
   */
  
  private propertyList = new BehaviorSubject<any[]>([]);
 
  setPropertyList(message: any[]) {
	this.localStorage.saveData('PropertyList',message);
	  this.propertyList.next(message);
  }

  onClearPropertyList() {
	  this.propertyList.next([]);
  }

  getPropertyList(): Observable<any> {
	  return this.propertyList.asObservable();
  }
  /**
   * for next and complete property buttons handling
   * @showNextPropertyBtn initialize 
   * @onNextPropertyBtn
   * @onDefaultPropertyBtn
   * @getButtonProperty subscribe to get the update the status of button.
   */
  private showNextPropertyBtn = new BehaviorSubject<{showBtn:boolean,type:string}>({showBtn:false,type:'next'});
 
  onNextPropertyBtn(message: {showBtn:boolean,type:string}) {
    this.localStorage.saveData('nextPropertyBtn',message);
	  this.showNextPropertyBtn.next(message);
  }

  onDefaultPropertyBtn() {
	  this.showNextPropertyBtn.next({showBtn:false,type:'next'});
  }
  getButtonProperty():Observable<any> {
	return this.showNextPropertyBtn.asObservable();
  }
  /**
 * for next and complete property buttons handling
 * @savePropertyFeedback initialize 
 * @onNextPropertyFeedback
 * @onDefaultPropertyBtn
 * @getPropertyFeedback subscribe to get the update the status of button.
 */

  private savePropertyFeedback = new BehaviorSubject<propertyFeedback[]>([]);

  onNextPropertyFeedback(message: any[]) {
    this.localStorage.saveData('propertyFeedback',message);
    console.log("onNextPropertyFeedback",message);
    this.savePropertyFeedback.next(message);
  }

  onDefaultPropertyFeedback() {
    this.savePropertyFeedback.next([]);
  }
 
  getPropertyFeedback():Observable<any[]> {
    // const propSaved = JSON.parse(this.localStorage.getData('propertyFeedback'))
    // if(propSaved && propSaved?.length>0){
      // this.onNextPropertyFeedback(propSaved);
    // }
  return this.savePropertyFeedback.asObservable();
  }
  /***
   * @tourClient tour property list record
   * @setTourClient set the tour property list record
   * @onClearTourClient clear tour property list record
   * @getTourClient   subscribe to get the tour property list record
   */
  
  private tourClient = new BehaviorSubject<any[]>([]);
 
  setTourClient(message: any[]) {
	this.localStorage.saveData('tourClient',message);
	  this.propertyList.next(message);
  }

  onClearTourClient() {
	  this.propertyList.next([]);
  }

  getTourClient(): Observable<any> {
	  return this.propertyList.asObservable();
  }
  /***
  //  * @fileListSubject tour property list record
   * @setFileListSubject set the tour property list record
   * @clearFileListSubject clear tour property list record
   * @getFileListSubject   subscribe to get the tour property list record
   */
  
  // private fileListSubject = new BehaviorSubject<File[]>([]);
  // fileList$ = this.fileListSubject.asObservable();
 
  setFileListSubject(message: any[]) {
	this.localStorage.saveData('showingsImage',message);
	  this.fileListSubject.next(message);
  }
  // addFilesToUpload(fileList: File[]) {
  //   const currentList = this.fileListSubject.getValue();
  //   const updatedList = [...currentList, ...fileList];
  //   console.log("updatedList",updatedList);
  //   this.fileListSubject.next(updatedList);
  //   // if(updatedList.length>0){
  //   //   this.uploadService.onPhotoCaptured(updatedList)
  //   // }
  // }

  clearFileList() {
    this.fileListSubject.next([]);
  }

  // getFileListSubject(): Observable<any> {
	//   return this.fileListSubject.asObservable();
  // }

  updateFileList(files: any[]): void {
    this.localStorage.saveData('showingsImage',files);
    this.fileListSubject.next(files);
    const savedFiles = this.localStorage.getData('showingsImage');
    console.log('savedFiles local storage', savedFiles);
  }
  // updateFileList(files: any[]): void {
  //   console.log("files New", files);
  //   const currentFiles = this.fileListSubject.getValue(); // Get the current files
  //   const updatedFiles = [...currentFiles, ...files]; // Concatenate current files with new files
  //   this.localStorage.saveData('showingsImage', updatedFiles);
  //   this.fileListSubject.next(updatedFiles);
  
  //   const savedFiles = this.localStorage.getData('showingsImage');
  //   console.log('savedFiles local storage', savedFiles);
  // }
  
  checkAuth () {
	this.authorization = this.loginService.getAuthorization()
	if ( !this.authorization?.token ) { console.error("No auth token present") }
  }
//   toggleSelection(item: string) {
//     if (this.selectedItems.includes(item)) {
//       // If item is already selected, remove it from the selectedItems array
//       this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem !== item);
//     } else {
//       // If item is not selected, add it to the selectedItems array
//       this.selectedItems.push(item);
//     }
//   }

  renderLinkIcon(node) {
		const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		const iconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		const iconPath1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		const iconPath2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		const iconPath3 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		// iconSvg.textContent=`${count+1}`;
		iconSvg.setAttribute('display','block');
		iconSvg.setAttribute('height','41px');
		iconSvg.setAttribute('width','27px');
		iconSvg.setAttribute('fill', '#3FB1CE');
		iconSvg.setAttribute('viewBox', '0 0 27 41');
		iconPath.setAttribute('d','M13.5 40.05C19.299 40.05 24 37.6995 24 34.8C24 31.9005 19.299 29.55 13.5 29.55C7.70101 29.55 3 31.9005 3 34.8C3 37.6995 7.70101 40.05 13.5 40.05Z');
		iconPath.setAttribute('fill','black')
		iconPath.setAttribute('fill-opacity','0.2')
		iconPath1.setAttribute('d','M27 13.5C27 19.07 20.25 27 14.75 34.5C14.02 35.5 12.98 35.5 12.25 34.5C6.75 27 0 19.22 0 13.5C0 6.04 6.04 0 13.5 0C20.96 0 27 6.04 27 13.5Z')
		iconPath1.setAttribute('fill','#0F60A1');
		iconPath2.setAttribute('d','M13.5 19C16.5376 19 19 16.5376 19 13.5C19 10.4624 16.5376 8 13.5 8C10.4624 8 8 10.4624 8 13.5C8 16.5376 10.4624 19 13.5 19Z')
		iconPath2.setAttribute('fill','white');
		iconPath3.setAttribute('d','M13.5349 11L13.5 11.0274L13.4651 11V11.0548L11 13.0344V16H12.7023L12.7814 14.5583C12.9837 14.1624 13.3837 14.0727 13.5 14.0553C13.6163 14.0727 14.0163 14.1624 14.2186 14.5583L14.2977 16H16V13.0344L13.5349 11.0548V11Z')
		iconPath3.setAttribute('fill','#0F60A1');
		
		iconSvg.appendChild(iconPath);
		iconSvg.appendChild(iconPath1);
		iconSvg.appendChild(iconPath2);
		iconSvg.appendChild(iconPath3);
	  
		return node.appendChild(iconSvg);
  }
  getRouteData(coordinatesString:string){
    // this.checkAuth()

		// let query = `mutation { removeMls( mls: ${mls.id} agentId: ${agent.id} ) { ${ this.agentReturnFields() } } }`
	  // 	let body = { "query" : query } 
	  	// let options = { headers : { "Authorization" : `Bearer ${this.authorization?.token}`} }

      const mapApiUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinatesString}`;
      const params = {
        access_token: environment.map.mapbox.apiKey,
        geometries: 'geojson', // Get the route as GeoJSON
        overview: 'full',
      };
      
	  	return this.http.get(mapApiUrl,  { params } )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
  }
/*
* Add a comment (i.e. note) to a property by a client.
*/
addPropertyComment( comments:string, propertyId:number, showingId:number ) {

	// this.checkAuth()

	let query = `mutation {	clientComment(comments:"""${comments}""" propertyId:${propertyId} showingId:${showingId}) {	  id  comments	}  }`
	  let body = { "query" : query } 
	  // let options = { headers : { "Authorization" : `Bearer ${this.authorization?.token}`} }

	  return this.http.post(this.graphApi, body )
		  .pipe(
			  catchError(ServiceErrors.handleError)
		  )
}
/***
 * get Tour Data
 */
getWebRespTour(tourId:number){
  let agentAvatar = "avatar { url }"
		let license = "license { licenseNumber }"
		let agentFields = `agent { id firstName lastName fullName email phone ${agentAvatar} ${license} }`
		let showingFields = `id showingTime showingOrder`

		let client = 'clients { id firstName lastName clientcategory{catId clientsubcategory{subcatId}}}'
    
		let clientRatingFields = ` propertyRatings { starRating } `
		let clientPhotos = ` clientPhotos { url type } `
		let clientFavorites = `clientFavorites { favoriteStatus }`
		let clientComments = `clientComments { id comments createdAt }`
		let clientFields = `${clientFavorites} ${clientComments} ${clientRatingFields} ${clientPhotos}`

    // let propertyRatings =	"propertyRatings { starRating }"
		let propertyFields = `property { id listingKey UnparsedAddress originatingMls propertyPhotos { url displayOrder type } address { fullAddress latitude longitude } propertyFeatures }`

	

  	let fields = ["id", "tourNumber", "tourDate", "name", "description", "createdAt", "tourCompleted", ` ${client} showings { ${clientFields} ${showingFields} ${propertyFields} ${agentFields} }`  ]
  

  let query = `query{   webRespTours(tourId:${tourId}){  ${fields.join(' ')}  } }`
  let body = { "query" : query } 
  // let options = { headers : { "Authorization" : `Bearer ${this.authorization?.token}`} }

  return this.http.post(this.graphApi, body )
    .pipe(
      catchError(ServiceErrors.handleError)
    )
}
  
/*
* property by a client.
*/
validateCustomUrl( gUrl:string ) {

  let query = `query{ validateCustUrl(url:"${gUrl}"){    message  }  }`;
  let body = { "query" : query } 

	  return this.http.post(this.graphApi, body )
		  .pipe(
			  catchError(ServiceErrors.handleError)
		  )
}


/**
 *  Change the client's 5-star rating of a property
 */
addPropertyRating( starRating: number , propertyId:number ,showingId:number) {

	// this.checkAuth()

	let query = `mutation  { propertyRatings( starRating: ${starRating},propertyId:${propertyId},showingId:${showingId}) {  id  starRating }  }`
	  let body = { "query" : query } 
	  // let options = { headers : { "Authorization" : `Bearer ${this.authorization?.token}`} }

	  return this.http.post(this.graphApi, body )
		  .pipe(
			  catchError(ServiceErrors.handleError)
		  )
}

/*
* Toggle favorite status for a client & property.
*/
addFavoriteStatus(favoriteStatus:number, propertyId:number,showingId:number) {

	// this.checkAuth()

	let query = `mutation {	clientFavorite( favoriteStatus:${favoriteStatus},propertyId:${propertyId},showingId:${showingId}) {  id  favoriteStatus	}  }`
	  let body = { "query" : query } 
	  // let options = { headers : { "Authorization" : `Bearer ${this.authorization?.token}`} }

	  return this.http.post(this.graphApi, body )
		  .pipe(
			  catchError(ServiceErrors.handleError)
		  )
}

/**
 	 Remove showing by id
	*/
	deleteShowing( showingId:number, tourId:number ) {

		// this.checkAuth()

		let query = `mutation { deleteShowing ( showing: ${showingId} tour: ${tourId} )}`

	  	let body = { "query" : query } 
	  	// let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}


 /** POI  get list*/
 getPoiList() {
    // this.checkAuth();

    let query = `query{ getPoiList{  id businessName displayName description phoneNumber address latitude longitude status  poicategory{id category{id name} poisubcategory{id subcategory{id name} } } } }`;
    let body = { query: query };
    // let options = {
      // headers: { Authorization: `Bearer ${this.authorization?.token}` },
    // };
    return this.http
      .post(this.graphApi, body)
      .pipe(catchError(ServiceErrors.handleError));
  }
//   # Fetch a property and everything we have on it.
  getProperty(listingKey:string,clientId:string) {
    // this.checkAuth();

    let query = `query  {
		property( listingKey: "${listingKey}", clientId: "${clientId}"  ) {
			id listingId listingKey UnparsedAddress dataSource originatingMls propertyFeatures
			address { fullAddress streetNumber streetName unitNumber zip crossStreet city county state country latitude
				longitude }
			propertyPhotos { url id type displayOrder mediaKey longDescription isTour category }
	
		}
	}`;
    let body = { query: query };
    // let options = {
    //   headers: { Authorization: `Bearer ${this.authorization?.token}` },
    // };
    return this.http
      .post(this.graphApi, body)
      .pipe(catchError(ServiceErrors.handleError));
  }

  /***
 * complete tour
 */
completeClientTour( tourId:number ) {
  let tourDate = formatDate(new Date());

  // this.checkAuth()
  const deleteLink=`${window.location.protocol}//${window.location.host}/#/client-complete/welcome?tourId=${tourId}`;
  // let query = `mutation{ updateTourCompleted(tourId:${tourId} linkUrl:"${deleteLink}"){   message  } }`
  let query = `mutation{ updateTourCompleted(tourId:${tourId} linkUrl:"${deleteLink}" tourEndDate:"${tourDate}"){   message  } }`
    let body = { "query" : query } 
    // let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
    return this.http.post(this.graphApi, body )
      .pipe(
        catchError(ServiceErrors.handleError)
      )

}
getRouteDirections(coordinates){
  const accessToken = environment.map.mapbox.apiKey;
  const coordinatesString = coordinates.map(coord => `${coord[0]},${coord[1]}`).join(';');
  const apiUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinatesString}`;
  const params = {
    access_token: accessToken,
    geometries: 'geojson', // Get the route as GeoJSON
    overview: 'full',
    steps:true,
  };
  return this.http.get(apiUrl, { params }).pipe(
    catchError(ServiceErrors.handleError)
  )
}
updateShowingOrder(showings) {

  // this.checkAuth()

  let query = `mutation { updateTourShowingOrder ( id:[${showings}] ) {message}  }`

    let body = { "query" : query } 

  console.log("query is",query)
    // let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
    return this.http.post(this.graphApi, body )
      .pipe(
        catchError(ServiceErrors.handleError)
      )
}
	/**
 	 Just fetch a single property 
	*/
	fetchPropertyByListingKey( listingKey: String, clientId: String ) {

		// this.checkAuth()

	  	var addressFields = ["fullAddress",
			"streetNumber",
			"streetName",
			"unitNumber",
			"zip",
			"crossStreet",
			"city",
			"county",
			"state",
			"country"]
		let addressJoins = `{ ${addressFields.join(" ")} }`
		let photoFields = ["url", "id", "type", "displayOrder", "mediaKey", 'longDescription', 'isTour', 'category']
		let photoJoins = `{ ${photoFields.join(" ")} }`

		// Pass along a client id to include that client's fav data/comments/photos of this property
		let clientJoins = ''
		let clientInfo = ''

		if ( clientId != null ) {

			let client = 'clients { id firstName lastName }'
			clientInfo = `clientId: "${clientId}" `

			let clientRatingFields = ` propertyRatings { starRating } `
			let clientPhotos = ` clientPhotos { url type } `
			let clientFavorites = `clientFavorites { favoriteStatus }`
			let clientComments = `clientComments { id comments createdAt ${client} }`
			clientJoins = `${clientFavorites} ${clientComments} ${clientRatingFields} ${clientPhotos}`
		}


		let query = `query { property( listingKey: "${listingKey}" ${clientInfo} ) { id listingId listingKey UnparsedAddress dataSource originatingMls propertyFeatures address ${addressJoins} propertyPhotos ${photoJoins} ${clientJoins} } }`
	  	let body = { "query" : query } 
	  	// let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }
	  	return this.http.post(this.graphApi, body )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

  setAgentId(agentId: any) {
    this.agentIdSubject.next(agentId);
    localStorage.setItem('agentId', agentId);
  }

  setClientId(clientId: any) {
    this.clientIdSubject.next(clientId);
    localStorage.setItem('clientId', clientId);
  }
	/**
 	 Send an avatar image to the server
	*/
	uploadPhotos(data, clientId, agentId) {


		const fileExtension = (s) => {

			const lastDot = s.lastIndexOf('.');

			const filename = s.substring(0, lastDot);
			const ext = s.substring(lastDot + 1);

			return {
				filename : filename,
				extension: ext.includes('.')?ext:'.'+ext
			}
		}

		if ( data == null ) {
			return
		}
		
		// this.checkAuth()

		// let path = clientId == null ? environment.restApi.agentAvatarUpload : environment.restApi.clientAvatarUpload
    let path =  environment.restApi.propertyPhotoUpload
    // let idKey = clientId == null ? "x-metadata-agent-id" : "x-metadata-client-id"
		// let idValue = clientId == null ? agentId : clientId

	  	let options = { 
	  		headers : { 
  				// "Authorization" : `Bearer ${this.authorization.token}`,
          "x-metadata-showing-id": data.showingsId,
          "x-metadata-agent-id": data.agentId,
          "x-metadata-client-id": data.clientId,
  				"x-metadata-file-extension" : fileExtension(data.key).extension,
          "x-metadata-property-listing-key": data.listingKey,
          "x-metadata-file-filename": data.key
	  		}
	  	}
      // @Body body: String?

	  	// options.headers[idKey] = idValue

		// const formData: FormData = new FormData();

		// formData.append('image', data);
	  	
	  	// return this.http.post(path, '', options )
	  	// 	.pipe(
	  	// 		catchError(ServiceErrors.handleError)
	  	// 	)

    return this.http.post(path, '', options)
    .pipe(
      map((res) => {
        console.log("Success:", res);
        return { success: true, data: res };
      }),
      catchError((error) => {
        console.error("Error:", error);
        return throwError({ success: false, error: error });
      })
    );
	}

  updateListingKey(listingKey: string) {
    this.listingKeySource.next(listingKey);
    localStorage.setItem('listingKey', listingKey);
  }
  //
  storeDataLocal(key,dataArray){
      // Get the existing array from localStorage
      const existingArrayJSON = localStorage.getItem(key);

      let existingArray = [];
      if (existingArrayJSON) {
        existingArray = JSON.parse(existingArrayJSON);
      }

      // Assume you have a new array or data to combine with the existing one
      const newData = dataArray;

      // Combine the existing array with the new data
      // let combinedArray =[];
      existingArray?.filter((item)=>{
        if(item.showingsId==newData[0].showingsId){
          return existingArray
        }else{
          return existingArray.concat(newData)
        }
      })

      // const combinedArray  =data?data:;
      const combinedArray =existingArray;

      // Store the combined array back in localStorage
      localStorage.setItem(key, JSON.stringify(combinedArray));
  }


  updateShowings(showings: string) {
    this.showingsSource.next(showings);
    localStorage.setItem('showings', showings);
  }

  getListingKey(): string {
    const currentValue = this.listingKeySource.getValue();
    return currentValue || localStorage.getItem('listingKey') || '';
  }

  getShowings(): string {
    const currentValue = this.showingsSource.getValue();
    return currentValue || localStorage.getItem('showings')  || '';
  }

  clearClientId(): void {
    this.clientIdSubject.next('');
  }

  clearAgentId(): void {
    this.agentIdSubject.next('');
  }

  checkDocApprovedByClients(clients) {

		let query = `query { checkDocApprovedByClients ( id:[${clients}] ) {message}  }`

	  	let body = { "query" : query } 

		  console.log("query is",query)
	  	return this.http.post(this.graphApi, body )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

  updateTourStartJourneyDate(tourId, date:any ) {
    date = formatDate(date);
    let query = `mutation{
      updateTourStartJourneyDate(id:"${tourId}", tourStartDate:"${date}"){
        message
      }
    }`
  
    console.log("query is-----------",query);
    let body = { "query" : query } 
  
      return this.http.post(this.graphApi, body )
      .pipe(
        catchError(ServiceErrors.handleError)
      )
  }
}
