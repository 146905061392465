import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
// import { catchError, retry } from 'rxjs/operators';
import { Router, Route, ActivatedRoute, ParamMap, NavigationEnd, NavigationExtras } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PropertySearch, PropertyFilter, Property } from '../propertySearch';
// import { GoogleMapsModule } from '@angular/google-maps';
// import { MatListModule } from '@angular/material/list';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material/input';
// import { MatCardModule } from '@angular/material/card';
// import { MatIconModule } from '@angular/material/icon';
// import { MatButtonModule } from '@angular/material/button';
// import { MatTabsModule } from '@angular/material/tabs';
// import { MatSelectModule } from '@angular/material/select';
// import { MatExpansionModule } from '@angular/material/expansion';
// import { MatTableModule } from '@angular/material/table';
// import { MatCheckboxModule } from '@angular/material/checkbox';
// import { MatGridListModule } from '@angular/material/grid-list';
import {
  // MatDialogRef,
  MatDialog,
  // MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { StorageMap } from '@ngx-pwa/local-storage';
import { LocalStorage } from '@ngx-pwa/local-storage';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { TrestleKeys } from '../trestleKeys';
import { BasicObject } from '../basicObject';
import { FieldSelection, SelectedField } from '../fieldSelection';
import { ConfirmListComponent } from '../confirm-list/confirm-list.component';
import { ConfirmInputComponent } from '../confirm-input/confirm-input.component';

import { PropertyService } from '../services/property.service';
import { LoginService } from '../services/login.service';
import { MapsService } from '../services/maps.service';
import { ToursService } from '../services/tours.service';
import { MlsService } from '../services/mls.service';
import { AgentService } from '../services/agent.service';

// import { Tour } from '../tour';
// import { SpinnerComponent } from '../spinner/spinner.component';
// import { CheckmarkComponent } from '../checkmark/checkmark.component';
import { BaseComponent } from '../base/base.component';
import { environment } from '../../environments/environment';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
// import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
 import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { User } from '@app/user';
import { capitalizeFirstLetter } from '@app/utils';
@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css'],
})
export class PropertiesComponent extends BaseComponent {
  error = null;
  user: User;

  /**
 	 List of selectable fields for use when filtering by criteria
	*/
  selections: FieldSelection[] = [];

  /**
 	 Properties returned by a particular search
	*/
  properties: Property[] = [];

  /**
 	 Was search pressed once?
	*/
  didSearch: boolean = false;

  /**
 	 Value for when the spinner should be shown
	*/
  showSpinner = false;

  /**
 	 Show the done checkmark
	*/
  showDone = false;

  /**
 	 The list of mls dropdowns for one mls
	*/
  mlsDropdowns: any[] = [];

  private tourFlowId = null

  //"Square Feet", "Bedrooms", "Bathrooms", "Price", "Address"
  searchFields = [
    {
      name: 'Square Feet',
      value: null,
      placeholder: '2000+, 1000-3000',
      searchKey: 'LivingArea',
      isDropdown: false,
      selection: null,
    },
    {
      name: 'Bedrooms',
      value: null,
      placeholder: '3, 2-3',
      searchKey: 'BedroomsTotal',
      isDropdown: false,
      selection: null,
    },
    {
      name: 'Bathrooms',
      value: null,
      placeholder: '3+, 2-3',
      searchKey: 'BathroomsTotalInteger',
      isDropdown: false,
      selection: null,
    },
    {
      name: 'Price',
      value: null,
      placeholder: 'Price',
      searchKey: 'ListPrice',
      isDropdown: false,
      selection: null,
    },
    {
      name: 'Address',
      value: null,
      placeholder: 'Address',
      searchKey: 'UnparsedAddress',
      isDropdown: false,
      selection: null,
    },
    {
      name: 'Year Built',
      value: null,
      placeholder: 'Year built',
      searchKey: 'YearBuilt',
      isDropdown: false,
      selection: null,
    },
    {
      name: 'MLS#',
      value: null,
      placeholder: 'MLS#',
      searchKey: 'ListingId',
      isDropdown: false,
      selection: null,
    },
    {
      name: 'MLS Selection',
      value: [],
      placeholder: 'MLS Selection',
      searchKey: 'MlsSelection',
      isDropdown: true,
      selection: {
        filteredFields: [], // Initialize with an empty array
        filteredValues: [], // Initialize with an empty array
        selectedField: {
          display: null,
          searchKey: 'MlsSelection',
          value: null,
        },
      },
    },
    {
      name: 'MLS Status',
      placeholder: 'MLS Status',
      searchKey: 'mlsStatus',
      isDropdown: true,
      selection: {
        filteredFields: [], // Initialize with an empty array or data if available
        filteredValues: [], // Initialize with an empty array or data if available
        selectedField: {
          display: null,
          searchKey: 'mlsStatus',
          value: ['Active'],
          active:true
        },
      },
    },
    {
      name: 'View',
      placeholder: 'View',
      searchKey: 'View',
      isDropdown: true,
      selection: {
        filteredFields: [], // Initialize with an empty array or data if available
        filteredValues: [], // Initialize with an empty array or data if available
        selectedField: {
          display: null,
          searchKey: 'View',
          value: null,
        },
      },
    },
  ];

  private searchFieldsCacheKey = 'searchFieldsCacheKey';
  private criteriaFieldsCacheKey = 'criteriaFieldsCacheKey';
  private searchResultsCacheKey = 'searchResultsCacheKey';
  checkedCount: number;
  selectAllChecked: boolean;
  // @ViewChildren(MatSelect) selectMlsSelectionElement!: QueryList<MatSelect>;
  @ViewChild('selectMlsSelectionElement', { static: false }) selectMlsSelectionElement!:MatSelect;
  @ViewChild('selectMlsStatusElement', { static: false }) selectMlsStatusElement!: MatSelect;
  @ViewChild('selectViewElement', { static: false }) selectViewElement!: MatSelect;
  @ViewChild('searchTextMlsStatus', { static: false }) searchTextMlsStatus!:ElementRef<HTMLInputElement>
  constructor(
    protected loginService: LoginService,
    protected router: Router,
    protected route: ActivatedRoute,
    // private agentService: AgentService,
    private mlsService: MlsService,
    private propertyService: PropertyService,
    // private mapsService: MapsService,
    private toursService: ToursService,
    private dialog: MatDialog,
    private storage: StorageMap,
    private modalService: NgbModal,
    // private gaService:GoogleAnalyticsService,
    private localStorage:LocalStorage,
    private cdr: ChangeDetectorRef
  ) {
    super(loginService, router, route);
    this.user = this.loginService.user;
    this.loginService.userChange.subscribe((user) => {
      this.user = user;
    });
  }

  capitalizeName(name:string){
    if(!name)return;  
    return capitalizeFirstLetter(name)
  }

  ngOnInit(): void {
    const storedProperty = localStorage.getItem('selectedProperty');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.urlAfterRedirects;
  
        // Check if the current URL doesn't contain /properties or /property/propertyid
        // if (currentUrl.indexOf('/properties') === -1 && !currentUrl.match(/^\/property\/\w+$/)) {
        //   // If the URL doesn't match the expected patterns, remove storedProperty from localStorage
        //   localStorage.removeItem('selectedProperty');
        // }
        if (currentUrl.indexOf('/properties') === -1 && !currentUrl.match(/^\/property\/\w+([;?].*)?$/)) {
          // If the URL doesn't match the expected patterns, remove storedProperty from localStorage
          localStorage.removeItem('selectedProperty');
        }
      }
    });
    if(storedProperty){
      this.storage.get('checkedProperties').subscribe((checkedProperties: Property[]) => {
        this.storage.get(this.searchResultsCacheKey)
        .subscribe((properties: Property[]) => {
          this.properties = properties ?? this.properties;
        });
        this.storage.get(this.searchFieldsCacheKey)
        .subscribe((searchFields: any) => {
        this.searchFields = searchFields ?? this.searchFields;
        const mlsStatusField = searchFields?.find(field => field?.name === "MLS Status");
        if (mlsStatusField) {}
        else{
          this.statusSelectionDropdown(this.loginService.user.usermls);
          this.fetchMlsDropdowns(this.loginService.user.usermls);
          this.createMlsSelectionDropdown(this.loginService.user.usermls);
        }
      });
    });
    }
    else {
      this.fetchMlsDropdowns(this.loginService.user.usermls);
      this.createMlsSelectionDropdown(this.loginService.user.usermls);
      this.statusSelectionDropdown(this.loginService.user.usermls);
    }
    
    this.storage.get(this.criteriaFieldsCacheKey).subscribe((selections: any) => {
      this.selections = selections ?? this.selections;
    });
  }

  // ngOnInit(): void {
  //   this.storage.get('checkedProperties').subscribe((checkedProperties: Property[]) => {
  //     if (checkedProperties) {
  //       this.properties.forEach(property => {
  //         property.checked = checkedProperties.some(checkedProperty => checkedProperty.id === property.id);
  //       });
  
  //       this.checkedCount = checkedProperties.length;
  //       this.selectAllChecked = this.checkedCount === this.properties.length;
  
  //       if (this.checkedCount > 0) {
  //         this.storage.get(this.searchResultsCacheKey).subscribe((properties: Property[]) => {
  //           this.properties = properties ?? this.properties;
  //         });
  //       }
  //     }
  //   });
  
  //   this.storage.get(this.criteriaFieldsCacheKey).subscribe((selections: any) => {
  //     this.selections = selections ?? this.selections;
  //   });
  
  //   this.fetchMlsDropdowns(this.loginService.user.agent.mls);
  // }

  // private fetchMlsDropdowns(mlsList) {
  //   // TODO: There should be no temp default, and there should be a dropdown to select which MLS to fetch from
  //   let mlsName = 'Incline Village Realtors';

  //   // if (mlsList.length > 0) {
  //   //   mlsName = mlsList[0].contractedMls.mlsName;
  //   // } // Temp default

  //   this.mlsService.fetchMlsDropdowns(mlsName).subscribe((dropdowns) => {
  //     if (this.handleError(dropdowns['errors'])) {
  //       return;
  //     }

  //     this.mlsDropdowns = dropdowns['data']['mlsDropdowns'];

  //     // Dropdowns to add to the top list
  //     this.mlsDropdowns
  //       .filter((each) => {
  //         return each['showInHeaderSearch'] == true;
  //       })
  //       .forEach((dropdownSet) => {
  //         let emptySelection: FieldSelection = {
  //           filteredFields: dropdownSet,
  //           filteredValues: dropdownSet['values'],
  //           selectedField: {
  //             display: dropdownSet.display,
  //             searchKey: dropdownSet.searchKey,
  //             value: null,
  //           },
  //         };

  //         let headerField = {
  //           name: dropdownSet.display,
  //           value: null,
  //           placeholder: 'Select from list...',
  //           searchKey: dropdownSet.searchKey,
  //           isDropdown: true,
  //           selection: emptySelection,
  //         };

  //         this.searchFields.push(headerField);
  //       });

  //     // Dropdowns to add to the selective criteria list
  //     this.mlsDropdowns
  //       .filter((each) => {
  //         return each['showInHeaderSearch'] == false;
  //       })
  //       .forEach((dropdownSet) => {
  //         TrestleKeys.push(dropdownSet);
  //       });
  //   });
  // }
  private fetchMlsDropdowns(mlsList) {
    let mlsName = 'Incline Village Realtors';
    this.mlsService.fetchMlsDropdowns(mlsName).subscribe((dropdowns) => {
      if (this.handleError(dropdowns['errors'])) {
        return;
      }
  
      this.mlsDropdowns = dropdowns['data']['mlsDropdowns'];
  
      // Update the selection field in searchFields
      const statusSelectionFieldIndex = this.searchFields.findIndex(field => field.searchKey === 'View');
  
      if (statusSelectionFieldIndex !== -1) {
        const dropdownSet = this.mlsDropdowns.find(each => each['showInHeaderSearch'] == true);
        if (dropdownSet) {
          const emptySelection: FieldSelection = {
            filteredFields: dropdownSet,
            filteredValues: dropdownSet['values'],
            selectedField: {
              display: dropdownSet.display,
              searchKey: dropdownSet.searchKey,
              value: null,
            },
          };
  
          this.searchFields[statusSelectionFieldIndex].selection = {
            ...this.searchFields[statusSelectionFieldIndex].selection,
            ...emptySelection,
          };
        }
        this.mlsDropdowns
        .filter((each) => {
          return each['showInHeaderSearch'] == false;
        })
        .forEach((dropdownSet) => {
          TrestleKeys.push(dropdownSet);
        });
      }
    });
  }

  // private statusSelectionDropdown(mlsList) {
  //   this.propertyService.getPropertStatusList().subscribe((dropdowns) => {
  //     console.log("dropdowns are",dropdowns);
  //     const statusList = dropdowns['data']['getPropertStatusList'];
  //     const filteredStatusList = statusList.filter(status => {
  //       const lowerCaseStatus = status.mlsStatus.toLowerCase();
  //       return lowerCaseStatus !== 'comingsoon' && lowerCaseStatus !== 'activeundercontract';
  //     });
  //     console.log("status list is",statusList)
  //     const statusSelectionDropdown = {
  //       display: 'mlsStatus',
  //       searchKey: 'mlsStatus',
  //       values: filteredStatusList.map(status => {
  //         return {
  //           value: status.mlsStatus,
  //           display: status.mlsStatus,
  //         };
  //       }),
  //     };
  
  //   const statusSelectionField = {
  //     name: 'MLS Status',
  //     value: [],
  //     placeholder: 'Select MLS Status...',
  //     searchKey: 'mlsStatus',
  //     isDropdown: true,
  //     selection: {
  //       filteredFields: [statusSelectionDropdown],
  //       filteredValues: statusSelectionDropdown.values,
  //       selectedField: {
  //         display: null,
  //         searchKey: 'mlsStatus',
  //         value: null,
  //       },
  //     },
  //   };
  
  //   this.searchFields.push(statusSelectionField);
  // });
  // }

  private statusSelectionDropdown(mlsList) {
    this.propertyService.getPropertStatusList().subscribe((dropdowns) => {
      if (this.handleError(dropdowns['errors'])) {
        return;
      }
      const statusList = dropdowns['data']['getPropertStatusList'];
      statusList.sort((a, b) => {
        // Compare the mlsStatus property of each object
        if (a.mlsStatus < b.mlsStatus) {
            return -1; // a should come before b in the sorted order
        } else if (a.mlsStatus > b.mlsStatus) {
            return 1; // a should come after b in the sorted order
        } else {
            return 0; // a and b are considered equal
        }
      });
      statusList?.forEach(statusObj => {
        statusObj.checked = false//statusObj.mlsStatus=='Active'?true: false;
      });
      const default_value = 'Active'
      const filteredStatusList = statusList.filter(status => {
        const lowerCaseStatus = status.mlsStatus.toLowerCase();
        return  lowerCaseStatus!== 'comingsoon' && lowerCaseStatus !== 'activeundercontract';//lowerCaseStatus !== 'active'&&
      });
  
      // Update the selection field with actual values
      this.searchFields.find(field => field.searchKey === 'mlsStatus').selection = {
        filteredFields: [{
          display: 'mlsStatus',
          searchKey: 'mlsStatus',
          values: filteredStatusList.map(status => ({
            value: status.mlsStatus,
            display: status.mlsStatus,
            checked:status.checked,
          })),
        }],
        filteredValues: filteredStatusList.map(status => ({
          value: status.mlsStatus,
          display: status.mlsStatus,
          checked:status.checked,
        })),
        selectedField: {
          display: null,
          searchKey: 'mlsStatus',
          value: [default_value],
          active:true
        },
      };
    });
  }

  // private createMlsSelectionDropdown(mlsList) {
  //   const mlsSelectionDropdown = {
  //     display: 'MLS Selection',
  //     searchKey: 'MlsSelection',
  //     values: mlsList.map(mls => {
  //       console.log("mls is------",mls);
  //       const trimmedMlsName = mls.contractedMls.mlsName.trim().toLowerCase();
  //       const formattedMlsName = trimmedMlsName === 'recolorado'
  //       ? 'RE Colorado'  // If yes, use the static value
  //       : mls.contractedMls.mlsName;  // If no, use the original value
  //       return {
  //         value: mls.contractedMls.id,
  //         display: formattedMlsName,
  //       };
  //     }),
  //   };
  
  //   const mlsSelectionField = {
  //     name: 'MLS Selection',
  //     value: [],
  //     placeholder: 'Select MLS...',
  //     searchKey: 'MlsSelection',
  //     isDropdown: true,
  //     selection: {
  //       filteredFields: [mlsSelectionDropdown],
  //       filteredValues: mlsSelectionDropdown.values,
  //       selectedField: {
  //         display: null,
  //         searchKey: 'MlsSelection',
  //         value: null,
  //       },
  //     },
  //   };
  
  //   this.searchFields.push(mlsSelectionField);
  // }

  private createMlsSelectionDropdown(mlsList) {
    const mlsSelectionFieldIndex = this.searchFields.findIndex(field => field.searchKey === 'MlsSelection');
    if (mlsSelectionFieldIndex !== -1) {
      const mlsSelectionDropdown = {
        display: 'MLS Selection',
        searchKey: 'MlsSelection',
        values: mlsList.map(mls => {
          const trimmedMlsName = mls.contractedMls.mlsName.trim().toLowerCase();
          const formattedMlsName = trimmedMlsName === 'recolorado'
            ? 'RE Colorado'
            : mls.contractedMls.mlsName;
          return {
            value: mls.contractedMls.id,
            display: formattedMlsName,
          };
        }),
      };
  
      // Update the MLS selection field in searchFields
      this.searchFields[mlsSelectionFieldIndex].selection.filteredFields = [mlsSelectionDropdown];
      this.searchFields[mlsSelectionFieldIndex].selection.filteredValues = mlsSelectionDropdown.values;
    } else {
      console.error('MLS Selection field not found in searchFields.');
    }
  }

  /**
 	 Turn something like 200k into 200000 or 1.5m into 1500000
	*/
  private expandPrice(priceStr) {
    // Normalize text
    priceStr = priceStr.toLowerCase();

    // Different regex for decimals to make life easier
    let regex = priceStr.includes('.')
      ? /[0-9]+\.?[0-9]+(k|m)/gi
      : /[0-9]+(k|m)/gi;

    return priceStr.replace(regex, (match, p1, offset, string) => {
      if (p1 == 'm') {
        let value = match.slice(0, match.length - 1);
        return `${Number(value) * 1000 * 1000}`;
      } else if (p1 == 'k') {
        let value = match.slice(0, match.length - 1);
        return `${Number(value) * 1000}`;
      }
    });
  }

  /**
 	 Search based on filtered criteria
	*/
  onSearch() {

    // this.properties = [];
    // this.didSearch = false;

    let simpleSelections = this.selections.map(
      (x) =>
        new BasicObject(
          x.selectedField.searchKey,
          x.selectedField.value.searchValue
        )
    );

    this.searchFields.forEach((x) => {
      if (x.value != null && x.value.length > 0) {
        let modifiedValue:any = x.value;
        if (x.name == 'Price') {
          modifiedValue = this.expandPrice(modifiedValue);
        }
        simpleSelections.push(new BasicObject(x.searchKey, modifiedValue));
      } else if (x.isDropdown && x.selection && x.selection.selectedField.value) {
        console.log("x.selection.selectedField.value",x.selection.selectedField.value)
        const status_value = x.selection.selectedField.value.map(element=>{
          if (typeof element === 'string') {
                  return element;
              } else if (typeof element === 'object' && element.hasOwnProperty('value')) {
                  return element.value;
              }
        })
        simpleSelections.push(new BasicObject(
          x.selection.selectedField.searchKey,
          status_value
        ));
      }
    });
    // this.gaService.sendCtaClickEvent({page_name:"properties"},{cta_type:'button',cta_location:'search_filter_form',cta_description:'search'})

    console.log("simple selection is",simpleSelections);

    // Cache search field values
    this.storage
      .set(this.searchFieldsCacheKey, this.searchFields)
      .subscribe((result) => {
      });

    // Cache critera search values
    this.storage
      .set(this.criteriaFieldsCacheKey, this.selections)
      .subscribe((result) => {
      });

      let link_name = this.loginService.user.agent.link;
    
      let data = this.loginService.user;
      console.log("user is",data);
      let id = data.usermls.map(item => item['contractedMls'].id);
      // this.agentService
      // .fetchAgent( link_name )
      // .subscribe(response => { 
          // if ( response["errors"] != undefined ) {
          //   this.error = response["errors"][0].message
          //   return
          // }
          // const mlsId = response["data"]["agent"]["mls"][0]["contractedMls"]["id"];
          // const agentId = response["data"]["agent"]["id"];

          // const contractedMlsIds = response['data'].agent.mls.map(mls => mls.contractedMls.id);
          this.showSpinner = true;

          let targetName = "MlsSelection";
          let targetValue = null;
          let contractedMlsIds = [];

          for (let selection of simpleSelections) {
            if (selection.name === targetName) {
              targetValue = selection.value;
              contractedMlsIds = [targetValue];
              break;
            }
          }

          if (contractedMlsIds && contractedMlsIds.length > 0) {
            contractedMlsIds = contractedMlsIds
            // contractedMlsIds = contractedMlsIds;
          } else {
              contractedMlsIds = id;
          }
          const transformPropertyInfo=(propertyArray)=> {
            const propertyInfo = {};
            propertyArray.forEach(item => {
                switch (item.name) {
                    case 'LivingArea':
                        propertyInfo['property_sqft'] = item.value;
                        break;
                    case 'BedroomsTotal':
                        propertyInfo['property_bedrooms'] = item.value;
                        break;
                    case 'BathroomsTotalInteger':
                        propertyInfo['property_bathrooms'] = item.value;
                        break;
                    case 'ListPrice':
                        propertyInfo['property_price'] = item.value;
                        break;
                    case 'YearBuilt':
                        propertyInfo['property_year'] = item.value;
                        break;
                    case 'View':
                        propertyInfo['property_view'] = item.value;
                        break;
                    case 'mlsStatus':
                        propertyInfo['property_mls_status'] = item.value.join(',');
                        break;
                    case 'MlsSelection':{
                      const userMlsData =[];
                       this.loginService?.user?.usermls?.filter(item1 =>{
                        if(item1['contractedMls'].id===item.value) userMlsData.push(item1['contractedMls']?.mlsName)});
                        propertyInfo['property_mls'] = userMlsData.join(',') ?? undefined //.filter(item1=>{if(item1['contractedMls'].id==item.value)return item1['contractedMls'].mlsName;});
                        break;
                      }
                    default:
                        break;
                }
            });
        
            return propertyInfo;
        }
          // this.gaService.sendFormSubmitEvent({formType:'search',disType:'page'},{
          //   ...transformPropertyInfo(simpleSelections),
          //   user_id:this.loginService?.user?.id
          // });
          simpleSelections.map(element=>{
            if(element.name=='mlsStatus'){
              if(element.value.length>0){
                if(element.value.includes('Active Under Contract')){
                 element.value = [...element.value,'ActiveUnderContract']
              }
            }
          }
          })
          this.propertyService
            .runPropertySearch(simpleSelections, [], contractedMlsIds)
            .subscribe((properties) => {
              this.didSearch = true;
              // let property = properties['data']['properties'];

              this.showSpinner = false;

              if (this.handleError(properties['errors'])) {
                return;
              }

              // Local only has closed properties
              if (environment?.production == true) {
                this.properties = properties['data']['properties'].filter((each) => {
                  return each.mlsStatus != 'Closed';
                });
              } else {
                this.properties = properties['data']['properties'].filter(data => {if(data.UnparsedAddress !=''){
                  data['checked'] = false;
                  return data;
                }});
                const excludedListingKeys = environment.excludedListingKeys;
                this.properties = this.properties.filter(
                  (property) => !excludedListingKeys.includes(property.listingKey)
                );
                let checkedProperties = this.properties.filter(property => property.checked);
                this.localStorage.setItem('checkedProperties', checkedProperties).subscribe(() => {
                });
              }
              if(this.didSearch && !(this.properties.length>0)){
                // this.gaService.sendErrorDisplayEvent({page_name:"properties",message:'No results found',type:"form_validation"})
              }
              localStorage.setItem('pageIndex',"0");

              // Cache for use when backing up, returning to the page, etc.
              this.storage
                .set(this.searchResultsCacheKey, this.properties)
                .subscribe((result) => {
                });
            });
          // });
  }

  /**
 	 Route handling of errors here to eliminate duplicate code
	*/
  handleError(error): boolean {
    if (error == null || error == undefined) {
      return false;
    }

    this.error = error[0].message;
    // this.gaService.sendErrorDisplayEvent({page_name:"properties",message:this.error,type:"no_results_found"})
    if (this.error.toLowerCase() == 'not authenticated') {
      this.router.navigate(['/login']);
    }

    return true;
  }

  /**
 	 Clear filters and cache
	*/
  onClearFilters() {
    this.selections = [];

    for (let i = 0; i < this.searchFields.length; i++) {
      this.searchFields[i].value = null;

      // if (this.searchFields[i].isDropdown == true) {
      //   this.searchFields[i].selection.selectedField.value = null;
      // }
      if (this.searchFields[i].isDropdown == true) {
        if(this.searchFields[i].name == "MLS Status"){
          this.searchFields[i].selection.selectedField.value = ["Active"]
          this.statusSelectionDropdown(this.loginService.user.usermls);
        }else{
          this.searchFields[i].selection.selectedField.value = null;
        }
      }
    }

    this.storage.delete(this.searchFieldsCacheKey).subscribe(() => {});
    this.storage.delete(this.criteriaFieldsCacheKey).subscribe(() => {});
    // this.gaService.sendCtaClickEvent({page_name:"properties"},{cta_type:'button',cta_location:'search_filter_form',cta_description:'clear_filters'})//cta 24
  }

  /**
 	 Update a value for a selected criteria field
	*/
  onUpdateValue(event, selection) {
    // Value from default fields such as SqFt, Price, etc.
    if (typeof event == 'string') {
      selection.value = event;
      return;
    }

    // Value from predefined set of field values
    selection.selectedField.value = event.value;
  }
  typingStarted:boolean = false;
  fromStartFrom(){
    if (!this.typingStarted) {
    // this.gaService.sendFormStartEvent({formType:'search',disType:'page'});
    this.typingStarted=true;
    }
  }

  onKeyDown(event, selection, index) {
    if (event.key === "Tab") {
      // event.preventDefault(); // Prevent default Tab behavior
  
      const nextIndex = index;
      if (nextIndex < this.searchFields.length) {
        const nextFieldId = "search-field-" + nextIndex;
        const nextFieldElement = document.getElementById(nextFieldId);
        if (nextFieldElement) {
          nextFieldElement.focus(); // Focus the next input field
        }
      }
    } else {
      if (selection.searchKey === 'LivingArea' ||
          selection.searchKey === 'BedroomsTotal' ||
          selection.searchKey === 'BathroomsTotalInteger' ||
          selection.searchKey === 'YearBuilt') {
        // Allow numeric keys, minus sign, plus sign, backspace, and arrow keys
        if (!/^\d$|^[+-]$|^Backspace$|^ArrowLeft$|^Delete$|^ArrowRight$/.test(event.key)) {
          // Prevent other keys from being typed
          event.preventDefault();
        }
      }
      if (selection.searchKey === 'ListPrice') {
        // Allow digits, plus sign, minus sign, dot, backspace, arrow keys, and suffixes
        if (!/^\d$|^[+-]$|^\.$|^Backspace$|^ArrowLeft$|^Delete$|^ArrowRight$|^[kKmMbB]$/.test(event.key)) {
          // Prevent other keys from being typed
          event.preventDefault();
        }
      }
      // if (selection.searchKey === 'ListingId') {
      //   // Allow only digits, backspace, and arrow keys
      //   if (!/^\d$|^Backspace$|^ArrowLeft$|^Delete$|^ArrowRight$/.test(event.key)) {
      //     // Prevent other keys from being typed
      //     event.preventDefault();
      //   }
      // }
    }
  }

  /**
 	 Update a value for a selected criteria field
	*/
  onSpaceKeyDown(event: KeyboardEvent){
    if (event.code === 'Enter') {
      if(event.target['id']='mlsStatus'){
        event.preventDefault();
        // event.target['checked'] = true;
      }
    }
    if (event.code === 'Space') {
      event.stopPropagation();
    }
    // console.log("object",event.code);
  }
  onUpdateSearchFieldValue(event, selection, field) {
    // Value from default fields such as SqFt, Price, etc.
    if(field.searchKey=='mlsStatus') {
      // console.log("object---------",event.code,event,selection,field);
      // const previousField =field.selection.filteredValues.filter(option => option.checked ).map(option => option.value);;
      // field.selection.selectedField.value=[...previousField,...event]    
      field.selection.filteredValues.forEach(option => {
        option.checked = field.selection.selectedField.value.includes(option.value);
      });               
      if (this.searchTextMlsStatus) {
        this.searchTextMlsStatus.nativeElement.focus();
      }                 
      return 
    }else{
    if (typeof event == 'string') {
      selection.value = event;
      return;
    }

    // Value from predefined set of field values
    selection.selectedField.value = event?.value;
    field.selection.selectedField.value = event?.value;
    field.value = event?.value?.searchValue?event.value?.searchValue:undefined;
    if (typeof field.value === 'undefined') {
        field.value = event?.value?.value;
    } else {
        field.value = event?.value?.searchValue;
    }
   }
  }
  /**
 	 Adds a new criteria field to further filter
	*/
  onAddCriteria() {
    let obj: FieldSelection = {
      filteredFields: TrestleKeys,
      filteredValues: null,
      selectedField: {
        display: null,
        value: null,
        searchKey: null,
      },
    };
    this.selections.push(obj);
  }

  /**
 	 Remove a criteria from this search
	*/
  onRemoveCriteria(selection, i, event) {
    this.selections.splice(Number(i), 1);
  }

  /**
 	 Select a field in the criteria drop down menu
	*/
  onSelection(event, selection) {
    // selection.name = event.value.display
    // selection.searchKey = event.value.searchKey

    // Set list of values
    selection.filteredValues = event.value.values;

    // Update selected field
    selection.selectedField = event.value;
  }

  // navigateToTours() {
  //   const data = { tour: this.tourFlowId }; // @tourFlowId have selected tour id
  //   const navigationExtras: NavigationExtras = {
  //     state: {
  //       data: data
  //     }
  //   };
  //   this.router.navigate(['/tours'], navigationExtras);
  // }

  navigateToTours(tourId?: any) {
    console.log("navigate tours new tourId-------------",tourId);
    let data;
    if(this.tourFlowId){
       data = { tour: this.tourFlowId };
    }
    else if(tourId){
      data = { tour: tourId };
      this.toursService.fetchTours2().subscribe((response) => {
        let tours = response['data'].tours || [];
        tours = tours.sort((a, b) => {
          const dateA = new Date(a.tourDate);
          const dateB = new Date(b.tourDate);
          return dateB.getTime() - dateA.getTime(); // Descending order
          // return dateA.getTime() - dateB.getTime();
        });
          
        if (tourId) {
          const tourIndex = tours.findIndex((t: any) => t.id === tourId);
          if (tourIndex !== -1) {
            // Calculate page number based on the index
            const pageSize = 15; // Number of items per page
            const pageNumber = Math.ceil((tourIndex + 1) / pageSize); // Page index is 1-based
            const recordsPerPage = 15;
            const sortText = 'all';
      
            console.log(`Page Number for Tour ID ${tourId}:`, pageNumber);
      
            // Store values in local storage
            localStorage.setItem('pageNumber', pageNumber.toString());
            localStorage.setItem('recordsPerPage', recordsPerPage.toString());
            localStorage.setItem('sortKey2', sortText);
          } else {
            console.log(`Tour with ID ${tourId} not found.`);
          }
        }
      });
    }
    const navigationExtras: NavigationExtras = {
      state: {
        data: data
      }
    };
    this.router.navigate(['/tours'], navigationExtras);
  }

  showCheckmark(next) {
    this.showDone = true;
    setTimeout(() => {
      this.showDone = false;

      if (next != null) {
        // Pass "self" context
        next(this);
      }
    }, 3000);
  }

  /**
 	 Allow the user to choose his tour 
	*/
  confirmTour(tourAdditions) {
    let map = this.route.snapshot.queryParams

		if ( map["tour"] != null ) {
			this.tourFlowId = map["tour"]
		}

    if ( this.tourFlowId != null ) {
			this.confirmFromTourFlow(tourAdditions)
			return
		}

    else {

    this.showSpinner = true;

    // Get the agent's tours
    this.toursService.fetchTours().subscribe((tours) => {
      // Assume for alpha stage that an error means logged out
      if (tours['errors'] != null) {
        this.showSpinner = false;
        this.error = tours['errors'][0].message;
        return;
      }

      if (tours['data']['tours'].length === 0) {
        this.showCreateTourPopup(tourAdditions);
        return;
      }

      const filteredTours = tours['data']['tours'].filter((tour) => tour.tourCompleted === 0);

      // Have him choose which
      const dialogRef = this.dialog.open(ConfirmListComponent, {
        data: {
          message: 'Please select a tour',
          buttonText: {
            ok: 'SELECT',
            cancel: 'CANCEL',
            extra: 'CREATE NEW',
          },
          // list: tours['data']['tours'].map((x) => x.name),
          list: filteredTours.map((x) => x.name),
          extraAction: () => {
            // An extra close call is probably not needed...
            dialogRef.close();
            this.showCreateTourPopup(tourAdditions);
           },
        },
        panelClass: 'custom-addTour',
      });

      // Tour select action
      dialogRef.afterClosed().subscribe((result) => {
        this.error = null;
        //cta 59
        if(result  && result?.cancel){
          // this.gaService.sendCtaClickEvent({page_name:`properties`},{cta_type: 'button', cta_location:'select_tour_popup', cta_description:'cancel'});
        }
        if (result?.confirmed) {
          let tourId = tours['data']['tours'].filter((x) => {
            return x.name == result.selection;
          })[0]?.id;
          // this.gaService.sendCtaClickEvent({page_name:`properties`},{cta_type: 'button', cta_location:'select_tour_popup', cta_description:'select', selection_option:tourId});//cta 57
          let propertyIdList = tourAdditions.map((x) => x.id);
      
          this.toursService.fetchTourSummary(tourId).subscribe((response) => {
            const tourSummary = response['data']; // The tour summary data is now directly available in response.data
            // Process the tour summary here
            const showings = tourSummary['tours'][0]['showings'];
            const existingPropertyIds = showings.map((showing) => {
              return showing['property']['id'];
            });
            const existingPropertyIdsStrings = existingPropertyIds.map(String); // Convert numbers to strings
      
            const newPropertyIdList = [];
            const existingPropertyIdList = [];
            const existingPropertyAddressList = [];
            const newPropertyAddressList = [];
      
            propertyIdList.forEach((propertyId) => {
              const showing = showings.find((showing) => showing['property']['id'] === propertyId);
              if (existingPropertyIdsStrings.includes(propertyId) && showing ) {
                const address = showing['property']['propertyFeatures']['featureData']['UnparsedAddress'];
                existingPropertyIdList.push(propertyId);
                existingPropertyAddressList.push(address);
              } else {
                newPropertyIdList.push(propertyId);
              }
            });

            if (newPropertyIdList.length === 0) {
              let errorMessage
              if(propertyIdList.length == 1){
                errorMessage = ['Selected property already exist.'];
              }
              else{
                errorMessage = ['Selected properties already exist.'];
              }
              this.openErrorModal(errorMessage, false);
              this.showSpinner = false;
              return;
            }

            if (newPropertyIdList.length !== 0 && existingPropertyIdList.length !== 0) {
              const errorMessage = existingPropertyAddressList;
              this.openErrorModal(errorMessage, true);
              this.showSpinner = false;
              return;
            }
      
            this.toursService.addPropertiesToTour(newPropertyIdList, tourId).subscribe((showingTour) => {
              this.showSpinner = false;
              // Assume for alpha stage that an error means logged out
              if (showingTour['errors'] != null) {
                this.error = showingTour['errors'][0].message;
                return;
              }
              this.navigateToTours(tourId);
            });
          });
        } else {
          this.showSpinner = false;
        }
      });
    });
  }
}

showCreateTourPopup(tourAdditions) {
  // this.gaService.sendCtaClickEvent({page_name:`properties`},{cta_type: 'button', cta_location:'select_tour_popup', cta_description:'create_new'});//cta 58
  this.showSpinner = false;
  const inputDialogRef = this.dialog.open(ConfirmInputComponent, {
    data: {
      message: 'Please name your new tour',
      currentRoute:'properties',
      buttonText: {
        ok: 'CREATE',
        cancel: 'CANCEL',
      },
      inputs: [
        { name: 'Tour Name', value: null },
        { name: 'Tour Description', value: null },
      ],
    },
  });

  let runAfterClosed = (result) => {
    if (result?.confirmed) {
      this.showSpinner = true;

      let propertyIdList =
        tourAdditions != null ? tourAdditions.map((x) => x.id) : null;

      // Create the new tour and get its id
      this.toursService.addTour(result.tour, propertyIdList, null).subscribe((response) => {
        // Rest of your existing code for creating the new tour...
        if(response['errors'] != null) {
          this.error = response['errors'][0].message;
          // this.gaService.sendErrorDisplayEvent({page_name:'please_name_your_new_tour',message:this.error,type:"form_validation"})
          return;
        }
        this.navigateToTours(response['data']['createTour']?.id);
        tourAdditions.forEach(property => {
          property.checked = false;
        });

        let checkedProperties = this.properties.filter(property => property.checked);
        this.storage.set('checkedProperties', checkedProperties).subscribe(() => {
        })
      });

      this.showSpinner = false;
      this.showCheckmark(null);
    }
    else{
      this.showSpinner = false;
    }
  };

  runAfterClosed.bind(this);

  inputDialogRef.afterClosed().subscribe(runAfterClosed);
}


confirmFromTourFlow(tourAdditions) {
  this.showSpinner = true;

  const confirmationMessage =
  tourAdditions.length === 1
            ? `Add this property to the tour?`
            : `Add ${tourAdditions.length} properties to the tour?`;

  const dialogRef = this.dialog.open(ConfirmListComponent, {
    data: {
      message: confirmationMessage,
      buttonText: {
        ok: 'ADD',
        cancel: 'CANCEL',
      },
    },
    panelClass: ['custom-modal-box','cusom-prop-box']
  });

  dialogRef.afterClosed().subscribe((result) => {
    this.error = null;
  
    if (result?.confirmed) {
      let tourId = this.tourFlowId;
      let propertyIdList = tourAdditions.map((x) => x.id);
  
      this.toursService.fetchTourSummary(tourId).subscribe((response) => {
        const tourSummary = response['data']; // The tour summary data is now directly available in response.data
        // Process the tour summary here
        const showings = tourSummary['tours'][0]['showings'];
        const existingPropertyIds = showings.map((showing) => {
          return showing['property']['id'];
        });
        const existingPropertyIdsStrings = existingPropertyIds.map(String); // Convert numbers to strings
  
        const newPropertyIdList = [];
        const existingPropertyIdList = [];
        const existingPropertyAddressList = [];
        const newPropertyAddressList = [];
  
        propertyIdList.forEach((propertyId) => {
          const showing = showings.find((showing) => showing['property']['id'] === propertyId);
          if (existingPropertyIdsStrings.includes(propertyId) && showing ) {
            // const address = showing['property']['propertyFeatures']['featureData']['UnparsedAddress'];
            const address = showing['property']['propertyFeatures']?.['featureData']?.['UnparsedAddress'] 
            || showing['property']['UnparsedAddress'];
            existingPropertyIdList.push(propertyId);
            existingPropertyAddressList.push(address);
          } else {
            newPropertyIdList.push(propertyId);
          }
        });

        if (newPropertyIdList.length === 0) {
          let errorMessage
          if(propertyIdList.length === 1){
            errorMessage = ['Selected property already exist.'];
          }
          else if(propertyIdList.length > 1){
            errorMessage = ['Selected properties already exist.'];
          }
          this.openErrorModal(errorMessage, false);
          this.showSpinner = false;
          return;
        }

        if (newPropertyIdList.length !== 0 && existingPropertyIdList.length !== 0) {
          const errorMessage = existingPropertyAddressList;
          this.openErrorModal(errorMessage, true);
          this.showSpinner = false;
          return;
        }
  
        this.toursService.addPropertiesToTour(newPropertyIdList, tourId).subscribe((showingTour) => {
          this.showSpinner = false;
          // Assume for alpha stage that an error means logged out
          if (showingTour['errors'] != null) {
            this.error = showingTour['errors'][0].message;
            return;
          }
          this.navigateToTours();
        });
      });
    } else {
      this.showSpinner = false;
    }
  });
  // tourAdditions.forEach(property => {
  //   property.checked = false;
  // });

  // let checkedProperties = this.properties.filter(property => property.checked);
  // this.storage.set('checkedProperties', checkedProperties).subscribe(() => {
  //   // Handle any additional logic after updating the local storage
  // });

}

  openErrorModal(errorMessage: string[], isExistingPropertyError: boolean) {
    // const modalRef = this.modalService.open(ErrorModalComponent);
    // modalRef.componentInstance.errorMessage = errorMessage;
    // modalRef.componentInstance.isExistingPropertyError = isExistingPropertyError;
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        message: errorMessage,
        isExistingPropertyError:isExistingPropertyError,
        buttonText: {
          // cancel: 'CANCEL',
        },
      },
      panelClass: 'custom-modal-box'
    });
    dialogRef.afterClosed()
  }

  onAddToTour(event) {
    this.error = null;

    let tourAdditions = this.properties.filter((x) => {
      return x.checked == true;
    });
    if (tourAdditions.length < 1) {
      this.error = 'Please select a property to add to a tour';
      return;
    }

    this.confirmTour(tourAdditions);

  }

  /**
 	 Filter the selection list by typing
	*/
  filter(filterString, selection) {
    selection.filteredFields = TrestleKeys.filter((x) =>
      x.display.toLowerCase().includes(filterString.toLowerCase())
    );
  }

  /**
 	 Filter the selection list by typing
	*/
  filterValuesList(filterString, selection) {
    selection.filteredValues = TrestleKeys.filter(
      (w) => w.searchKey == selection.searchKey
    )
      .flatMap((x) => x.values)
      .filter((y) =>
        y.display.toLowerCase().includes(filterString.toLowerCase())
      );
  }
  /**
 	 Filter the selection list by typing
	*/
  filterSearchFieldValuesList(filterString, selection,fieldName:string) {
    // selection.filteredValues = selection.filteredFields.values.filter((x) =>
    //   x.display.toLowerCase().includes(filterString.toLowerCase())
    // );
    let fieldData =[];
    let filterValue =[];
    if(fieldName==='mlsStatus'){
      fieldData=selection.filteredFields[0]['values'];
      const previousSelection = selection.selectedField.value;
      // selection.selectedField['value'] =!previousSelection.includes('Active')?[...previousSelection]:['Active']
       console.log("object", selection.selectedField['value'])
      filterValue = fieldData.filter((item,i) => {
      const normalizedDisplay = typeof item.value =='object'?item.value?.display.trim().toLowerCase():item.value.trim().toLowerCase();
      const normalizedFilter = filterString.trim().toLowerCase();
      // const selectedDisplay= typeof  selection.selectedField.value[i] =='object'? selection.selectedField.value[i]?.value.trim().toLowerCase(): selection.selectedField.value[i].trim().toLowerCase();
      return normalizedDisplay.startsWith(normalizedFilter)||previousSelection.includes(item.value);
    });
    // Set active flag for the first option
    fieldData[0].active = true;
    }else if(fieldName==='MlsSelection'){
      fieldData=selection.filteredFields[0]['values'];
      filterValue = fieldData.filter((item,i) => {
        const normalizedDisplay = typeof item.value =='object'?item?.display.trim().toLowerCase():item.display.trim().toLowerCase();
        const normalizedFilter = filterString.trim().toLowerCase();
        return normalizedDisplay.startsWith(normalizedFilter);
      });
    }else if(fieldName==='View'){
      fieldData=selection.filteredFields['values'];
       filterValue = fieldData.filter((item,i) => {
        const normalizedDisplay = typeof item.value =='object'?item?.display.trim().toLowerCase():item.display.trim().toLowerCase();
        const normalizedFilter = filterString.trim().toLowerCase();
        return normalizedDisplay.startsWith(normalizedFilter);
      });
    }
    if(filterString ===''){
      selection.filteredValues = fieldData;
      return;
    }
    selection.filteredValues =[...filterValue]
    // selection.selectedField['active'] = selection.filteredValues.some(option => option.value.toLowerCase().startsWith(filterString.toLowerCase()));
    // this.focusFilteredOption( filterString, selection.filteredValues,fieldName); 
   
  }
  focusFilteredOption(filterString,value,fieldName:string) {
    if (fieldName === 'mlsStatus') {
      const filteredOption = this.selectMlsStatusElement;
        // Clear previous active states
    filteredOption.options.forEach(option => option['_active'] = false);
    //   const activeOption = this.selectMlsStatusElement.options
    //   .toArray()
    //   .find((option: MatOption) => !option['_selected']);
    // if (activeOption) {
    //   activeOption['_active'] = true;
    //   this.cdr.detectChanges()
    // }
    //  console.log("activeOption",activeOption);
    //  console.log("filteredOption",filteredOption['options']['_results'])
  }


    // if(fieldName==='mlsStatus'){
    
    // const filteredOption = this.selectMlsStatusElement;
    // const activeData =filteredOption['options'].filter(option =>{
    //   console.log("object",filterString,option.value);
    //   option['_active']=false;
    //   return (typeof option.value =='object'?option.value?.display.toLowerCase():option.value.toLowerCase()).includes(filterString.toLowerCase())
    // } );
   
  
    // if(activeData){
    //   console.log("object-activeData",activeData[0],activeData[1],activeData[2]);
    //  activeData.filter((item) => {
    //     const normalizedDisplay = typeof item.value =='object'?item.value?.value.trim().toLowerCase():item.value.trim().toLowerCase();
    //     const normalizedFilter = filterString.trim().toLowerCase();
    //     if( normalizedDisplay.startsWith(normalizedFilter)){
    //       item['_active'] = true;
    //     };
    //   });
    // }

    // }
  }
  // onEnterSelection(){
  // }
  // focusFilteredOption() {
  //   const filteredOption = this.selectElement.find(option => option.value === 'specificValue');
  //   if (filteredOption) {
  //     filteredOption.focus();
  //   }
  // }
}
