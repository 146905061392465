<div style="position:relative">
    <mat-card class="predicted-address-card" *ngIf="predictedPlaces.length > 0">
      <mat-card-content>
            <mat-selection-list class="places-autocomplete" [multiple]="false" (selectionChange)="onAddressSelection($event)"  role="list" style="overflow-y: auto;
            height:fit-content;max-height: calc(50vh - 100px);">
                <mat-list-option style="height:auto"   role="listitem" *ngFor="let place of predictedPlaces;last as isLast" [value]="place" class="predicted-place">
                  {{ place.place_name }}
                  <mat-divider [inset]="true" *ngIf="!isLast"></mat-divider>
                </mat-list-option>
            </mat-selection-list>
      </mat-card-content>
    </mat-card>
</div>