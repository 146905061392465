
<div class="content-container pt-0 mobile-scroll">
	<mat-list class="tours-list tour-section-header" >
		<div class="tour-list-wrapper">
			<div class="middle-buttons-wrapper">
				<button mat-raised-button color="primary" class="mx-2 px-2 all-btn" [class.active-btn]="sortText=='all'" (click)="dataFilter('all',$event);onFilterChange('all')">All</button>
				<ng-container *ngFor="let tourSection of filteredToursInSequence;let i=index">
					<div [style]="sectionStyle(tourSection.key)" >
						<button mat-stroked-button color="primary" class="mx-2 custom-button-style" [class.active-btn]="sortText==tourSection.display?true:false" (click)="dataFilter(tourSection.display);onFilterChange($event.target.value)"  [tabindex]="0" [value]="tourSection.display">{{ tourSection.display }}</button>	
					</div>
				</ng-container>
			</div>
			<div class="searchbar-button-wrapper tourMargin d-flex justify-content-start">
				<app-search-bar (onFilterChange)="filterChange($event)" class="custom-width" #filterComponent></app-search-bar>	
				<button title="Delete Tours" class="trash-button" (click)="onDeleteTours()" [disabled]="selectedTours().length == 0">
					<img src="assets/bxs_trash.png" alt="Delete" matSuffix [ngClass]="{'disabled': selectedTours().length == 0}" width="24" />
				</button>
				<button title="Copy Tours" class="copy-button" (click)="onCopyTours()" [disabled]="selectedTours().length === 0 || selectedTours().length > 1">
					<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-clipboard-fill" viewBox="0 0 16 16">  <path fill-rule="evenodd" d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2"/></svg>
				</button>
			</div>
		</div>
	</mat-list>
	<div class="Nodata" *ngIf="dataFilterValue.length === 0">
		<app-no-results></app-no-results>
	</div>
	<div class="TourList-container">
		<mat-list class="tours-list my-3" *ngFor="let tour of dataFilterValue; let index = index; trackBy: trackByTourId">
			<div class="filter" >
				<mat-accordion multi="false" >
					<mat-expansion-panel [expanded]="addShowingsTourId===tour.id?true:false" (opened)="onPanelState('expand',tour)" (closed)="onPanelState('collapse',tour)">
						<mat-expansion-panel-header (click)="onHeaderClick(tour)" [attr.aria-disabled]="tour.tourCompleted == 1?'true':'false'">
							<mat-panel-title class="tour-panel-center-align">
								<div class="tour-grid">
									
								<div class="column1">
									<div class="first-column">
										<!-- <div class="checkbox-heading-wrap"><mat-checkbox class="tour-title tour-checkbox" [(ngModel)]="tour.checked" (change)="onSelectOneTour(tour,tour.id, $event)" tabindex="0" (click)="$event.stopPropagation();"><b class="adafix">.</b></mat-checkbox> -->
											<div class="checkbox-heading-wrap"><mat-checkbox class="tour-title tour-checkbox" [checked]="tour.checked"  (change)="onSelectOneTour(tour, $event)" tabindex="0" (click)="$event.stopPropagation();"><b class="adafix">.</b></mat-checkbox>
									<!-- <img src="assets/avatar.jpg" alt="User Picture" class="tour-user" width="24" tabindex="0"> -->
									<h4 tabindex="0"  >
										<span [ngClass]="{'tour-complete-disabled': tour.tourCompleted == 1}">{{ tour.name }}</span><img src="assets/material-symbols_info.png" style="vertical-align: bottom;" alt="Info Icon" width="24" tabindex="0"
										[matTooltip]="tour?.description !== 'null' ? tour.description : ''"
										[matTooltipClass]="'custom-tooltip'" #tooltip="matTooltip" (click)="$event.stopPropagation();tooltip.toggle()" (mouseover)="tooltip.toggle()">	
										<!-- <img src="assets/info-icon.svg" alt="Info Icon" width="24" tabindex="0" [matTooltip]="tour?.description" #tooltip="matTooltip"> -->								
										
									</h4>
									</div>			
									</div>
									<!-- <em data-bs-toggle="collapse" (click)="$event.stopPropagation();" [attr.data-bs-target]="'#dropList'+index" class="dropList"></em>
										<div [attr.id]="'dropList'+index" class="collapse dropList-links" >
											<a [routerLink]="tour.tourCompleted !== 1 ? ['../properties'] : null" [queryParams]="{ tour: this.tour.id }" tabindex="0" (click)="tour.tourCompleted !== 1 ? addShowingsEvent($event,tour) : ''">
												<label class="custom-link">Add Showing&nbsp;<i class="bi bi-plus plusicon icon-color"></i></label>	
											</a>
											<a (click)="tour.tourCompleted !== 1 ? navigateToClients(tour.id) : ''" tabindex="0">
												<label class="custom-link">Add Client&nbsp;<i class="bi bi-plus plusicon icon-color"></i></label>
											</a>
											<button title="Delete Tours" class="trash-button" mat-mini-fab (click)="DeleteTours($event,tour)">
												<span style="text-decoration: underline;">Delete</span>&nbsp;<img src="assets/bxs_trash.png" alt="Delete" matSuffix [ngClass]="{'disabled': !tour.checked}" />
											</button>
										</div> -->

									<em (click)="toggleDropdown(index); $event.stopPropagation();" [attr.data-bs-target]="'#dropList' + index" class="dropList"></em>
										<div [attr.id]="'dropList' + index" class="collapse dropList-links" [class.show]="activeDropdownIndex === index">
											<a [routerLink]="tour.tourCompleted !== 1 ? ['../properties'] : null" [queryParams]="{ tour: tour.id }" tabindex="0" (click)="tour.tourCompleted !== 1 ? addShowingsEvent($event, tour) : ''">
												<label class="custom-link">Add Showing&nbsp;<i class="bi bi-plus plusicon icon-color"></i></label>
											</a>
											<a (click)="tour.tourCompleted !== 1 ? navigateToClients(tour.id) : ''" tabindex="0">
												<label class="custom-link">Add Client&nbsp;<i class="bi bi-plus plusicon icon-color"></i></label>
											</a>
											<button title="Delete Tours" class="trash-button" mat-mini-fab (click)="DeleteTours($event, tour)">
												<span style="text-decoration: underline;">Delete</span>&nbsp;<img src="assets/bxs_trash.png" alt="Delete" matSuffix [ngClass]="{'disabled': !tour.checked}" />
											</button>
										</div>
								</div>
								
								<div class="column2" style="display:none;">
									<p tabindex="0">{{ tour.description !== 'null' ? tour.description : '' }}</p>
								</div>
								<!-- <div class="Vdivider2"></div> -->
								<!-- <div class="column2">
									<label class="custom-link">ADD Showing</label>	
									<a routerLink="../properties" [queryParams]="{tour: this.tour.id}" tabindex="0">
									<i class="bi bi-plus-square plusicon icon-color"></i> 
									</a>							
								</div> -->
								<div class="column2 addshowingColumn" [ngClass]="{'tour-complete-disabled': tour.tourCompleted == 1}">
									<a [routerLink]="tour.tourCompleted !== 1 ? ['../properties'] : null" [queryParams]="{ tour: this.tour.id }" tabindex="0" (click)="tour.tourCompleted !== 1 ? addShowingsEvent($event,tour) : ''">
										<label class="custom-link">Add Showing&nbsp;<i class="bi bi-plus plusicon icon-color"></i></label>
										
									</a>
								</div>
								<!-- <div class="Vdivider2"></div> -->
								<!-- <div class="column2">
									<a href="#" tabindex="0" style="display:none;">
									<label>ADD Client</label>
									<i class="bi bi-plus-square plusicon icon-color"></i>
									</a>
									<div class="custom-client-wrap" *ngFor="let client of tour.clients">
										<div><img [src]="client?.avatar?.url"></div>
										<div class="custom-client-list">
											<label>{{ client.firstName }} {{ client.lastName }}</label>
										</div>
										<i class="bi bi-plus-square plusicon icon-color"></i>
									</div>
								</div> -->
								<div class="column2 client-tour-column" >
									<!-- Check if tour.clients has any elements -->
									<!-- <ng-container *ngIf="tour.clients.length > 0; else addClientSection">
										<div class="custom-client-wrap" *ngFor="let client of tour.clients" (click)="onOpenModa2($event, tour)">
											<div><img [src]="client?.avatar?.url" width="30"></div>
											<div class="custom-client-list">
												<label>{{ client.firstName }} {{ client.lastName }}</label>
											</div>
											<i class="bi bi-plus-square plusicon icon-color" (click)="navigateToClients(tour.id)"></i>								
										</div>
									</ng-container> -->
									<ng-container *ngIf="tour.clients.length > 0; else addClientSection">
										<!-- Display details of the first client only -->
										<div class="custom-client-wrap" (click)="onOpenModa2($event, tour)" [ngClass]="{'tour-complete-disabled-only': tour.tourCompleted == 1}">
											<div *ngIf="tour.clients.length > 1; else singleClientSection">
												<img *ngIf="tour.clients[0]?.avatar?.url; else defaultAvatar" [src]="tour.clients[0]?.avatar?.url" width="30" height="30" alt="multiple client selection0">
												<img *ngIf="tour.clients[1]?.avatar?.url; else defaultAvatar" [src]="tour.clients[1]?.avatar?.url" width="30" height="30" alt="multiple client selection1">
											</div>
											<ng-template #singleClientSection>
												<img *ngIf="tour.clients[0]?.avatar?.url; else defaultAvatar" [src]="tour.clients[0]?.avatar?.url" width="30" height="30" alt="single client selection">
											</ng-template>
											<ng-template #defaultAvatar>
												<img src="./assets/avatar.jpg" width="30" [attr.alt]="'Client'+tour.clients[0]?.id" />
											</ng-template>

											<div class="custom-client-list">
												<label>{{ tour.clients[0]?.firstName }} {{ tour.clients[0]?.lastName }}</label>
											</div>
											<i class="bi bi-plus plusicon icon-color" (click)="tour.tourCompleted !== 1 ? navigateToClients(tour.id) : ''" [ngClass]="{'tour-complete-disabled': tour.tourCompleted == 1}"></i>
										</div>
									</ng-container>
								
									<!-- Template for when tour.clients has no elements -->
									<ng-template #addClientSection>
										<!-- routerLink="../clients" [queryParams]="{tour: this.tour.id}"  -->
										<a (click)="tour.tourCompleted !== 1 ? navigateToClients(tour.id) : ''" tabindex="0">
											<label class="custom-link">Add Client&nbsp;<i class="bi bi-plus plusicon icon-color"></i></label>
											
										</a>
									</ng-template>
								</div>
								<!-- <div class="Vdivider2"></div> -->
								<div class="column-date">
									<!-- <div class="tour-date">
									<input id="matInput" type="date" class="date-picker title-date-picker" (click)="$event.stopPropagation();" (change)="onUpdateTourDate(tour,$event,tour.tourDate)" 
										[value]="tour.tourDate | date:'yyyy-MM-dd' " min="{{ today | date:'yyyy-MM-dd' }}" max="{{ nextYear | date:'yyyy-MM-dd' }}" aria-labelledby="matInput">
									</div> -->
									<div class="tour-date">

										<mat-form-field class="tour-form-field"  >
											<!-- <input  matInput [matDatepicker]="picker" (click)="$event.stopPropagation();" [value]="tour.tourDate | date:'yyyy-MM-dd' " [min]="today | date:'yyyy-MM-dd'" [max]="nextYear | date:'yyyy-MM-dd'" aria-labelledby="matInput"
											(dateChange)="onUpdateTourDate(tour,$event,tour.tourDate)"
											/> -->
											<input  matInput [matDatepicker]="picker" (click)="$event.stopPropagation();picker.open();" (focus)="picker.open()" [value]="tour.tourDate" [min]="today" [max]="nextYear" id="matInput" aria-labelledby="matInput"
											(dateChange)="onUpdateTourDate(tour,$event,tour.tourDate)" [disabled]="tour.tourCompleted === 1"
											/>
											<mat-datepicker-toggle matSuffix [for]="picker" style="font-size: 23px;display: none;" >
											</mat-datepicker-toggle>
											<mat-datepicker #picker color="primary"></mat-datepicker>
										</mat-form-field>
									</div>
								</div>
								<div class="column3 prop-icon">
									<ng-container>
										<div class="show-prop-wrapper" [ngClass]="{'tour-complete-disabled': tour.tourCompleted == 1}">
											<ng-container *ngIf="tour.showings.length > 1; else singleIcon">
												<img src="assets/mutiple-property-icon.svg" [attr.alt]="'multiple properties icon'+index" style="filter: brightness(0.8);">
											</ng-container>
											<ng-template #singleIcon>
												<img src="assets/fa6-solid_house.png"  [attr.alt]="'single property icon'+index"/>
											</ng-template>
											<div>{{ tour.showings.length }}</div>
										</div>
									</ng-container>
									<ng-container *ngIf="tour.showings.length === 0; else showProperties">
									</ng-container>
								</div>
								<div class="column3 prop-column">
									<ng-container>
										<div class="show-prop-wrappe" [ngClass]="{'disabled': tour.tourCompleted == 1}" (click)="tour.tourCompleted != 1 && navigateToMyDocument()">
											<ng-container>
												<img src="assets/tour-document.png" width="28" [attr.alt]="'tour document icon'+index">
											</ng-container>
										</div>
									</ng-container>
								</div>
								<div class="column2 tourDet-column" [ngClass]="{'tour-complete-disabled': tour.tourCompleted == 1}">
									<button mat-stroked-button tabindex="0" type="button" class="btn-pad" (click)="SendRemainderEmail($event, tour)" [disabled]="tour.showings.length === 0">
										<img src="assets/tour-email.png" width="24" alt="email" class="tourEmail"><!--&#9993;  -->
										<span>&nbsp;Tour Details</span>
									</button>
								</div>
								<div class="column4">
									<button title="Delete Tours" class="trash-button" mat-mini-fab (click)="DeleteTours($event,tour)">
										<img src="assets/bxs_trash.png" alt="Delete" matSuffix [ngClass]="{'disabled': !tour.checked}" />
									</button>
								</div>
							</div>						
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div *ngIf="tour.showings.length < 1">	
							This tour is so lonely! <a routerLink="../properties" [queryParams]="{tour: this.tour.id}">Please add a showing.</a>
						</div>
						<div *ngIf="tour.showings.length > 0" class="add-showing-button">
							<div class="add-buttons">
								<button mat-stroked-button tabindex="0" class="btn-margin custom-font-size" type="button" *ngIf="tour?.clients?.length>0" [disabled]="tour.tourCompleted == 1" (click)="onOpenModal(tour)">LivePad Mobile Link</button>
								
								<button  mat-stroked-button class="ms-2 custom-font-size" *ngIf="tour.tourCompleted"  (click)="viewTourSummary(tour)">
									Tour Summary
								</button>
							</div>
						</div>
						<div class="row" style="display: block;">
							<div class="col-sm-12 col-md-6 col-lg-6 maprightOnDesk">
								<div class="map-container">
									<div id="map-{{ tour.id }}" style="width: 100%; height: 100%;"></div>
								</div>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-6 TourCardLeftOnDesk">
							<div *ngIf="tour.showings.length > 0">
								<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event,tour)">
									<div class="example-box" *ngFor="let showing of tour.showings; let i = index"> 
									<app-showing-card  *ngIf="!isMobile" cdkDrag class="example-box tour-list-box"  [propertyIndex]="i + 1" [tour]="tour" [showing]="showing"  [requestActions]="requestActions" (onDelete)="deleteShowing($event)" (onRemoveClient)="removeClient($event)"  (onUpdateTime)="onUpdateShowingTime($event)" (onRequestShowing)="onRequested($event)" [cdkDragDisabled]="tour.tourCompleted==1"></app-showing-card>
									<app-showing-card  *ngIf="isMobile" class="example-box tour-list-box"  [propertyIndex]="i + 1" [tour]="tour" [showing]="showing"  [requestActions]="requestActions" (onDelete)="deleteShowing($event)" (onRemoveClient)="removeClient($event)"  (onUpdateTime)="onUpdateShowingTime($event)" (onRequestShowing)="onRequested($event)" [cdkDragDisabled]="tour.tourCompleted==1"></app-showing-card>
									</div>
								</div>
							</div>
							</div>
						</div> 
					</mat-expansion-panel>
				</mat-accordion>
				<div class="align-error-message">
				<div class="error-message" *ngIf="tour.dateError">{{ tour.dateError }}</div>
			</div>
			</div>
		</mat-list>
	
		<div class="d-flex pagination-md paginator-content bg-white" *ngIf="displayedData?.length>0">
			<div class="form-group" style="width: 8.5rem;display:flex;justify-content: center;align-items:center;color:rgba(0,0,0,.54);" >
			  <label for="paginationInput">Page </label>
			  <input  #i maxlength="10" pattern="\d*"  	
				id="paginationInput" 
				class="form-control py-0 ms-1 px-2" 
				type="text"
				[(value)]="pageNumber"
				(keyup.enter)="selectPage(i.value)"
				(blur)="selectPage(i.value)"
				(input)="formatInput($event,i)"
				aria-labelledby="paginationDescription"
				[style.width]="totalNumberOfPages.toString().length+'.9rem'"
				autocomplete="off"
				/>
				<span id="paginationDescription" class="col-form-label text-nowrap px-2"> of {{totalNumberOfPages }}</span>
			  </div>
			<mat-paginator 
			  #paginator
			  [pageSize]="pageSize"
			  [pageSizeOptions]="[5, 10, 15, 20]"
			  [showFirstLastButtons]="true" 
			  (page)="onPageChange($event)"
			  [length]="displayedData?.length"
			  [hidePageSize]="false"
			  [pageIndex]="pageIndex"
			  [disabled]="false"
			  aria-label="Select page"
			>
			</mat-paginator>
		</div>
	</div>

<div class="modal2" *ngIf="showAllClients">
    <div class="modal-content2 d-flex">
    <div class="modal-header">
		<h1>Clients in the Tour</h1>
		<span (click)="onCloseModal()" class="close" title="Close Modal"><img src="assets/close-icon.png" alt="Close Icon"
            title="Close" class="close-icon" width="19"></span>
     </div>
	 <div *ngFor="let client of selectedTour?.clients" class="client-row-popup" (click)="selectedTour.tourCompleted !==1 ? onClientChipClick(client.id) : '' ;selectedTour.tourCompleted !==1 ? onShowClientEvent($event,client,selectedTour?.name) : ''">
		<div class="profile-pic-popup" width="20%">
			<img *ngIf="client?.avatar?.url; else defaultAvatar" [src]="client.avatar.url" width="30" alt="Client ">
			<ng-template #defaultAvatar>
				<img src="./assets/avatar.jpg" width="30" alt="Client ">
			</ng-template>
		</div>
		<div class="info-section position-relative" width="80%" [ngStyle]="{'height':selectedTour.tourCompleted ==1? '40px':'auto'}">
			<div class="full-name">{{ client.firstName }} {{ client.lastName }}</div>
			<button mat-mini-fab class="client-delete" (click)="selectedTour.tourCompleted !==1 ? removeClient($event, client, selectedTour) : ''" [ngClass]="{'d-none':selectedTour.tourCompleted ==1}">
				<mat-icon class="client-delete-icon">clear</mat-icon>
			</button>
		</div>
	</div>
    </div>
</div>
<div class="modal" *ngIf="showTourSummaryModal">
    <div class="modal-content">  
    <!-- <span (click)="onCloseModal()" class="close" title="Close Modal"><img src="assets/close-icon.png" alt="Close Icon"
                title="Close" class="close-icon"></span>    -->
          	<div class="logo-modal-position">
                    <img src="./assets/logo-symbol.png" alt="Livepad Logo" tabindex="0">
                    <h5 class="modal-title" tabindex="0">Link to start tour</h5>
                   </div>
		<div class="row justify-content-center">
			<!-- <div class="col-md-12">
				<mat-form-field class="mt-3">
					<mat-label>Email</mat-label>
					
					<input type="email" matInput name="email" ngModel #email="ngModel" email pattern="/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" (input)="onInputChanged()">
				  </mat-form-field>
				  <mat-form-field class="mt-3">
					<mat-label>Phone Number</mat-label>
					<input type="tel" (pasteError)="errorOutput($event)" matInput name="phoneNumber" ngModel #phoneNumber="ngModel" Formatphone (input)="onInputChanged()">
				  </mat-form-field>
				<br>
			</div> -->
			<div class="col-md-12">
				<div class="mt-3">
				  <label tabindex="0">Email</label>
				  <!-- [ngModel]="selectedClients.email" --><!-- pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"-->
				  <input type="email" name="email" [ngModel]="selectedClients.email" #emailInput="ngModel" id="emailInputPop" aria-labelledby="emailInputPop" (input)="onInputChanged()" class="form-control" tabindex="0">
				</div>
				<div class="mt-3">
				  <label tabindex="0">Phone Number</label>
				  <!-- [ngModel]="selectedClients.phone" -->
				  <!-- <input type="tel" (pasteError)="errorOutput($event)" [ngModel]="selectedClients.phone" matInput name="phoneNumber"  #phoneInput="ngModel" Formatphone (input)="onInputChanged()"> -->
				  <input type="tel" (pasteError)="errorOutput($event)" [ngModel]="transform(selectedClients.phone)" id="phoneInputPop" aria-labelledby="phoneInputPop" name="phoneNumber" Formatphone  #phoneInput="ngModel" (input)="onInputChanged()" class="form-control" tabindex="0">
				</div>
				<br>
			</div>
			<!-- <div class="col-sm-12">
				<div *ngIf="email.touched" class="error-message">
					<p *ngIf="email.errors?.pattern"><small>This is not a valid Email!!!</small></p>
				  </div>
				  <div *ngIf="showValidationPhone" class="error-message">
					<p><small>Please Enter valid Phone Number</small></p>
				</div>
				  <div *ngIf="showValidationError" class="error-message">
					<small>Please provide either a valid Email or Phone Number</small>
				</div>
				<div *ngIf="showPasteError" class="error-message">
					<small>Invalid phone number pasted</small>
				  </div>
			</div> -->
			<div class="col-sm-12">
				<div *ngIf="showValidationEmail" class="error-message">
					<p><small>This is not a valid Email!!!</small></p>
				</div>
				<div *ngIf="showValidationPhone" class="error-message">
				  <p><small>Please Enter a valid Phone Number</small></p>
				</div>
				<div *ngIf="showValidationError" class="error-message mb-1">
				  <p><small>Please provide either a valid Email or Phone Number</small></p>
				</div>
				<div *ngIf="showPasteError" class="error-message">
				  <p><small>Invalid phone number pasted</small></p>
				</div>
			</div>
		</div>
		<div class="signBtn">
		<button type="submit" class="btn-primary" (click)="onSubmitGenerateLink(selectedTour,emailInput.value, phoneInput.value)" tabindex="0">SEND</button>
		<button type="submit" class="btn-secondary" (click)="onCloseModal()" tabindex="0">CANCEL</button> 
      </div>
    </div>
</div>
<div id="modal" class="modal1 alert-popup" *ngIf="openModal">
    <div class="modal-content3">
        <div class="container2">
            <h2 class="modal-cus-title" tabindex="0"><img src="./assets/alert-icon1.svg" width="30px" alt="Alert Icon" class="alert-img-pad"> Reminder</h2>
            <p class="my-5">Please complete the document signing process before commencing your tour</p>
            <div class="row signBtn" style="text-align: center;">
                <div class="col-md-6">
                    <button type="submit" tabindex="0" class="btn btnColor form-control btn-md delete-btn-color" (click)="navigateToMyDocument1(currentTourForModal)" tabindex="0"> ACCEPT
                    </button>
                </div>
                <div class="col-md-6"><button type="submit" tabindex="0"
                        class="btn signup form-control cancel-btn-color btn-md" (click)="onCloseModal()"> BACK
                    </button></div>
            </div>
        </div>
    </div>
</div>
</div>