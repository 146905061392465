<div [class.scroll-lock]="enlargedImage">  
	<div class="title-bg-color" tabindex="0">
		<!-- <span>Property&nbsp;{{propertyIndex}}:&nbsp;</span> {{showing?.property?.address?.fullAddress}} -->
		<span>Property&nbsp;{{propertyIndex}}:&nbsp;</span> {{showing?.property?.UnparsedAddress}}
		<!-- <span>Property&nbsp;{{propertyIndex}}:&nbsp;</span> <span>
			{{ 
			  (showing.property?.address.streetNumber && showing.property?.address.streetName 
				? showing.property?.address.streetNumber + ' ' + showing.property?.address.streetName 
				: showing.property?.address.streetNumber && !showing.property?.address.streetName 
				  ? 'N/A' 
				  : showing.property?.address.streetName && showing.property?.address.streetName !== "null" && showing.property?.address.streetName !== "none" 
					? showing.property?.address.streetName 
					: 'N/A') + ', ' + 
			  ((showing.property?.address?.city || '') + 
			   (showing.property?.address?.state ? ', ' + showing.property?.address?.state : '') + 
			   (showing.property?.address?.zip ? ', ' + showing.property?.address?.zip : '')) 
			}}
		  </span> -->
		<img [src]="favoriteStatus(showing)" alt="Favorite Icon" width="35" style="float:right;margin-top:-6px;">
	</div>
	<div class="price-details">{{listPrice()}}</div>
	<div>
		<h1 class="heading_2">Property photos <div class="rating-star">Property rating &nbsp;<div [innerHTML]="RatingStatus(showing)"></div></div></h1>
		<app-image-viewer-element class="image-viewer" [images]="getImageSet()"	[thumbnailIndex]="thumbnailImageIndex">
		</app-image-viewer-element>
	<div>

	<div>
		<h1 class="heading_2" tabindex="0">Your comments:&nbsp;<span *ngFor="let comment of comments()">{{ comment }} <br></span></h1>
	</div>

	<div>
		<ng-container *ngIf="filterImagesByType(showing['clientPhotos'], 'jpg').length > 0">
			<h1 class="heading_2">Your photos:</h1>
			<div>
				<app-image-row [selected]="areClientPhotosSelected()" [images]="filterImagesByType(showing['clientPhotos'], 'jpg')" (onImageClick)="onSelectClientThumbnail($event)">
				</app-image-row>
			</div>
		</ng-container>
	</div>

	<div>
		<ng-container *ngIf="filterImagesByType(showing['clientPhotos'], 'mp4').length > 0">
			<h1 class="heading_2">Your videos:</h1>
			<div>
				<app-image-row [selected]="areClientPhotosSelected()" [images]="filterImagesByType(showing['clientPhotos'], 'mp4')" (onImageClick)="onSelectClientThumbnail($event)"></app-image-row>
			</div>
		</ng-container>
	</div>

	<div>
		<a class="poi-preview-link" (click)="startTour(showing, $event)">View POI</a>
	</div>

	<div *ngIf="enlargedImage" class="enlarged-image">
		<img [src]="enlargedImage" (click)="closeEnlargedImage()" class="enlarged-img">
	</div>

</div>