<div class="error-modal">
<div class="modal-header">
    <!-- <h1 class="modal-title">Error</h1> -->
    <h2 class="modal-cus-title"><img src="./assets/alert-icon1.svg" width="32" alt="Alert Icon" class="alert-img-pad"> Error</h2>  
    <span (click)="onCancelClick($event)" class="close" title="Close Modal"><img src="./assets/close-icon.png" alt="Close Icon"
                title="Close" class="close-icon"></span>
  </div>
  <div class="modal-body">
    <h2>
      {{ isExistingPropertyError ? 'Below are the existing properties:' : errorMessage[0] }}
    </h2>
    <ul>
        <ng-container *ngIf="isExistingPropertyError">
          <li *ngFor="let message of errorMessage" class="error">{{ message }}</li>
        </ng-container>
    </ul>
    <h3>Please add only new properties.</h3>
  </div>
</div>
  
  