import { Component, OnInit, EventEmitter, Output, Input, SimpleChange, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { MapsService } from '@services/maps.service';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'app-address-complete',
  templateUrl: './address-complete.component.html',
  styleUrls: ['./address-complete.component.css']
})
export class AddressCompleteComponent implements OnChanges{

  userInput: string = null

	/**
 	 @Input / @Output underlying variable for our final value.
	*/
	_address: string

  @Input()
	set address(val: string) {
	  this._address = val;
	  this.userInput = val; // Set the userInput to the updated address value
	}
	get address() {
		return this._address
	}

  @Input()
	disabled: boolean

  predictedPlaces = [];

  @Output() addressChange = new EventEmitter<{address:string,longitude:number,latitude:number}>();

  constructor(private mapsService: MapsService) { }

  ngOnChanges(changes:{[address:string]: SimpleChange}) {
    
    if (changes.address && !changes.address.isFirstChange()) {
      this.address = changes.address.currentValue;
		}else{
      return;
    }
  
    if (this.userInput.length === 0) {

      this.predictedPlaces = [];

      return;

    }

    this.mapsService.autocompleteGeo(this.userInput, (err, results) => {

      if (err) {

        console.error(err);

        return;

      }
      this.predictedPlaces = results;
    });
	}

  onAddressSelection(event: MatSelectionListChange) {
    const selectedPlace = event.options[0].value;
    if (selectedPlace) {
      this.addressChange.emit({
        address: selectedPlace.place_name,
        longitude: selectedPlace.geometry.coordinates[0],
        latitude: selectedPlace.geometry.coordinates[1]
      });
      this.predictedPlaces = [];
      this.address ='';
    }
  }

}
